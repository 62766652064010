import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    containerView: {
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 36,
        paddingTop: 36,
        flex: 1,
        margin: 'auto',
        height: '100%',
        width: '100vw'
    },

    headerBackground: {
        backgroundColor: '#fff',
        minHeight: Dimensions.get('window').height / 3
    },

    score: {
        fontWeight: 'bold',
        color: '#0D0334',
        fontSize: Dimensions.get('window').height / 30,
        textAlign: 'center'
    },

    scoreFeedback: {
        fontWeight: 'bold',
        color: 'green',
        fontSize: Dimensions.get('window').height / 40,
        textAlign: 'center',
        marginBottom: 15
    },

    feedbackView: {
        flex: 1
    },

    feedbackViewWeb: {
        flex: 1,
        backgroundColor: '#F3F2F5',
        minHeight: Dimensions.get('window').height / 1.5
    },

    buttonView: {
        width: '85%',
        marginTop: 40
    },

    buttonViewWeb: {
        width: '100%',
        marginTop: 40,
        alignItems: 'center'
    },

    hazardButton: {
        backgroundColor: '#fff',
        marginTop: 10,
        marginHorizontal: 28,
        borderRadius: 10,
        width: '75%',
        alignSelf: 'center'
    },

    hazardButtonView: {
        padding: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    hazardButtonText: {
        fontSize: Dimensions.get('window').height / 50,
        fontWeight: 'bold',
        color: '#0D0334'
    },

    hazardButtonScore: {
        fontSize: Dimensions.get('window').height / 50,
        fontWeight: 'bold',
        color: '#0D0334',
        marginRight: 15,
        borderWidth: 2,
        borderRadius: 60,
        padding: 6,
        textAlign: 'center'
    },

    hazardButtonInnerView: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    green: {
        borderColor: '#99C455'
    },

    amber: {
        borderColor: '#FCB040'
    },

    red: {
        borderColor: '#F0040F'
    },

    pass: {
        color: '#99C455'
    },

    fail: {
        color: '#F0040F'
    }
});

export default styles;
