import React, { useEffect, useState } from 'react';
import { View, ScrollView, Text, TouchableHighlight, Image, Dimensions } from 'react-native';
import styles from './styles';

import { useHistory } from 'react-router-dom';

import Dial from '../../Dial';
import PrimaryButton from '../../Buttons/PrimaryButton';
import HazardPerceptionQuestion from '../../QuestionTemplates/HazardPerceptionQuestion/index.web';

import { addHazards, addTestResult } from '../../../API/Joomla.web';
import { HAZARD_PERCEPTION_PASS, HGV_HAZARD_PERCEPTION_PASS } from '../../../API/Environment.web';

const FeedbackBox = ({ hazard, setPlaybackClip }) => {
    const score = hazard[1];
    return (
        <TouchableHighlight
            onPress={() => setPlaybackClip(hazard)}
            underlayColor="#cac7c6"
            style={styles.hazardButton}>
            <View style={styles.hazardButtonView}>
                <Text style={styles.hazardButtonText}>{hazard[0].name}</Text>
                <View style={styles.hazardButtonInnerView}>
                    <Text
                        style={[
                            styles.hazardButtonScore,
                            score < 3
                                ? styles.red
                                : score === 3
                                ? styles.amber
                                : score > 3 && styles.green
                        ]}>
                        {score}/5
                    </Text>
                    <Image
                        style={{ width: 32, height: 32, resizeMode: 'contain' }}
                        source={require('../../../../assets/images/HazardPerception/PNG/icon-play-blue.png')}
                    />
                </View>
            </View>
        </TouchableHighlight>
    );
};

const ResultsScreen = ({ questionFeedbackBreakdown, testScore, maxScore, questionsTracker }) => {
    const history = useHistory();

    const [playbackClip, setPlaybackClip] = useState();
    const [resultsSaved, setResultsSaved] = useState(false);

    const testPercentage = (testScore / maxScore) * 100;

    const isHgv = localStorage.getItem('testtype') === '2';

    const isTestPass =
        testPercentage >= (isHgv ? HGV_HAZARD_PERCEPTION_PASS : HAZARD_PERCEPTION_PASS);

    useEffect(() => {
        (async () => {
            if (!resultsSaved) {
                if (!isTestPass) {
                    await addTestResult(
                        localStorage.getItem('userid'),
                        localStorage.getItem('session'),
                        maxScore,
                        testScore,
                        questionFeedbackBreakdown,
                        '1',
                        '3'
                    );
                } else {
                    await addTestResult(
                        localStorage.getItem('userid'),
                        localStorage.getItem('session'),
                        maxScore,
                        testScore,
                        questionFeedbackBreakdown,
                        '0',
                        '3'
                    );
                }
                await addHazards(
                    localStorage.getItem('userid'),
                    localStorage.getItem('session'),
                    questionsTracker
                );
                setResultsSaved(true);
            }
        })();
    }, []);

    if (playbackClip) {
        return (
            <HazardPerceptionQuestion
                currentQuestion={playbackClip[0]}
                score={playbackClip[1]}
                setPlaybackClip={setPlaybackClip}
                reviewQuestion={playbackClip[2]}
            />
        );
    } else {
        return (
            <ScrollView contentContainerStyle={styles.container}>
                <View style={styles.headerBackground}>
                    <Dial
                        scorePercentage={testPercentage}
                        prompt={false}
                        noPercentage={true}
                        noNote={true}
                        size={Dimensions.get('window').height / 3}
                    />

                    <Text style={styles.score}>
                        {testScore}/{maxScore}
                    </Text>

                    <Text style={[styles.scoreFeedback, isTestPass ? styles.pass : styles.fail]}>
                        {isTestPass ? 'PASS' : 'FAIL'}
                    </Text>
                </View>

                <View style={styles.feedbackViewWeb}>
                    {Object.entries(questionFeedbackBreakdown).map(([key, value]) => {
                        return <FeedbackBox hazard={value} setPlaybackClip={setPlaybackClip} />;
                    })}

                    <View style={styles.buttonViewWeb}>
                        <PrimaryButton
                            text="Close test"
                            onPress={() => history.push('/hazard-perception-dashboard')}
                            textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                            style={{ width: '75%' }}
                        />
                    </View>
                </View>
            </ScrollView>
        );
    }
};

export default ResultsScreen;
