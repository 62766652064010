import React from 'react';
import { View, Image } from 'react-native';
import styles from './styles';

const LoadingState = () => {
    return (
        <View style={styles.webLoading}>
            <Image
                style={styles.logoImage}
                source={require('../../../assets/images/Login-Signup-Dashboard/Welcome/ttp-logo.png')}
            />

            <Image
                style={styles.spinnerImage}
                source={require('../../../assets/images/spinner.gif')}
            />
        </View>
    );
};

export default LoadingState;
