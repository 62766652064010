import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    titleText: {
        paddingBottom: 33,
        fontWeight: 'bold',
        fontSize: Dimensions.get('window').height / 60,
        color: '#0D0334',
        alignSelf: 'flex-start'
    },

    buttonView: {
        width: '100%',
        marginBottom: 15,
        flexDirection: 'row'
    },

    button: {
        backgroundColor: '#F3F2F5',
        borderRadius: 50,
        marginRight: 10,
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    buttonText: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#868199',
        margin: 15
    },

    buttonTts: {
        marginTop: 5
    },

    testButtonTts: {
        resizeMode: 'contain',
        width: 50,
        height: 50
    },

    videoView: {
        marginBottom: 20,
        alignSelf: 'center'
    },

    video: {
        width: 300,
        height: 200,
        alignSelf: 'center'
    },

    selectedButton: {
        backgroundColor: '#0D0334'
    },

    selectedText: {
        color: 'white'
    },

    image: {
        width: '100%',
        height: '100%',
        resizeMode: 'contain'
    },

    pauseButton: {
        position: 'absolute',
        zIndex: 10,
        alignSelf: 'center',
        justifyContent: 'center',
        top: '40%'
    },

    playIcon: {
        width: 150,
        height: 150,
        resizeMode: 'contain'
    }
});

export default styles;
