import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function GuardedRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={() => {
                return localStorage.getItem('userid') ? children : <Redirect to="/" />;
            }}
        />
    );
}

export default GuardedRoute;
