import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    videoView: {
        width: '100%',
        height: '100%',
        backgroundColor: 'black'
    },

    video: {
        width: '100%',
        height: '100%',
        alignSelf: 'center'
    },

    videoWeb: {
        width: '100%',
        height: '100%'
    },

    barView: {
        width: '100%',
        backgroundColor: '#e6e6e6',
        height: '15%',
        bottom: 0,
        position: 'absolute',
        flexDirection: 'column',
        alignItems: 'center'
    },

    innerBarView: {
        backgroundColor: '#fff',
        height: '45%',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row'
    },

    exitIcon: {
        alignSelf: 'flex-end',
        right: 20,
        top: 20,
        position: 'absolute',
        zIndex: 100
    },

    exitIconWeb: {
        position: 'absolute',
        alignSelf: 'flex-end',
        right: 10,
        top: 10,
        zIndex: 100
    },

    score: {
        backgroundColor: '#fff',
        color: '#0D0334',
        fontSize: 30,
        zIndex: 100,
        height: 60,
        width: 60,
        position: 'absolute',
        alignSelf: 'flex-end',
        right: 10,
        bottom: '20%',
        borderRadius: 30,
        textAlign: 'center',
        lineHeight: 55,
        borderWidth: 5,
        overflow: 'hidden'
    }
});

export default styles;
