import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#F3F2F5',
        flex: 1
    },

    resultsContainer: {
        flex: 1,
        marginHorizontal: 36,
        marginTop: 20
    },

    dashboardTitle: {
        fontSize: 36,
        marginBottom: 24,
        color: '#fff',
        fontWeight: 'bold'
    },

    buttonRowViews: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    headerBackgroundImage: {
        height: 120
    },

    testResultsButton: {
        marginVertical: 5,
        borderRadius: 10,
        backgroundColor: '#fff'
    },

    testResultsView: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10
    },

    testResult: {
        color: '#0D0334',
        borderWidth: 2,
        borderRadius: 50,
        padding: 10,
        fontSize: Dimensions.get('window').height / 60,
        textAlign: 'center',
        fontWeight: 'bold',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 100
    },

    testScore: {
        color: '#0D0334',
        fontSize: Dimensions.get('window').height / 60,
        fontWeight: 'bold',
        padding: 5
    },

    testDate: {
        color: '#868199',
        fontSize: Dimensions.get('window').height / 60,
        padding: 5
    }
});

export default styles;
