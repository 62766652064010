import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({
    modalBackGround: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center'
    },

    modalContainer: {
        width: '80%',
        backgroundColor: 'white',
        maxHeight: '100%',
        paddingHorizontal: 20,
        paddingVertical: 30,
        borderRadius: 20,
        elevation: 20,
        marginVertical: 10
    },

    image: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center'
    },

    title: {
        marginVertical: 5,
        fontSize: 28,
        textAlign: 'center',
        color: '#0D0334',
        fontWeight: 'bold'
    },

    secondaryText: {
        marginVertical: 5,
        fontSize: 14,
        textAlign: 'center',
        color: '#868199',
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 20
    },

    skipText: {
        marginVertical: 20,
        fontSize: 16,
        textAlign: 'center',
        color: '#0D0334'
    },

    subText: {
        marginVertical: 5,
        fontSize: 14,
        textAlign: 'center',
        color: '#868199',
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 20
    }
});

export default styles;
