import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Linking } from 'react-native';

import styles from './styles';
import Dial from '../../Components/Dial/index.web';
import PrimaryButton from '../../Components/Buttons/PrimaryButton';
import PassProtectionCriteria from '../../Components/PassProtectionCriteria/index.web';
import LoadingState from '../../Components/LoadingState/index.web';

import { CONTACT_URL, PASS_PROTECT_TERMS_URL } from '../../API/Environment.web';
import { checkPPStatus } from '../../API/Joomla.web';
import { calculatePPPercentage } from '../../API/RealmComms.web';

const PassProtect = () => {
    const [ppStatus, setPPStatus] = useState(false);

    useEffect(() => {
        (async () => {
            setPPStatus(
                await checkPPStatus(localStorage.getItem('session'), localStorage.getItem('userid'))
            );
        })();
    }, []);

    if (!ppStatus) {
        return <LoadingState />;
    }

    const { requirements, numOfCompleted } = calculatePPPercentage(ppStatus);
    const percentage = (numOfCompleted / requirements.length) * 100;
    const hasComplete = percentage === 100;

    return (
        <View style={{ flex: 1 }}>
            <View style={[styles.container, styles.background]}>
                <Text style={styles.introTextWeb}>
                    <Text style={styles.bold}>Complete 100% </Text>
                    of the required tasks below to be covered for free re-sits and priority support.
                </Text>
                <Dial
                    scorePercentage={percentage}
                    size={250}
                    noPercentage={true}
                    noNote={true}
                    prompt={false}
                />
                <View style={styles.percentageView}>
                    <Text style={styles.percentage}>{percentage}%</Text>
                    <Text style={styles.covered}>{hasComplete ? 'COVERED!!' : 'NOT COVERED'}</Text>
                </View>
                <Text style={styles.encouragement}>{hasComplete ? 'WELL DONE' : 'KEEP GOING'}</Text>
                <Text style={styles.feedback}>
                    You've completed {percentage}% of the requirements for pass protection.
                </Text>
                {!!hasComplete && (
                    <PrimaryButton
                        text={'GET SUPPORT'}
                        style={styles.supportButton}
                        onPress={() => Linking.openURL(CONTACT_URL)}
                    />
                )}
                <TouchableOpacity onPress={() => Linking.openURL(PASS_PROTECT_TERMS_URL)}>
                    <Text style={styles.tcs}>Read our terms and conditions</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.container}>
                <PassProtectionCriteria status={requirements} />
            </View>
        </View>
    );
};

export default PassProtect;
