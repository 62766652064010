import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, Image } from 'react-native';
import { Link } from 'react-router-dom';
import styles from './styles.web';

import PageContainer from '../../PageContainer';
import PrimaryButton from '../../Buttons/PrimaryButton';
import LoadingState from '../../LoadingState/index.web';
import TrialPopup from '../../TrialPopup/index.web';

import { useAuth } from '../../../Context/AuthContext';
import { getUserTestType } from '../../../API/RealmComms.web';

import { UPGRADE_URL } from '../../../API/Environment.web';

const Tip = ({ text, icon }) => {
    return (
        <View style={styles.tipViewWeb}>
            {icon}
            <Text style={styles.tipText}>{text}</Text>
        </View>
    );
};

const IntroductionScreen = ({ setMockTestStage, isHgv }) => {
    const [loading, setLoading] = useState(true);
    const [trialExceeded, setTrialExceeded] = useState(false);

    const auth = useAuth();

    useEffect(() => {
        (async () => {
            setLoading(true);

            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <LoadingState />;
    } else {
        return (
            <View style={styles.container}>
                <ScrollView contentContainerStyle={styles.scrollview}>
                    <TrialPopup
                        trialExceeded={trialExceeded}
                        setTrialExceeded={setTrialExceeded}
                        onPress={() =>
                            (window.location.href = `${UPGRADE_URL}${auth.user.urlParams}`)
                        }
                    />

                    <PageContainer>
                        <Image
                            style={styles.headerImage}
                            source={require('../../../../assets/images/MockTest/PNG/mock-test.png')}
                        />

                        <Text style={styles.categoryName}>Theory questions</Text>

                        <Tip
                            icon={
                                <Image
                                    style={styles.iconStyle}
                                    source={require('../../../../assets/images/PracticeZone/PNG/icon-limit.png')}
                                />
                            }
                            text={isHgv ? '1 hour 55 minutes' : '57 minutes'}
                        />

                        <Tip
                            icon={
                                <Image
                                    style={styles.iconStyle}
                                    source={require('../../../../assets/images/PracticeZone/PNG/icon-question.png')}
                                />
                            }
                            text={isHgv ? '100 questions' : '50 questions'}
                        />

                        <Tip
                            icon={
                                <Image
                                    style={styles.iconStyle}
                                    source={require('../../../../assets/images/MockTest/PNG/icon-categories.png')}
                                />
                            }
                            text={'14 categories'}
                        />

                        <Tip
                            icon={
                                <Image
                                    style={styles.iconStyle}
                                    source={require('../../../../assets/images/MockTest/PNG/icon-trophy.png')}
                                />
                            }
                            text={isHgv ? 'Get 85 correct to pass' : 'Get 43 correct to pass'}
                        />

                        <View style={styles.spacer} />

                        <PrimaryButton
                            text="Start test"
                            onPress={async () => {
                                if (auth.user.trial === 'Yes') {
                                    const mockUserTests = await getUserTestType(null, '0');
                                    if (mockUserTests !== null || mockUserTests !== undefined) {
                                        if (mockUserTests?.length >= 1) {
                                            setTrialExceeded(true);
                                        } else {
                                            setMockTestStage(2);
                                        }
                                    } else {
                                        setMockTestStage(2);
                                    }
                                } else {
                                    setMockTestStage(2);
                                }
                            }}
                            textStyle={styles.primaryButtonText}
                        />

                        <Link to="/dashboard" style={styles.secondaryButtonStyle}>
                            <Text style={styles.secondaryButtonText}> Cancel </Text>
                        </Link>
                    </PageContainer>
                </ScrollView>
            </View>
        );
    }
};

export default IntroductionScreen;
