import React from 'react';
import { View, ImageBackground, Text, TouchableHighlight, Image } from 'react-native';
import styles from './styles';

import { useHistory } from 'react-router-dom';

import { useScreenSize } from '../../Utilities/utils';

const Parent = ({ screen, name, showButtons = true }) => {
    const Name = screen;
    const history = useHistory();
    const screenSize = useScreenSize();

    return (
        <View style={{ minHeight: '100vh', minWidth: '100%', flex: 1 }}>
            <ImageBackground
                source={require('../../../assets/images/Header/PNG/header.png')}
                resizeMode="stretch"
                style={styles.headerBackgroundImageWeb}>
                <View style={styles.buttonRowViews}>
                    {showButtons && (
                        <>
                            <TouchableHighlight
                                onPress={() => history.push('/dashboard')}
                                style={styles.dashboardHeaderProfileButtonLeft}>
                                <Image
                                    style={{
                                        width: 75,
                                        height: 75,
                                        resizeMode: 'contain',
                                        bottom: 10
                                    }}
                                    source={require('../../../assets/images/Login-Signup-Dashboard/Dashboard/SVG/dash-icon-header.svg')}
                                />
                            </TouchableHighlight>
                        </>
                    )}

                    {showButtons ? (
                        <>
                            <Text style={styles.dashboardTitleButtons}>{String(name)}</Text>
                        </>
                    ) : (
                        <>
                            <Text style={styles.dashboardTitle}>{String(name)}</Text>
                        </>
                    )}

                    <View style={styles.buttonRightView}>
                        {showButtons && (
                            <>
                                <TouchableHighlight
                                    onPress={() => history.push('/profile')}
                                    underlayColor="#cac7c6"
                                    style={styles.dashboardHeaderProfileButtonLeft}>
                                    <Image
                                        style={{ width: 50, height: 50, resizeMode: 'contain' }}
                                        source={require('../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/profile-icon.png')}
                                    />
                                </TouchableHighlight>
                            </>
                        )}
                    </View>
                </View>
            </ImageBackground>

            <View style={{ flex: 1, flexDirection: 'row' }}>
                <Name />
            </View>

            <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                <ImageBackground
                    source={require('../../../assets/images/Footer/footer.png')}
                    style={{
                        height: 150,
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                />
            </View>
        </View>
    );
};

export default Parent;
