import React, { useEffect, useState } from 'react';
import { View, Text, Image, Pressable } from 'react-native';
import styles from './styles';
import Video from 'react-player';
import { useHistory } from 'react-router-dom';

import ModalPopup from '../../../Components/Popup';
import { HAZARD_PERCEPTION_VIDEO } from '../../../API/Environment.web';

const VIDEO_LENGTH = 60;

const HazardPerceptionQuestion = ({
    currentQuestion,
    clickLogger,
    setClickLogger,
    saveQuestion,
    totalQuestions,
    currentIndex,
    reviewQuestion,
    score,
    setPlaybackClip
}) => {
    const [videoURL, setVideoURL] = useState();
    const [time, setTime] = useState();
    const [tooManyClicks, setTooManyClicks] = useState(false);
    const [clicksInTime, setClicksInTime] = useState([]);
    const history = useHistory();

    useEffect(() => {
        const intervalID = setTimeout(() => {
            setClicksInTime([]);
        }, 2000);

        return () => clearInterval(intervalID);
    }, [clicksInTime]);

    useEffect(() => {
        if (!reviewQuestion) {
            setClickLogger([]);
        }
        (async () => {
            const URL = `${HAZARD_PERCEPTION_VIDEO}${
                reviewQuestion ? currentQuestion.feedbackvideoref : currentQuestion.videoref
            }.mp4`;
            setVideoURL(URL);
        })();
    }, [currentQuestion]);

    const saveClick = () => {
        var clicks = [...clickLogger];
        clicksInTime.push(time);
        if (clicksInTime.length === parseInt(currentQuestion.attemptsallowedtwoseconds)) {
            setTooManyClicks(true);
        }
        clicks.push(time);
        setClickLogger(clicks);
    };

    const displayTimeline = (time) => {
        if (reviewQuestion) {
            time = time + parseInt(currentQuestion.feedbackstarttime);
        }
        const width = (time / VIDEO_LENGTH) * 100 + '%';
        return (
            <>
                {reviewQuestion && (
                    <View
                        style={{
                            backgroundColor: 'red',
                            height: 10,
                            width: width,
                            alignSelf: 'flex-start'
                        }}
                    />
                )}
            </>
        );
    };

    const displayFlag = (arr) => {
        return arr.map((click, key) => {
            const percentage = ((click / VIDEO_LENGTH) * 100).toFixed(0) + '%';
            return (
                <Image
                    id={key}
                    style={{
                        width: 25,
                        height: 25,
                        resizeMode: 'contain',
                        left: percentage,
                        position: 'absolute'
                    }}
                    source={require('../../../../assets/images/HazardPerception/SVG/icon-red-flag.svg')}
                />
            );
        });
    };

    const scoreBoundaries = (clipwindow) => {
        const clipWindows = JSON.parse(clipwindow);
        return Object.values(clipWindows).map((value) => {
            const width = ((value.endtime - value.starttime) / VIDEO_LENGTH) * 100 + '%';
            const startingPoint = (value.starttime / VIDEO_LENGTH) * 100 + '%';
            const color =
                value.score == 5
                    ? 'green'
                    : value.score == 4 || value.score == 3
                    ? 'orange'
                    : 'red';
            return (
                <View
                    style={{
                        height: 5,
                        width: width,
                        backgroundColor: color,
                        left: startingPoint,
                        alignSelf: 'flex-end',
                        marginLeft: -0.5,
                        position: 'absolute'
                    }}
                />
            );
        });
    };

    return (
        <>
            <ModalPopup
                visible={tooManyClicks}
                setVisible={setTooManyClicks}
                image={
                    <Image
                        style={{ width: 75, height: 75, resizeMode: 'contain' }}
                        source={require('../../../../assets/images/HazardPerception/SVG/too-many-clicks.svg')}
                    />
                }
                title={'Too many clicks!'}
                secondaryText={`You have clicked over ${currentQuestion.attemptsallowedtwoseconds} times in two seconds.`}
                buttonText={currentIndex + 1 < totalQuestions ? 'Next Question' : 'Go to results'}
                onPress={() => {
                    saveQuestion(currentQuestion, tooManyClicks, totalQuestions);
                    setTooManyClicks(false);
                }}
            />
            <View style={reviewQuestion ? styles.videoViewWebReview : styles.videoViewWeb}>
                <Pressable
                    onPress={() => (setPlaybackClip ? setPlaybackClip() : history.goBack())}
                    style={{
                        position: 'absolute',
                        alignSelf: 'flex-end',
                        right: 10,
                        top: 10,
                        zIndex: 100
                    }}>
                    <Image
                        style={{ width: 50, height: 50 }}
                        source={require('../../../../assets/images/HazardPerception/SVG/icon-video-close.svg')}
                    />
                </Pressable>
                {reviewQuestion && <Text style={styles.score}>{score}</Text>}
                <Pressable onPress={() => saveClick(time)} disabled={!!reviewQuestion}>
                    <View style={{ width: '100%', height: '100%' }}>
                        <Video
                            url={videoURL}
                            onEnded={() => {
                                setPlaybackClip ? setPlaybackClip() : saveQuestion(currentQuestion);
                            }}
                            muted={true}
                            onProgress={(time) => setTime(time.playedSeconds)}
                            resizeMode="cover"
                            playing={true}
                            playsinline={true}
                            width={reviewQuestion ? '100%' : '100%'}
                            height="100%"
                        />
                    </View>
                </Pressable>
                <View style={styles.barView}>
                    {displayTimeline(time)}
                    <View style={styles.innerBarView}>
                        {displayFlag(clickLogger || reviewQuestion)}
                        {reviewQuestion && scoreBoundaries(currentQuestion.clipwindow)}
                    </View>
                </View>
            </View>
        </>
    );
};

export default HazardPerceptionQuestion;
