import React, { useEffect, useState } from 'react';
import { View, Text, Dimensions } from 'react-native';
import styles from './styles.web';

import { useHistory } from 'react-router-dom';
import moment from 'moment';

import PrimaryButton from '../../../Components/Buttons/PrimaryButton';
import LoadingState from '../../../Components/LoadingState';
import Popup from '../../../Components/Popup/index.web';

import { useAuth } from '../../../Context/AuthContext';
import { logout } from '../../../API/Joomla.web';

import { CANCEL_SUBSCRIPTION_URL, UPGRADE_URL } from '../../../API/Environment.web';
import packageJson from '../../../../package.json';

const SectionInfo = ({ name, info }) => {
    return (
        <Text style={styles.sectionInfo}>
            <Text style={{ fontWeight: 'bold' }}>{name} </Text>
            {info}
        </Text>
    );
};

const ProfilePage = () => {
    const history = useHistory();
    const [popupVisible, setPopupVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    const auth = useAuth();

    useEffect(() => {
        setLoading(true);
        setLoading(false);
        return;
    }, []);

    if (loading) {
        return <LoadingState />;
    }

    return (
        <View style={styles.container}>
            <Popup
                visible={popupVisible}
                setVisible={setPopupVisible}
                title={'Are you sure?'}
                secondaryText={
                    'You will still have access to the application until your subscription expires.'
                }
                cancelsub
                skip
            />

            <Text style={styles.version}>Version: {packageJson.version}</Text>

            <Text style={styles.sectionTitle}>Profile Details -</Text>
            <SectionInfo name={'User ID:'} info={localStorage.getItem('userid')} />
            <SectionInfo name={'Email:'} info={localStorage.getItem('username')} />
            <SectionInfo name={'First Name:'} info={localStorage.getItem('firstname')} />
            <SectionInfo name={'Last Name:'} info={localStorage.getItem('lastname')} />
            <SectionInfo
                name={'Test Type:'}
                info={
                    localStorage.getItem('testtype') === '0'
                        ? 'Car Theory Test'
                        : localStorage.getItem('testtype') === '1'
                        ? 'Motorcycle Theory Test'
                        : 'HGV/LGV Theory Test'
                }
            />
            <SectionInfo
                name={'Test Date: '}
                info={
                    localStorage.getItem('dateoftest') != '0000-00-00 00:00:00'
                        ? localStorage.getItem('dateoftest')
                        : 'No date set'
                }
            />

            <PrimaryButton
                text={`Edit Profile`}
                onPress={() => {
                    history.push('/edit-profile');
                }}
                textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                style={{ width: '50%', backgroundColor: 'lightgrey', marginVertical: 20 }}
            />

            <Text style={styles.sectionTitle}>Subscription Details -</Text>
            <SectionInfo name={'Payment Plan:'} info={localStorage.getItem('planname')} />
            <SectionInfo
                name={'Expiry Date:'}
                info={moment(localStorage.getItem('expirydate')).format('hh:mm:ss DD/MM/YYYY')}
            />

            <PrimaryButton
                text={`Edit`}
                onPress={() => {
                    window.location.href = `${UPGRADE_URL}${auth.user.urlParams}`;
                }}
                textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                style={{ width: '50%', marginBottom: 30, backgroundColor: 'lightgrey' }}
            />

            <PrimaryButton
                text={`Cancel`}
                onPress={() => {
                    window.location.href = `${CANCEL_SUBSCRIPTION_URL}${auth.user.urlParams}`;
                }}
                textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                style={{ width: '50%', backgroundColor: 'lightgrey', marginBottom: 30 }}
            />

            <PrimaryButton
                text={`Log Out`}
                onPress={async () => {
                    await logout(localStorage.getItem('session'));
                    history.push('/login');
                }}
                textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                style={{ width: '75%' }}
            />
        </View>
    );
};

export default ProfilePage;
