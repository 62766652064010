import { API_BASE } from './Environment.web';

export async function register(
    email,
    password,
    firstName,
    lastName,
    testType,
    trial,
    length,
    paymentplan
) {
    fullName = firstName + ' ' + lastName;

    let formData = new FormData();

    formData.append('action', 'register');
    formData.append('email', `${email.trim()}`);
    formData.append('name', `${fullName}`);
    formData.append('password', `${password.trim()}`);
    formData.append('trial', `${trial}`);
    formData.append('testtype', `${testType}`);
    formData.append('paymentmethod', '');
    formData.append('length', `${length}`);
    formData.append('voucherused', '0');
    formData.append('vouchertype', '');
    formData.append('code', '');
    formData.append('securitycode', '');
    formData.append('total', '');

    const res = await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();

    if (data.error_code) {
        throw data.error;
    } else {
        await addSubscription(data.userid, length, paymentplan);
        await addStudent(firstName, lastName, testType, data.userid);
    }
}

export async function addSubscription(userid, length, paymentplan) {
    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'form|updatesubscription');
    formData.append('pre', '1');
    formData.append('userid', `${userid}`);
    formData.append('length', `${length}`);
    formData.append('paymentmethod', '1');
    formData.append('total', '');
    formData.append('paymentplan', `${paymentplan}`);
    formData.append('couponused', '');

    await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });
}

export async function addSubscriptionLogged(length, paymentplan) {
    const paymentmethod = Platform.select({
        ios: 3,
        android: 4
    });

    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'form|updatesubscription');
    formData.append('token', `${currentUser.token}`);
    formData.append('userid', `${currentUser.userid}`);
    formData.append('length', `${length}`);
    formData.append('paymentmethod', `${paymentmethod}`);
    formData.append('total', '');
    formData.append('paymentplan', `${paymentplan}`);
    formData.append('couponused', '');

    await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });
}

export async function addStudent(firstName, lastName, testType, userid) {
    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'form|updatestudentaccount');
    formData.append('userid', `${userid}`);
    formData.append('testtype', `${testType}`);
    formData.append('pre', '1');
    formData.append('dateoftest', '');
    formData.append('firstname', `${firstName}`);
    formData.append('lastname', `${lastName}`);

    await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });
}

export async function accountStatus(email) {
    let formData = new FormData();

    formData.append('action', 'accountstatus');
    formData.append('email', `${email}`);

    const res = await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();

    return data.content;
}

export async function check(email) {
    let formData = new FormData();

    formData.append('action', 'check');
    formData.append('email', `${email}`);

    await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });
}

export async function login(email, password) {
    let formData = new FormData();

    formData.append('action', 'login');
    formData.append('username', `${email}`);
    formData.append('password', `${password}`);

    const res = await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();

    if (data.error_code) {
        throw data.error;
    } else {
        return data;
    }
}

export async function reset(email) {
    let formData = new FormData();

    formData.append('action', 'forgotpassword');
    formData.append('email', `${email}`);

    const res = await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();

    if (data.error_code) {
        throw data.error;
    } else {
        return data;
    }
}

export async function fetchQuestions() {
    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'download|getquestions');
    formData.append('userid', localStorage.getItem('userid'));
    formData.append('token', localStorage.getItem('session'));

    const res = await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();

    const questions = data.content.filter((x) => x.testtype === localStorage.getItem('testtype'));

    return questions;
}

export async function fetchRoadsigns() {
    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'download|getroadsigns');
    formData.append('userid', localStorage.getItem('userid'));
    formData.append('token', localStorage.getItem('session'));

    const res = await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();

    return data.content;
}

export async function fetchCategories() {
    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'download|getcategories');
    formData.append('userid', localStorage.getItem('userid'));
    formData.append('token', localStorage.getItem('session'));

    const res = await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();

    return data.content;
}

export async function fetchHazards() {
    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'download|gethazards');
    formData.append('userid', localStorage.getItem('userid'));
    formData.append('token', localStorage.getItem('session'));

    const res = await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();

    return data.content;
}

export async function fetchUserQuestions() {
    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'download|getuseranswers');
    formData.append('userid', localStorage.getItem('userid'));
    formData.append('token', localStorage.getItem('session'));

    const res = await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();

    if (data.error_code) {
        return [];
    } else {
        return data.content;
    }
}

export async function fetchUserHazards() {
    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'download|getuserhazardanswers');
    formData.append('userid', localStorage.getItem('userid'));
    formData.append('token', localStorage.getItem('session'));

    const res = await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();

    return data.content;
}

export async function fetchUserTests() {
    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'download|getusertests');
    formData.append('userid', localStorage.getItem('userid'));
    formData.append('token', localStorage.getItem('session'));

    const res = await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();

    return data.content;
}

export async function addAnswers(userid, token, questionsTracker) {
    questionsTracker.forEach(async (questionSet) => {
        let formData = new FormData();

        const questions = JSON.stringify(questionSet);

        formData.append('action', 'content');
        formData.append('route', 'form|updateanswers');
        formData.append('userid', `${userid}`);
        formData.append('answers', `[${questions}]`);
        formData.append('token', `${token}`);

        await fetch(API_BASE, {
            method: 'POST',
            body: formData
        });
    });
}

export async function addTestResult(
    userid,
    token,
    totalQuestions,
    userScore,
    questionFeedbackBreakdown,
    result,
    testType
) {
    const questionlist = JSON.stringify(questionFeedbackBreakdown);

    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'form|addtest');
    formData.append('userid', `${userid}`);
    formData.append('token', `${token}`);
    formData.append('testtype', `${testType}`);
    formData.append('result', `${result}`);
    formData.append('totalquestions', `${totalQuestions}`);
    formData.append('correctanswers', `${userScore}`);
    formData.append('questionlist', `${questionlist}`);

    await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });
}

export async function logout(token) {
    let formData = new FormData();

    formData.append('action', 'logout');
    formData.append('token', `${token}`);

    await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    localStorage.clear();
}

export async function updateProfile(username, userid, firstname, lastname, password, token) {
    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'form|updateprofile');
    formData.append('userid', `${userid}`);
    formData.append('firstname', `${firstname}`);
    formData.append('lastname', `${lastname}`);
    formData.append('password', `${password}`);
    formData.append('testtype', localStorage.getItem('testtype'));
    formData.append('token', `${token}`);

    await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    localStorage.setItem('firstname', firstname);
    localStorage.setItem('lastname', lastname);
}

export async function addHazards(userid, token, questionsTracker) {
    questionsTracker.forEach(async (questionSet) => {
        let formData = new FormData();

        const questions = JSON.stringify(questionSet);

        formData.append('action', 'content');
        formData.append('route', 'form|updatehazard');
        formData.append('userid', `${userid}`);
        formData.append('answers', `[${questions}]`);
        formData.append('token', `${token}`);

        await fetch(API_BASE, {
            method: 'POST',
            body: formData
        });
    });
}

export async function updateTestDate(userid, dateoftest, token) {
    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'form|updatestudenttest');
    formData.append('userid', `${userid}`);
    formData.append('dateoftest', `${dateoftest}`);
    formData.append('token', `${token}`);

    await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    localStorage.setItem('dateoftest', String(dateoftest));
}

export async function getTrialTime(time) {
    const split = time.split('.');
    const hours = parseInt(split[0]);
    const minutes = (parseInt(split[1]) * 60) / 100;
    const minSplit = String(minutes).split('.');

    const timeLeft = `${hours} hours and ${minSplit[0]} minutes`;

    return timeLeft;
}

export function arrayRows(array, num, fillLastRow) {
    const res = [];
    const copy = [...array];
    let slice = [];
    while (copy.length) {
        slice.push(copy.shift());
        if (slice.length === num) {
            res.push(slice);
            slice = [];
        }
    }
    if (slice.length) {
        res.push(slice);
    }
    if (fillLastRow) {
        const lastRowLength = res[res.length - 1].length;
        for (let i = lastRowLength; i < num; i++) {
            res[res.length - 1].push(null);
        }
    }
    return res;
}

export async function checkPPStatus(token, userId) {
    let formData = new FormData();

    formData.append('action', 'content');
    formData.append('route', 'list|get_pass_protection');
    formData.append('token', `${token}`);
    formData.append('userid', `${userId}`);

    const res = await fetch(API_BASE, {
        method: 'POST',
        body: formData
    });

    const data = await res.json();

    return data.content;
}
