import React, { useState } from 'react';
import { View, Text, ScrollView, TouchableHighlight, Image } from 'react-native';
import styles from './styles.web';
import { useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import SectionFeedback from '../../../Components/ResultTemplates/SectionFeedback/index.web';

const ToggleButton = ({ text, onPress, selected }) => {
    return (
        <TouchableHighlight
            onPress={onPress}
            underlayColor="#cac7c6"
            style={[styles.toggleButton, selected && styles.selectedButton]}>
            <Text style={[styles.toggleText, selected && styles.selectedText]}> {text} </Text>
        </TouchableHighlight>
    );
};

const ProgressPageIndividualResults = () => {
    const [sort, setSort] = useState('All');
    const location = useLocation();

    const categories = location.categories;
    const questionFeedbackBreakdown = JSON.parse(location.userTest.item.questionlist);
    const totalQuestions = location.userTest.item.totalquestions;
    const userScore = location.userTest.item.correctanswers;

    const CategoryName = ({ categoryID }) => {
        const categoryObject = categories.find((o) => o.id === categoryID);
        return categoryObject.name;
    };

    return (
        <View style={{ flex: 1 }}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                <View style={styles.feedbackView}>
                    <View style={styles.headerView}>
                        <Image
                            style={{ width: 150, height: 150, resizeMode: 'contain' }}
                            source={require('../../../../assets/images/ProgressPage/PNG/trophy.png')}
                        />
                        <Text style={styles.scoreStyle}>
                            {userScore}/{totalQuestions}
                        </Text>
                        <Text style={styles.dateStyle}>
                            {format(parseISO(location.userTest.item.datecompleted), 'dd/MM/yyyy')}
                        </Text>
                    </View>

                    <View
                        style={{
                            flexDirection: 'row',
                            alignSelf: 'flex-start',
                            paddingBottom: 20
                        }}>
                        <ToggleButton
                            text={'All'}
                            onPress={() => {
                                setSort('All');
                            }}
                            selected={sort === 'All'}
                        />
                        <ToggleButton
                            text={'✓ Correct'}
                            onPress={() => {
                                setSort('Correct');
                            }}
                            selected={sort === 'Correct'}
                        />
                        <ToggleButton
                            text={'X Incorrect'}
                            onPress={() => {
                                setSort('Incorrect');
                            }}
                            selected={sort === 'Incorrect'}
                        />
                    </View>
                    {sort === 'All' ? (
                        <SectionFeedback
                            questionFeedbackBreakdown={questionFeedbackBreakdown}
                            CategoryName={CategoryName}
                            sort={sort}
                        />
                    ) : sort === 'Correct' ? (
                        <SectionFeedback
                            questionFeedbackBreakdown={questionFeedbackBreakdown}
                            CategoryName={CategoryName}
                            sort={sort}
                        />
                    ) : (
                        sort === 'Incorrect' && (
                            <SectionFeedback
                                questionFeedbackBreakdown={questionFeedbackBreakdown}
                                CategoryName={CategoryName}
                                sort={sort}
                            />
                        )
                    )}
                </View>
            </ScrollView>
        </View>
    );
};

export default ProgressPageIndividualResults;
