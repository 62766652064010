import React from 'react';
import { View, Text, Image, Linking } from 'react-native';
import styles from './styles';

import ModalPopup from '../../Components/Popup/index.web';

import { useAuth } from '../../Context/AuthContext';
import { useHistory } from 'react-router-dom';
import { logout } from '../../API/Joomla.web';
import { getUserTestType } from '../../API/RealmComms.web';
import { HIGHWAY_CODE_URL, UPGRADE_URL } from '../../API/Environment.web';

const Sidebar = () => {
    const [trainingVisible, setTrainingVisible] = React.useState(false);
    const [trialExceeded, setTrialExceeded] = React.useState(false);

    const history = useHistory();
    const auth = useAuth();

    return (
        <View style={{ width: '20%', backgroundColor: 'white' }}>
            <ModalPopup
                visible={trainingVisible}
                setVisible={setTrainingVisible}
                image={
                    <Image
                        style={{ width: 250, height: 250, resizeMode: 'contain' }}
                        source={require('../../../assets/images/Popup/PNG/getting-started.png')}
                    />
                }
                title={'Getting started'}
                secondaryText={
                    "Take a short test and we'll build you a bespoke training plan so you can focus your learning on your weakest topics."
                }
                buttonText={'Get started'}
                buttonLink={'/training'}
                subText={'Short on time? Skip this and create a training plan later.'}
                skip
            />

            <ModalPopup
                visible={trialExceeded}
                setVisible={setTrialExceeded}
                image={
                    <Image
                        style={{ width: 150, height: 150, resizeMode: 'contain' }}
                        source={require('../../../assets/images/Popup/SVG/trial-finished.svg')}
                    />
                }
                title={'Trial Exceeded'}
                secondaryText={'Please upgrade your plan to gain access to this section.'}
                buttonText={'Choose a plan'}
                buttonLink={'/choose-a-plan'}
                skip
            />

            <View style={{ width: '80%', alignSelf: 'center' }}>
                <Image
                    style={styles.logoImage}
                    source={require('../../../assets/images/Login-Signup-Dashboard/Welcome/ttp-logo.png')}
                />

                <Text style={styles.topMenu} onPress={() => history.push('/dashboard')}>
                    {' '}
                    Dashboard{' '}
                </Text>

                <Text
                    style={styles.topMenu}
                    onPress={async () => {
                        if (auth.user.trial === 'Yes') {
                            const mockUserTests = await getUserTestType(null, '0');
                            if (mockUserTests !== null || mockUserTests !== undefined) {
                                if (mockUserTests?.length >= 1) {
                                    setTrialExceeded(true);
                                } else {
                                    setTrainingVisible(true);
                                }
                            } else {
                                setTrainingVisible(true);
                            }
                        } else {
                            setTrainingVisible(true);
                        }
                    }}>
                    {' '}
                    Training Plan{' '}
                </Text>

                <Text style={styles.topMenu} onPress={() => history.push('/practice-zone')}>
                    {' '}
                    Practice Zone{' '}
                </Text>

                <Text style={styles.topMenu} onPress={() => history.push('/mock-test')}>
                    {' '}
                    Mock Test{' '}
                </Text>

                <Text style={styles.topMenu} onPress={() => history.push('/quick-test-dashboard')}>
                    {' '}
                    Quick Test{' '}
                </Text>

                <Text
                    style={styles.topMenu}
                    onPress={() => history.push('/hazard-perception-dashboard')}>
                    {' '}
                    Hazard Perception{' '}
                </Text>

                {auth.user.testtype !== '2' && (
                    <Text
                        style={styles.topMenu}
                        onPress={() => history.push('/learn-how-to-dashboard')}>
                        {' '}
                        Driving Lessons{' '}
                    </Text>
                )}

                <Text style={styles.topMenu} onPress={() => history.push('/roadsign-dashboard')}>
                    {' '}
                    Road Signs{' '}
                </Text>

                <Text style={styles.topMenu} onPress={() => Linking.openURL(HIGHWAY_CODE_URL)}>
                    {' '}
                    Highway Code{' '}
                </Text>

                <Text style={styles.topMenu} onPress={() => history.push('/progress')}>
                    {' '}
                    Progress{' '}
                </Text>
            </View>

            <View
                style={{
                    width: '80%',
                    borderBottomWidth: 1,
                    borderBottomColor: 'black',
                    alignSelf: 'center',
                    marginVertical: 30
                }}
            />

            <View style={{ width: '80%', alignSelf: 'center' }}>
                <Text style={styles.topMenu} onPress={() => history.push('/profile')}>
                    {' '}
                    Personal Details{' '}
                </Text>

                <Text
                    style={styles.topMenu}
                    onPress={() => (window.location.href = `${UPGRADE_URL}${auth.user.urlParams}`)}>
                    {' '}
                    Subscription{' '}
                </Text>

                <Text
                    style={styles.topMenu}
                    onPress={async () => {
                        await logout(localStorage.getItem('session'));
                        history.push('/login');
                    }}>
                    {' '}
                    Log Out{' '}
                </Text>
            </View>
        </View>
    );
};

export default Sidebar;
