import React from 'react';
import { Text, Image, View } from 'react-native';
import styles from './styles';

import { GET_ONLINE_IMAGE_PATH } from '../../API/Environment.web';

const Card = ({ image, heading, text }) => {
    return (
        <View style={styles.container}>
            <View style={styles.innerContainer}>
                <Image style={styles.image} source={{ uri: `${GET_ONLINE_IMAGE_PATH + image}` }} />

                <View style={styles.textContainer}>
                    <Text style={styles.headingWeb}>{heading}</Text>

                    <Text style={styles.textWeb}>{text}</Text>
                </View>
            </View>
        </View>
    );
};

export default Card;
