import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F3F2F5'
    },

    pageContainer: {
        paddingHorizontal: 30,
        paddingVertical: 20
    },

    practiceZoneTitle: {
        fontSize: Dimensions.get('window').height / 50,
        fontWeight: 'bold',
        color: '#0D0334',
        marginVertical: 15
    },

    practiceZoneDescription: {
        color: '#868199',
        fontSize: Dimensions.get('window').height / 60,
        marginBottom: 25
    },

    categoryButtonView: {
        backgroundColor: '#ffffff50',
        borderRadius: 5,
        marginBottom: 10
    },

    categoryButtonWebView: {
        backgroundColor: '#ffffff',
        width: '32%',
        borderRadius: 5,
        marginBottom: 10,
        marginRight: 10
    },

    categoryButtonTitle: {
        color: '#0D0334',
        fontSize: Dimensions.get('window').height / 60,
        fontWeight: 'bold',
        marginLeft: 5,
        maxWidth: '85%'
    },

    categoryButtonTitleWeb: {
        color: '#0D0334',
        fontSize: Dimensions.get('window').height / 60,
        fontWeight: 'bold',
        marginLeft: 10,
        width: '100%'
    },

    categoryButtonChildren: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 15
    },

    questionAttempts: {
        fontSize: Dimensions.get('window').height / 60
    },

    questionNumber: {
        fontWeight: 'bold',
        marginLeft: 10,
        fontSize: Dimensions.get('window').height / 60
    },

    padlockIcon: {
        width: 20,
        height: 20,
        resizeMode: 'contain',
        alignSelf: 'center',
        marginTop: 5
    }
});

export default styles;
