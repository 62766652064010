import React from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import styles from './styles';

import { useHistory } from 'react-router-dom';

import CategoryIcon from '../CategoryIcons';

import { useScreenSize } from '../../Utilities/utils';

const Card = ({ heading, text, id, pathname, isLearnHowTo = false }) => {
    const history = useHistory();
    const screenSize = useScreenSize();

    return (
        <TouchableOpacity
            style={
                screenSize.large ? styles.touchableContainerWebFull : styles.touchableContainerWeb
            }
            onPress={() =>
                history.push({
                    pathname: pathname,
                    id: id,
                    heading: heading
                })
            }>
            <View style={styles.container}>
                {isLearnHowTo && (
                    <View style={styles.iconContainer}>
                        <CategoryIcon categoryName={heading} size={screenSize.large ? 50 : 30} />
                    </View>
                )}
                <View style={styles.textContainer}>
                    <Text style={styles.headingWeb}>{heading}</Text>

                    <Text style={styles.textWeb}>{text}</Text>
                </View>

                <View style={styles.arrowContainer}>
                    <Image
                        style={styles.arrow}
                        source={require('../../../assets/images/Roadsign/PNG/icon-right-arrow.png')}
                    />
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default Card;
