import React, { useState } from 'react';
import { View, ScrollView, Text, TouchableHighlight, Image, Dimensions } from 'react-native';
import styles from './styles.web';

import { useHistory, useLocation } from 'react-router-dom';

import Dial from '../../../Components/Dial';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton';
import HazardPerceptionQuestion from '../../../Components/QuestionTemplates/HazardPerceptionQuestion/index.web';

import { HAZARD_PERCEPTION_PASS, HGV_HAZARD_PERCEPTION_PASS } from '../../../API/Environment.web';

const FeedbackBox = ({ hazard, setPlaybackClip }) => {
    const score = hazard[1];
    return (
        <TouchableHighlight
            onPress={() => setPlaybackClip(hazard)}
            underlayColor="#cac7c6"
            style={styles.hazardButton}>
            <View style={styles.hazardButtonView}>
                <Text style={styles.hazardButtonText}>{hazard[0].name}</Text>
                <View style={styles.hazardButtonInnerView}>
                    <Text
                        style={[
                            styles.hazardButtonScore,
                            score < 3
                                ? styles.red
                                : score === 3
                                ? styles.amber
                                : score > 3 && styles.green
                        ]}>
                        {score}/5
                    </Text>
                    <Image
                        style={{ width: '5vw', height: '5vh', resizeMode: 'contain' }}
                        source={require('../../../../assets/images/HazardPerception/PNG/icon-play-blue.png')}
                    />
                </View>
            </View>
        </TouchableHighlight>
    );
};

const ResultsScreen = ({}) => {
    const history = useHistory();
    const location = useLocation();

    const questionFeedbackBreakdown = JSON.parse(location.userTest.item.questionlist);
    const testScore = location.userTest.item.correctanswers;
    const maxScore = location.userTest.item.totalquestions;

    const [playbackClip, setPlaybackClip] = useState();

    const testPercentage = (testScore / maxScore) * 100;

    const isHgv = localStorage.getItem('testtype') === '2';

    const isTestPass =
        testPercentage >= (isHgv ? HGV_HAZARD_PERCEPTION_PASS : HAZARD_PERCEPTION_PASS);

    if (playbackClip) {
        return (
            <HazardPerceptionQuestion
                currentQuestion={playbackClip[0]}
                score={playbackClip[1]}
                setPlaybackClip={setPlaybackClip}
                reviewQuestion={playbackClip[2]}
            />
        );
    } else {
        return (
            <View style={{ flex: 1 }}>
                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <View style={styles.headerBackground}>
                        <Dial
                            scorePercentage={testPercentage}
                            prompt={false}
                            noPercentage={true}
                            noNote={true}
                        />

                        <Text style={styles.score}>
                            {testScore}/{maxScore}
                        </Text>

                        <Text
                            style={[styles.scoreFeedback, isTestPass ? styles.pass : styles.fail]}>
                            {isTestPass ? 'PASS' : 'FAIL'}
                        </Text>
                    </View>

                    <View style={styles.feedbackViewWeb}>
                        {Object.entries(questionFeedbackBreakdown).map(([key, value]) => {
                            return <FeedbackBox hazard={value} setPlaybackClip={setPlaybackClip} />;
                        })}

                        <View style={styles.buttonViewWeb}>
                            <PrimaryButton
                                text="Back to results"
                                onPress={() => history.push('/progress/hazard')}
                                style={{ marginVertical: 20, width: '90%' }}
                                textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                            />
                        </View>
                    </View>
                </ScrollView>
            </View>
        );
    }
};

export default ResultsScreen;
