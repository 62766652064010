import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    buttonView: {
        width: '100%',
        marginBottom: 15,
        flexDirection: 'row'
    },

    button: {
        backgroundColor: '#F3F2F5',
        borderRadius: 50,
        marginRight: 10,
        alignItems: 'center',
        width: '90%',
        height: 'auto',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    buttonWeb: {
        backgroundColor: '#F3F2F5',
        borderRadius: 50,
        marginRight: 10,
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    buttonText: {
        fontSize: Dimensions.get('window').height / 70,
        color: '#868199',
        margin: 15,
        marginRight: 0,
        width: '75%'
    },

    buttonTts: {
        marginTop: 5
    },

    correctButton: {
        backgroundColor: '#8CC63F'
    },

    correctNonSelectedButton: {
        borderColor: '#8CC63F',
        borderWidth: 1
    },

    correctText: {
        color: 'white'
    },

    incorrectButton: {
        backgroundColor: '#F0040F'
    },

    incorrectText: {
        color: 'white'
    }
});

export default styles;
