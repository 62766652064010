import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        minHeight: '100vh',
        width: '100vw',
        margin: 'auto',
        paddingHorizontal: 36,
        backgroundColor: '#F3F2F5'
    },

    dashboardButtonStyleWeb: {
        backgroundColor: '#fff',
        borderWidth: 3,
        borderColor: 'black',
        padding: 26,
        borderRadius: 12,
        marginBottom: 20,
        width: '75%'
    },

    dashboardButtonText: {
        fontSize: Dimensions.get('window').height / 40,
        fontWeight: 'bold',
        color: '#0D0334',
        paddingLeft: 10
    },

    dashboardButtonView: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    hintText: {
        fontWeight: 'bold',
        color: '#0D0334',
        fontSize: Dimensions.get('window').height / 40,
        paddingTop: 15,
        paddingBottom: 15
    },

    videoButtonStyle: {
        width: '29%',
        marginRight: 25,
        marginBottom: 45,
        flexDirection: 'column'
    },

    videoStyle: {
        height: 200,
        borderRadius: 12,
        marginBottom: 10,
        justifyContent: 'center'
    },

    videoTitle: {
        textAlign: 'left',
        color: '#0D0334',
        fontWeight: 'bold',
        fontSize: 12
    },

    hazardRow: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },

    thumbnail: {
        height: '100%',
        width: '100%',
        borderRadius: 10,
        resizeMode: 'stretch'
    },

    groupTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#0D0334',
        marginBottom: 20
    },

    scoreView: {
        height: 'auto'
    },

    playIcon: {
        position: 'absolute',
        alignSelf: 'center'
    },

    ctaButton: {
        marginBottom: 40,
        width: 'fit-content'
    },

    ctaButtonText: {
        fontSize: 14
    },

    videoIcon: {
        width: 100,
        height: 100,
        resizeMode: 'contain',
        position: 'absolute',
        alignSelf: 'center'
    },

    learnHowToHeading: {
        fontSize: 24,
        fontWeight: 'bold',
        marginVertical: 40
    }
});

export default styles;
