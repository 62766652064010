import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    headingText: {
        paddingBottom: 15,
        fontWeight: 'bold',
        fontSize: Dimensions.get('window').height / 50,
        alignSelf: 'flex-start',
        color: '#0D0334'
    },

    secondaryButton: {
        marginTop: 20,
        width: '100%',
        padding: 10,
        borderRadius: 20
    },

    secondaryButtonText: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#0D0334',
        textAlign: 'center'
    },

    categoryName: {
        color: '#0D0334',
        fontSize: 32,
        textAlign: 'center',
        fontWeight: 'bold',
        marginVertical: 10
    },

    tipView: {
        width: '100%',
        alignItems: 'flex-start',
        marginLeft: 88,
        marginVertical: 8,
        flexDirection: 'row'
    },

    tipViewWeb: {
        alignSelf: 'center',
        marginVertical: 8,
        flexDirection: 'row'
    },

    tipText: {
        color: '#868199',
        fontSize: Dimensions.get('window').height / 80,
        marginLeft: 10
    },

    iconStyle: {
        resizeMode: 'contain',
        height: 20,
        width: 20
    },

    secondaryButtonStyle: {
        marginTop: 20,
        width: '100%',
        padding: 10,
        borderRadius: 20,
        textAlign: 'center'
    },

    spacer: {
        marginBottom: 20
    },

    headerImage: {
        resizeMode: 'contain',
        width: '30vw',
        height: '30vh'
    },

    container: {
        height: '100%',
        flex: 1,
        maxWidth: '75%',
        margin: 'auto'
    },

    scrollview: {
        flexGrow: 1
    },

    primaryButtonText: {
        fontSize: Dimensions.get('window').height / 60
    }
});

export default styles;
