import { useState, useEffect } from 'react';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export function useScreenSize() {
    const { width } = useWindowDimensions();

    const breakpoints = {
        xs: 450,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400
    };

    const screenSizes = {
        mobile: width <= breakpoints.xs,
        small: width <= breakpoints.sm,
        medium: width <= breakpoints.md,
        large: width <= breakpoints.lg,
        extraLarge: width <= breakpoints.xl,
        extraExtraLarge: width <= breakpoints.xxl
    };

    return screenSizes;
}
