import React, { useEffect, useState } from 'react';
import { ScrollView, View, TouchableHighlight, Text, FlatList, Image } from 'react-native';
import styles from './styles.web';

import { useHistory } from 'react-router-dom';
import ProgressBar from '@ramonak/react-progress-bar';

import TrialPopup from '../../../Components/TrialPopup/index.web';
import CategoryIcon from '../../../Components/CategoryIcons/index.web';
import LoadingState from '../../../Components/LoadingState';

import { useAuth } from '../../../Context/AuthContext';
import { UPGRADE_URL } from '../../../API/Environment.web';
import {
    fetchUserQuestionsCategoryList,
    fetchCategoriesForRelevantTestType
} from '../../../API/RealmComms.web';

import { useScreenSize } from '../../../Utilities/utils';

const GroupedFlatlist = ({
    userQuestionList,
    groupName,
    categoryTotalQuestions,
    setTrialExceeded
}) => {
    const history = useHistory();
    const screenSize = useScreenSize();
    const auth = useAuth();

    if (!screenSize.large) {
        return (
            <FlatList
                numColumns={3}
                data={groupName}
                keyExtractor={(item) => item.id}
                renderItem={({ item }) => (
                    <CategoryButton
                        onPress={() => {
                            auth.user.trial === 'No' || item.showontrial == '1'
                                ? history.push({
                                      pathname: '/practice-zone-test',
                                      categoryId: item.id,
                                      categoryName: item.name
                                  })
                                : setTrialExceeded(true);
                        }}
                        name={item.name}
                        showontrial={item.showontrial}
                        id={item.id}
                        totalQuestions={categoryTotalQuestions[item.id]}
                        userQuestions={userQuestionList[item.id]}
                    />
                )}
            />
        );
    } else {
        return (
            <FlatList
                numColumns={1}
                data={groupName}
                keyExtractor={(item) => item.id}
                renderItem={({ item }) => (
                    <CategoryButton
                        onPress={() => {
                            auth.user.trial === 'No' || item.showontrial == '1'
                                ? history.push({
                                      pathname: '/practice-zone-test',
                                      categoryId: item.id,
                                      categoryName: item.name
                                  })
                                : setTrialExceeded(true);
                        }}
                        name={item.name}
                        showontrial={item.showontrial}
                        id={item.id}
                        totalQuestions={categoryTotalQuestions[item.id]}
                        userQuestions={userQuestionList[item.id]}
                    />
                )}
            />
        );
    }
};

const CategorySection = ({ categoryName, categoryDescription }) => {
    return (
        <View>
            <Text style={styles.practiceZoneTitle}>{categoryName}</Text>

            <Text style={styles.practiceZoneDescription}>{categoryDescription}</Text>
        </View>
    );
};

const CategoryButton = ({ onPress, name, totalQuestions, userQuestions, showontrial }) => {
    const screenSize = useScreenSize();
    const auth = useAuth();

    let percentage = 0;
    userQuestions = userQuestions || [];
    if (totalQuestions === 0 || userQuestions.length === 0) {
        percentage = 0;
    } else {
        percentage = (userQuestions.length / totalQuestions).toFixed(2);
    }

    return (
        <View
            style={
                auth.user.trial === 'No' || showontrial == '1'
                    ? [styles.categoryButtonWebView, { width: !screenSize.large ? '32%' : '100%' }]
                    : [
                          styles.categoryButtonWebView,
                          {
                              width: !screenSize.large ? '32%' : '100%',
                              backgroundColor: '#ffffff70'
                          }
                      ]
            }>
            <TouchableHighlight
                onPress={onPress}
                underlayColor="#cac7c6"
                style={styles.dashboardHeaderButtonStyle}>
                <View style={styles.categoryButtonChildren}>
                    <View style={{ justifyContent: 'space-between', width: '70%' }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10,
                                marginTop: 5
                            }}>
                            {auth.user.trial === 'No' || showontrial == '1' ? (
                                <CategoryIcon categoryName={name && name} />
                            ) : (
                                <Image
                                    style={styles.padlockIcon}
                                    source={require('../../../../assets/images/HazardPerception/PNG/padlock.png')}
                                />
                            )}
                            {auth.user.trial === 'No' || showontrial == '1' ? (
                                <Text
                                    style={[
                                        styles.categoryButtonTitleWeb,
                                        { maxWidth: !screenSize.large ? '70%' : '80%' }
                                    ]}>
                                    {name}
                                </Text>
                            ) : (
                                <Text
                                    style={[
                                        styles.categoryButtonTitleWeb,
                                        {
                                            maxWidth: !screenSize.large ? '70%' : '80%',
                                            color: 'grey'
                                        }
                                    ]}>
                                    {name} (Locked)
                                </Text>
                            )}
                        </View>

                        <ProgressBar
                            animateOnRender={true}
                            completed={Number(percentage * 100).toFixed(0)}
                            customLabel={`${Number(percentage * 100).toFixed(0)}%`}
                            bgColor="#11AD04"
                            baseBgColor="#F3F2F5"
                            height="18"
                        />

                        <View style={{ flexDirection: 'row', marginTop: 10 }}>
                            <Text style={styles.questionAttempts}>Question's Correct:</Text>

                            <Text style={styles.questionNumber}>
                                {`${userQuestions.length || 0}/${totalQuestions || 0}`}
                            </Text>
                        </View>
                    </View>
                    <View style={{ width: '20%', display: 'flex', alignItems: 'flex-end' }}>
                        {auth.user.trial === 'No' || showontrial == '1' ? (
                            <>
                                {percentage < 0.33 ? (
                                    <Image
                                        style={{ resizeMode: 'contain', height: 100, width: 30 }}
                                        source={require('../../../../assets/images/PracticeZone/PNG/traffic-red.png')}
                                    />
                                ) : percentage >= 0.33 && percentage <= 0.66 ? (
                                    <Image
                                        style={{ resizeMode: 'contain', height: 100, width: 30 }}
                                        source={require('../../../../assets/images/PracticeZone/PNG/traffic-amber.png')}
                                    />
                                ) : (
                                    percentage > 0.66 && (
                                        <Image
                                            style={{
                                                resizeMode: 'contain',
                                                height: 100,
                                                width: 30
                                            }}
                                            source={require('../../../../assets/images/PracticeZone/PNG/traffic-green.png')}
                                        />
                                    )
                                )}
                            </>
                        ) : (
                            <Image
                                style={{ resizeMode: 'contain', height: 100, width: 30 }}
                                source={require('../../../../assets/images/PracticeZone/SVG/traffic-grey.svg')}
                            />
                        )}
                    </View>
                </View>
            </TouchableHighlight>
        </View>
    );
};

const PracticeZoneDashboard = () => {
    const [categoryGroups, setCategoryGroups] = useState(null);
    const [categoryTotalQuestions, setCategoryTotalQuestions] = useState(null);
    const [userQuestionList, setUserQuestionList] = useState(null);
    const [trialExceeded, setTrialExceeded] = useState(false);
    const [loading, setLoading] = useState(true);

    const auth = useAuth();

    useEffect(() => {
        (async () => {
            var categories;

            categories = await fetchCategoriesForRelevantTestType();

            var userCategoryQuestions = await fetchUserQuestionsCategoryList();
            const categoryGrouping = {
                'Areas of focus': [],
                'Good start': [],
                'Speeding ahead': []
            };

            setUserQuestionList(userCategoryQuestions);

            var categoryQuestionsTotalDict = {};

            categories.forEach((category) => {
                var percentage =
                    (
                        ((userCategoryQuestions[category[0].id]?.length || 0) / category[1]) *
                        100
                    ).toFixed(0) / 100;
                if (percentage < 0.33) {
                    categoryGrouping['Areas of focus'].push(category[0]);
                } else if (percentage >= 0.33 && percentage <= 0.66) {
                    categoryGrouping['Good start'].push(category[0]);
                } else {
                    categoryGrouping['Speeding ahead'].push(category[0]);
                }

                categoryQuestionsTotalDict[category[0].id] = category[1];
            });

            setCategoryTotalQuestions(categoryQuestionsTotalDict);
            setCategoryGroups(categoryGrouping);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <LoadingState />;
    } else {
        return (
            <View style={styles.container}>
                <ScrollView>
                    <TrialPopup
                        trialExceeded={trialExceeded}
                        setTrialExceeded={setTrialExceeded}
                        onPress={() =>
                            (window.location.href = `${UPGRADE_URL}${auth.user.urlParams}`)
                        }
                    />

                    <View style={styles.pageContainer}>
                        {categoryGroups && categoryGroups['Areas of focus']?.length > 0 && (
                            <>
                                <CategorySection
                                    categoryName={'Areas of focus'}
                                    categoryDescription={
                                        'Focus your revision on these categories which we’ve identified as your weakest areas'
                                    }
                                />

                                <GroupedFlatlist
                                    groupName={categoryGroups['Areas of focus']}
                                    categoryTotalQuestions={categoryTotalQuestions}
                                    userQuestionList={userQuestionList}
                                    setTrialExceeded={setTrialExceeded}
                                />
                            </>
                        )}

                        {categoryGroups && categoryGroups['Good start']?.length > 0 && (
                            <>
                                <CategorySection
                                    categoryName={'Good start!'}
                                    categoryDescription={
                                        'These categories you are performing moderately well in, considering spending some more time on these too'
                                    }
                                />

                                <GroupedFlatlist
                                    groupName={categoryGroups['Good start']}
                                    categoryTotalQuestions={categoryTotalQuestions}
                                    userQuestionList={userQuestionList}
                                    setTrialExceeded={setTrialExceeded}
                                />
                            </>
                        )}

                        {categoryGroups && categoryGroups['Speeding ahead']?.length > 0 && (
                            <>
                                <CategorySection
                                    categoryName={'Speeding Ahead'}
                                    categoryDescription={
                                        'You’re performing well in these categories'
                                    }
                                />

                                <GroupedFlatlist
                                    groupName={categoryGroups['Speeding ahead']}
                                    categoryTotalQuestions={categoryTotalQuestions}
                                    userQuestionList={userQuestionList}
                                    setTrialExceeded={setTrialExceeded}
                                />
                            </>
                        )}
                    </View>
                </ScrollView>
            </View>
        );
    }
};

export default PracticeZoneDashboard;
