import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1
    },

    headingText: {
        paddingBottom: 33,
        fontWeight: 'bold',
        fontSize: 22,
        color: '#0D0334',
        alignSelf: 'flex-start'
    },

    navigationButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 10,
        borderTopColor: '#0D03341A',
        borderTopWidth: 1,
        paddingBottom: 15,
        backgroundColor: 'white'
    },

    nextButton: {
        backgroundColor: '#8CC63F',
        borderRadius: 50,
        marginRight: 25,
        alignItems: 'center',
        width: '40%'
    },

    nextButtonText: {
        fontSize: 16,
        color: '#F6F9FC',
        margin: 15
    },

    previousButton: {
        backgroundColor: '#F3F2F5',
        borderRadius: 50,
        marginLeft: 25,
        alignItems: 'center',
        width: '40%'
    },

    previousButtonText: {
        fontSize: 16,
        color: '#868199',
        margin: 15
    },

    hintButton: {
        backgroundColor: '#FCB040',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },

    hintText: {
        color: '#fff',
        marginLeft: 5
    }
});

export default styles;
