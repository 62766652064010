import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100vh',
        width: '100vw',
        margin: 'auto',
        backgroundColor: '#F3F2F5',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 36
    },

    dashboardButtonStyleWeb: {
        backgroundColor: '#fff',
        borderColor: 'black',
        padding: 26,
        borderRadius: 12,
        marginBottom: 20,
        width: '75%'
    },

    dashboardButtonText: {
        fontSize: Dimensions.get('window').height / 40,
        fontWeight: 'bold',
        color: '#0D0334',
        paddingLeft: 10
    },

    dashboardButtonView: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    hintText: {
        fontWeight: 'bold',
        color: '#0D0334',
        fontSize: Dimensions.get('window').height / 40,
        paddingTop: 15,
        paddingBottom: 15
    },

    videoButtonStyle: {
        height: 225,
        width: '35%',
        borderRadius: 12,
        marginHorizontal: 17.5,
        marginBottom: 25,
        justifyContent: 'center',
        alignItems: 'center'
    },

    hazardRow: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },

    thumbnail: {
        height: '100%',
        width: '100%',
        borderRadius: 10,
        resizeMode: 'stretch'
    },

    groupTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#0D0334',
        marginBottom: 20
    },

    scoreView: {
        height: 'auto'
    },

    playIcon: {
        position: 'absolute',
        alignSelf: 'center'
    }
});

export default styles;
