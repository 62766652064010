import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    primaryButton: {
        backgroundColor: '#8CC63F',
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },

    primaryButtonText: {
        fontSize: 18,
        color: '#F6F9FC',
        margin: 15
    }
});

export default styles;
