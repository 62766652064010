import { StyleSheet, Platform, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    headerX: {
        color: 'white',
        fontWeight: 'bold'
    },

    feedbackView: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        padding: 36,
        paddingTop: Platform.OS === 'ios' ? 50 : 36
    },

    secondaryButton: {
        marginTop: 20,
        width: '100%',
        padding: 10,
        borderRadius: 20
    },

    secondaryButtonText: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#0D0334',
        textAlign: 'center'
    },

    questionView: {
        backgroundColor: '#fff',
        padding: 12,
        marginBottom: 10,
        borderRadius: 10,
        width: '100%',
        alignItems: 'center'
    },

    questionButton: {
        backgroundColor: '#fff',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    questionTitle: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#0D0334',
        width: '90%',
        fontWeight: '800',
        marginRight: 10
    },

    answer: {
        color: '#868199',
        fontSize: Dimensions.get('window').height / 60,
        alignSelf: 'flex-start',
        padding: 8
    },

    correctAnswer: {
        borderWidth: 2,
        borderColor: '#8CC63F',
        borderRadius: 5,
        width: '100%'
    },

    selectedAnswer: {
        borderWidth: 2,
        borderColor: '#F0040F',
        borderRadius: 5,
        width: '100%'
    },

    toggleButton: {
        backgroundColor: '#fff',
        paddingVertical: 10,
        paddingHorizontal: 12,
        marginRight: 20,
        borderRadius: 15
    },

    toggleText: {
        color: '#868199',
        fontSize: Dimensions.get('window').height / 60
    },

    selectedButton: {
        backgroundColor: '#0D0334'
    },

    selectedText: {
        color: '#fff'
    },

    image: {
        width: 100,
        height: 100,
        resizeMode: 'contain'
    },

    testComplete: {
        color: '#0D0334',
        alignSelf: 'center',
        fontSize: Dimensions.get('window').height / 60,
        fontWeight: 'bold',
        marginBottom: 25
    },

    imageHeaderStyle: {
        resizeMode: 'contain',
        marginBottom: 20
    },

    scoreStyle: {
        color: '#0D0334',
        fontSize: Dimensions.get('window').height / 60,
        fontWeight: 'bold'
    },

    scoreFeedbackStylePass: {
        color: '#fcb040',
        fontSize: Dimensions.get('window').height / 60,
        paddingBottom: 50
    },

    scoreFeedbackStyleFail: {
        color: '#fc4040',
        fontSize: Dimensions.get('window').height / 60,
        paddingBottom: 50
    }
});

export default styles;
