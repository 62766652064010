import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    selectionButton: {
        backgroundColor: '#F3F2F5',
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginBottom: 20
    },

    selectionButtonText: {
        fontSize: 15,
        color: '#868199',
        padding: 15,
        alignSelf: 'center',
        textAlign: 'center'
    },

    selectionButtonSubText: {
        fontSize: 10,
        color: '#868199',
        paddingBottom: 15,
        alignSelf: 'center',
        textAlign: 'center'
    },

    selectionButtonTextWithHint: {
        marginTop: -10
    },

    buttonSelected: {
        backgroundColor: '#0D0334'
    },

    buttonTextSelected: {
        color: 'white'
    },

    hintView: {
        borderRadius: 10,
        marginTop: -10,
        backgroundColor: '#8CC63F'
    },

    hintText: {
        fontSize: 9,
        color: 'white',
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: 'white'
    }
});

export default styles;
