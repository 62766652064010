import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    headingText: {
        paddingBottom: 15,
        fontWeight: 'bold',
        fontSize: Dimensions.get('window').height / 60,
        alignSelf: 'flex-start',
        color: '#0D0334'
    },

    secondaryButton: {
        marginTop: 20,
        width: '100%',
        padding: 10,
        borderRadius: 20
    },

    secondaryButtonText: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#0D0334',
        textAlign: 'center'
    },

    categoryName: {
        color: '#0D0334',
        fontSize: Dimensions.get('window').height / 55,
        textAlign: 'center',
        fontWeight: 'bold',
        marginVertical: 10
    },

    tipView: {
        width: '100%',
        alignItems: 'flex-start',
        marginLeft: 88,
        marginVertical: 8,
        flexDirection: 'row'
    },

    tipViewWeb: {
        alignSelf: 'center',
        marginVertical: 8,
        flexDirection: 'row'
    },

    tipText: {
        color: '#868199',
        fontSize: Dimensions.get('window').height / 70,
        marginLeft: 10
    }
});

export default styles;
