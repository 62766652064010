import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    logoImage: {
        width: 75,
        height: 75,
        marginTop: -100,
        marginLeft: -100,
        marginBottom: 20,
        resizeMode: 'contain',
        alignSelf: 'center'
    },

    topMenu: {
        textAlign: 'left',
        color: '#0D0334',
        fontSize: 14,
        fontWeight: 'bold',
        padding: 15,
        paddingVertical: 12
    }
});

export default styles;
