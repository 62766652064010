import React, { useEffect, useState, useRef } from 'react';
import { Text, View, Animated, Modal, TouchableOpacity, ScrollView, Image, Linking } from 'react-native';
import styles from './styles';

import PrimaryButton from '../Buttons/PrimaryButton';
import PassProtectionCriteria from '../PassProtectionCriteria/index.web';

import { useAuth } from '../../Context/AuthContext';

import { PASS_PROTECTION_URL, PASS_PROTECT_TERMS_URL } from '../../API/Environment.web';

const ModalPoup = ({ visible, children }) => {
    const [showModal, setShowModal] = useState(visible);
    const scaleValue = useRef(new Animated.Value(0)).current;
    useEffect(() => {
        toggleModal();
    }, [visible]);
    const toggleModal = () => {
        if (visible) {
            setShowModal(true);
            Animated.spring(scaleValue, {
                toValue: 1,
                duration: 300,
                useNativeDriver: true
            }).start();
        } else {
            setTimeout(() => setShowModal(false), 200);
            Animated.timing(scaleValue, {
                toValue: 0,
                duration: 300,
                useNativeDriver: true
            }).start();
        }
    };

    return (
        <Modal transparent visible={showModal}>
            <View style={styles.modalBackGround}>
                <Animated.View style={[{ transform: [{ scale: scaleValue }] }]}>
                    <View style={[styles.modalContainer]}>
                        <ScrollView bounces={false}>{children}</ScrollView>
                    </View>
                </Animated.View>
            </View>
            <View />
        </Modal>
    );
};

const PassProtectionPopup = ({ status, visible, onClose }) => {
    const auth = useAuth();

    return (
        <ModalPoup visible={visible}>
            <View style={styles.header}>
                <Text style={styles.title}>About Pass Protect</Text>
                <TouchableOpacity onPress={onClose}>
                    <Image
                        style={{ width: 15, height: 15, resizeMode: 'contain' }}
                        source={require('../../../assets/images/Header/SVG/icon-close-black.svg')}
                    />
                </TouchableOpacity>
            </View>
            <View style={styles.border} />
            <Text style={styles.title}>Up to 2 FREE theory test re-sits if you fail.</Text>
            <Text style={styles.text}>
                Get up to two free theory test re-sits worth £46 if you fail your theory test, plus
                priority support from our expert team.
            </Text>
            <Text style={styles.subtitle}>How does it work?</Text>
            <Text style={styles.text}>
                Simply purchase Pass Protect ahead of your theory test date and complete all 4 of
                the below requirements to be covered by Pass Protect, once met if you fail Theory
                Test Pass will cover the cost of your next theory test exam
            </Text>
            <Text style={styles.subtitle}>What criteria do I need to meet?</Text>
            <Text style={[styles.text, { marginBottom: 20 }]}>
                Your progress so far to unlocking Pass Protect is shown below:
            </Text>
            <PassProtectionCriteria
                status={status}
                style={styles.criteriaContainerWeb}
                textStyle={styles.criteriaText}
            />
            <PrimaryButton
                text={'BUY PASS PROTECTION FOR £12.99'}
                style={styles.button}
                textStyle={styles.buttonText}
                onPress={() =>
                    (window.location.href = `${PASS_PROTECTION_URL}${auth.user.urlParams}`)
                }
            />
            <TouchableOpacity onPress={() => Linking.openURL(PASS_PROTECT_TERMS_URL)}>
                <Text style={styles.tcs}>
                    By purchasing Pass Protection you confirm you have read to and agree to our{' '}
                    <Text style={styles.tcsUnderline}>Pass Protect Terms and Conditions</Text>
                </Text>
            </TouchableOpacity>
        </ModalPoup>
    );
};

export default PassProtectionPopup;
