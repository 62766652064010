import React, { useEffect, useState } from 'react';
import { View, Text, Image, Dimensions } from 'react-native';
import { Link } from 'react-router-dom';
import styles from './styles.web';

import LoadingState from '../../../Components/LoadingState';

import { useHistory } from 'react-router-dom';

import PrimaryButton from '../../../Components/Buttons/PrimaryButton';

const Tip = ({ text, icon }) => {
    return (
        <View style={styles.tipViewWeb}>
            {icon}
            <Text style={styles.tipText}>{text}</Text>
        </View>
    );
};

const HazardPerceptionMockTest = () => {
    const [loading, setLoading] = useState(false);

    const isHgv = localStorage.getItem('testtype') === '2';

    const history = useHistory();

    useEffect(() => {
        (async () => {
            setLoading(true);

            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <LoadingState />;
    } else {
        return (
            <View style={styles.container}>
                <Image
                    style={styles.hpImage}
                    source={require('../../../../assets/images/HazardPerception/PNG/hazard-perception-graphic.png')}
                />

                <Text style={styles.categoryName}>Mock Test</Text>

                <Tip
                    icon={
                        <Image
                            style={styles.miniImage}
                            source={require('../../../../assets/images/MockTest/SVG/icon-trophy.svg')}
                        />
                    }
                    text={isHgv ? 'Will take 20 minutes.' : 'Will take 15 minutes.'}
                />

                <Tip
                    icon={
                        <Image
                            style={styles.miniImage}
                            source={require('../../../../assets/images/PracticeZone/SVG/icon-question.svg')}
                        />
                    }
                    text={
                        isHgv
                            ? '20 videos with one hazard each.'
                            : '15 videos with one hazard each.'
                    }
                />

                <Tip
                    icon={
                        <Image
                            style={styles.miniImage}
                            source={require('../../../../assets/images/MockTest/SVG/icon-trophy.svg')}
                        />
                    }
                    text={'Results and feedback at the end.'}
                />

                <View style={{ marginBottom: 20 }} />

                <PrimaryButton
                    text="Start Test"
                    onPress={() => history.push('/hazard-perception-test')}
                    textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                    style={{ width: '75%' }}
                />

                <Link
                    to="/hazard-perception-dashboard"
                    style={{
                        marginTop: 20,
                        width: '100%',
                        padding: 10,
                        borderRadius: 20,
                        textAlign: 'center'
                    }}>
                    <Text style={styles.secondaryButtonText}> Cancel </Text>
                </Link>
            </View>
        );
    }
};

export default HazardPerceptionMockTest;
