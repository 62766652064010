import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import AuthProvider from './src/Context/AuthProvider';

import Parent from './src/Screens/Parent/index.web';
import ParentSidebar from './src/Screens/ParentSidebar/index.web';
import GuardedRoute from './src/Components/GuardedRoute/index.web';

import Login from './src/Screens/Login-Signup-Dashboard/Login/index.web';
import Password from './src/Screens/Login-Signup-Dashboard/Password/index.web';
import Dashboard from './src/Screens/Login-Signup-Dashboard/Dashboard/index.web';

import Profile from './src/Screens/Login-Signup-Dashboard/Profile/index.web';
import EditProfile from './src/Screens/Login-Signup-Dashboard/EditProfile/index.web';

import TrainingPlan from './src/Screens/TrainingPlan/index.web';

import QuickTestDashboard from './src/Screens/QuickTest/QuickTestDashboard/index.web';
import QuickTest from './src/Screens/QuickTest/Test/index.web';

import ProgressPage from './src/Screens/ProgressPage/Dashboard/index.web';
import ProgressPageMockTest from './src/Screens/ProgressPage/MockTestResults/index.web';
import ProgressPageHazardTest from './src/Screens/ProgressPage/HazardTestResults/index.web';
import ProgressPageMockIndividualResults from './src/Screens/ProgressPage/MockIndividualResults/index.web';
import ProgressPageHazardIndividualResults from './src/Screens/ProgressPage/HazardIndividualResults/index.web';

import PracticeZoneDashboard from './src/Screens/PracticeZone/Dashboard/index.web';
import PracticeZoneTest from './src/Screens/PracticeZone/Test/index.web';

import MockTest from './src/Screens/MockTest/Test/index.web';

import RoadsignDashboard from './src/Screens/RoadSigns/Dashboard/index.web';
import RoadSigns from './src/Screens/RoadSigns/Test/index.web';

import HazardPerceptionDashboard from './src/Screens/HazardPerception/Dashboard/index.web';
import HazardPerceptionPractice from './src/Screens/HazardPerception/PracticeVideos/index.web';
import HazardPerceptionMockTest from './src/Screens/HazardPerception/MockTest/index.web';
import HazardPerceptionTest from './src/Screens/HazardPerception/Test/index.web';
import HazardPerceptionTutorial from './src/Components/QuestionTemplates/HazardPerceptionTutorial/index.web';

import LearnHowToDashboard from './src/Screens/LearnHowTo/Dashboard/index.web';
import LearnHowTo from './src/Screens/LearnHowTo/AdditionalVideos/index.web';
import LearnHowToVideoPlayer from './src/Screens/LearnHowTo/LearnHowToVideoPlayer/index.web';

import PassProtect from './src/Screens/PassProtect/index.web';

import ScrollTop from './ScrollTop';

import { useScreenSize } from './src/Utilities/utils';

export default function App() {
    const screenSize = useScreenSize();

    return (
        <AuthProvider>
            <Router basename="/app/">
                <ScrollTop>
                    <Route exact path="/">
                        {' '}
                        <Parent screen={Login} name={'Login'} showButtons={false} />{' '}
                    </Route>
                    <Route path="/login">
                        {' '}
                        <Parent screen={Login} name={'Login'} showButtons={false} />{' '}
                    </Route>
                    <Route path="/password">
                        {' '}
                        <Parent screen={Password} name={'Password'} showButtons={false} />{' '}
                    </Route>
                    <Route path="/register">
                        {' '}
                        <Parent screen={Login} name={'Login'} showButtons={false} />{' '}
                    </Route>
                    <GuardedRoute path="/dashboard">
                        {screenSize.large ? (
                            <Parent screen={Dashboard} name={'Dashboard'} />
                        ) : (
                            <ParentSidebar screen={Dashboard} name={'Dashboard'} />
                        )}
                    </GuardedRoute>
                    <GuardedRoute path="/profile">
                        {screenSize.large ? (
                            <Parent screen={Profile} name={'Profile'} />
                        ) : (
                            <ParentSidebar screen={Profile} name={'Profile'} />
                        )}
                    </GuardedRoute>
                    <GuardedRoute path="/edit-profile">
                        {screenSize.large ? (
                            <Parent screen={EditProfile} name={'Edit Profile'} />
                        ) : (
                            <ParentSidebar screen={EditProfile} name={'Edit Profile'} />
                        )}{' '}
                    </GuardedRoute>

                    <GuardedRoute path="/training">
                        {' '}
                        {screenSize.large ? (
                            <Parent screen={TrainingPlan} name={'Training Plan'} />
                        ) : (
                            <ParentSidebar screen={TrainingPlan} name={'Training Plan'} />
                        )}
                    </GuardedRoute>

                    <GuardedRoute path="/quick-test-dashboard">
                        {screenSize.large ? (
                            <Parent screen={QuickTestDashboard} name={'Quick Test Dashboard'} />
                        ) : (
                            <ParentSidebar
                                screen={QuickTestDashboard}
                                name={'Quick Test Dashboard'}
                            />
                        )}{' '}
                    </GuardedRoute>
                    <GuardedRoute path="/quick-test">
                        {screenSize.large ? (
                            <Parent screen={QuickTest} name={'Quick Test'} />
                        ) : (
                            <ParentSidebar screen={QuickTest} name={'Quick Test'} />
                        )}{' '}
                    </GuardedRoute>

                    <GuardedRoute exact path="/progress">
                        {screenSize.large ? (
                            <Parent screen={ProgressPage} name={'Progress Zone Dashboard'} />
                        ) : (
                            <ParentSidebar screen={ProgressPage} name={'Progress Zone Dashboard'} />
                        )}
                    </GuardedRoute>
                    <GuardedRoute exact path="/progress/mocktest">
                        {screenSize.large ? (
                            <Parent
                                screen={ProgressPageMockTest}
                                name={'Progress Zone | Mock Test'}
                            />
                        ) : (
                            <ParentSidebar
                                screen={ProgressPageMockTest}
                                name={'Progress Zone | Mock Test'}
                            />
                        )}
                    </GuardedRoute>
                    <GuardedRoute exact path="/progress/hazard">
                        {screenSize.large ? (
                            <Parent
                                screen={ProgressPageHazardTest}
                                name={'Progress Zone | Hazard Test'}
                            />
                        ) : (
                            <ParentSidebar
                                screen={ProgressPageHazardTest}
                                name={'Progress Zone | Hazard Test'}
                            />
                        )}{' '}
                    </GuardedRoute>
                    <GuardedRoute exact path="/progress/individual-mock">
                        {screenSize.large ? (
                            <Parent
                                screen={ProgressPageMockIndividualResults}
                                name={'Progress Zone | Mock Test | Individual'}
                            />
                        ) : (
                            <ParentSidebar
                                screen={ProgressPageMockIndividualResults}
                                name={'Progress Zone | Mock Test | Individual'}
                            />
                        )}{' '}
                    </GuardedRoute>
                    <GuardedRoute exact path="/progress/individual-hazard">
                        {screenSize.large ? (
                            <Parent
                                screen={ProgressPageHazardIndividualResults}
                                name={'Progress Zone | Hazard Test | Individual'}
                            />
                        ) : (
                            <ParentSidebar
                                screen={ProgressPageHazardIndividualResults}
                                name={'Progress Zone | Hazard Test | Individual'}
                            />
                        )}{' '}
                    </GuardedRoute>

                    <GuardedRoute path="/practice-zone">
                        {screenSize.large ? (
                            <Parent
                                screen={PracticeZoneDashboard}
                                name={'Practice Zone Dashboard'}
                            />
                        ) : (
                            <ParentSidebar
                                screen={PracticeZoneDashboard}
                                name={'Practice Zone Dashboard'}
                            />
                        )}{' '}
                    </GuardedRoute>
                    <GuardedRoute path="/practice-zone-test">
                        {screenSize.large ? (
                            <Parent screen={PracticeZoneTest} name={'Practice Zone Test'} />
                        ) : (
                            <ParentSidebar screen={PracticeZoneTest} name={'Practice Zone Test'} />
                        )}{' '}
                    </GuardedRoute>

                    <GuardedRoute path="/mock-test">
                        {screenSize.large ? (
                            <Parent screen={MockTest} name={'Mock Test'} />
                        ) : (
                            <ParentSidebar screen={MockTest} name={'Mock Test'} />
                        )}
                    </GuardedRoute>

                    <GuardedRoute path="/roadsign-dashboard">
                        {screenSize.large ? (
                            <Parent screen={RoadsignDashboard} name={'Roadsign Dashboard'} />
                        ) : (
                            <ParentSidebar screen={RoadsignDashboard} name={'Roadsign Dashboard'} />
                        )}
                    </GuardedRoute>
                    <GuardedRoute path="/roadsigns">
                        {screenSize.large ? (
                            <Parent screen={RoadSigns} name={'Road Signs'} />
                        ) : (
                            <ParentSidebar screen={RoadSigns} name={'Road Signs'} />
                        )}{' '}
                    </GuardedRoute>

                    <GuardedRoute path="/hazard-perception-dashboard">
                        {screenSize.large ? (
                            <Parent
                                screen={HazardPerceptionDashboard}
                                name={'Hazard Perception Dashboard'}
                            />
                        ) : (
                            <ParentSidebar
                                screen={HazardPerceptionDashboard}
                                name={'Hazard Perception Dashboard'}
                            />
                        )}
                    </GuardedRoute>
                    <GuardedRoute path="/hazard-perception-practice">
                        {screenSize.large ? (
                            <Parent
                                screen={HazardPerceptionPractice}
                                name={'Hazard Perception | Practice Test'}
                            />
                        ) : (
                            <ParentSidebar
                                screen={HazardPerceptionPractice}
                                name={'Hazard Perception | Practice Test'}
                            />
                        )}
                    </GuardedRoute>
                    <GuardedRoute path="/hazard-perception-mocktest">
                        {screenSize.large ? (
                            <Parent
                                screen={HazardPerceptionMockTest}
                                name={'Hazard Perception | Mock Test'}
                            />
                        ) : (
                            <ParentSidebar
                                screen={HazardPerceptionMockTest}
                                name={'Hazard Perception | Mock Test'}
                            />
                        )}
                    </GuardedRoute>
                    <GuardedRoute path="/hazard-perception-test">
                        {screenSize.large ? (
                            <Parent
                                screen={HazardPerceptionTest}
                                name={'Hazard Perception | Test'}
                            />
                        ) : (
                            <ParentSidebar
                                screen={HazardPerceptionTest}
                                name={'Hazard Perception | Test'}
                            />
                        )}{' '}
                    </GuardedRoute>
                    <GuardedRoute path="/hazard-perception-tutorial">
                        {screenSize.large ? (
                            <Parent
                                screen={HazardPerceptionTutorial}
                                name={'Hazard Perception | Tutorial'}
                            />
                        ) : (
                            <ParentSidebar
                                screen={HazardPerceptionTutorial}
                                name={'Hazard Perception | Tutorial'}
                            />
                        )}
                    </GuardedRoute>

                    <GuardedRoute path="/learn-how-to-dashboard">
                        {screenSize.large ? (
                            <Parent
                                screen={LearnHowToDashboard}
                                name={'Driving Lessons | Dashboard'}
                            />
                        ) : (
                            <ParentSidebar
                                screen={LearnHowToDashboard}
                                name={'Driving Lessons | Dashboard'}
                            />
                        )}
                    </GuardedRoute>
                    <GuardedRoute path="/learn-how-to-video">
                        {screenSize.large ? (
                            <Parent
                                screen={LearnHowToVideoPlayer}
                                name={'Driving Lessons | Video'}
                            />
                        ) : (
                            <ParentSidebar
                                screen={LearnHowToVideoPlayer}
                                name={'Driving Lessons | Video'}
                            />
                        )}
                    </GuardedRoute>
                    <GuardedRoute path="/learn-how-to">
                        {screenSize.large ? (
                            <Parent screen={LearnHowTo} name={'Driving Lessons | Video Area'} />
                        ) : (
                            <ParentSidebar
                                screen={LearnHowTo}
                                name={'Driving Lessons | Video Area'}
                            />
                        )}
                    </GuardedRoute>

                    <GuardedRoute path="/pass-protect">
                        {screenSize.large ? (
                            <Parent screen={PassProtect} name={'Pass Protect'} />
                        ) : (
                            <ParentSidebar screen={PassProtect} name={'Pass Protect'} />
                        )}
                    </GuardedRoute>
                </ScrollTop>
            </Router>
        </AuthProvider>
    );
}
