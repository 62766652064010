import React from 'react';
import { Text, TouchableHighlight, View, Dimensions } from 'react-native';
import styles from './styles';

const Banner = ({ message, style }) => {
    return <Text style={[styles.banner, style]}>{message}</Text>;
};

const CategoryBoxButton = ({ text, onPress, icon, buttonStyle, isNew = false }) => {
    return (
        <View style={[styles.buttonWebView, buttonStyle]}>
            <TouchableHighlight
                onPress={onPress}
                underlayColor="#cac7c6"
                style={styles.buttonStyle}>
                <View style={styles.buttonInnerView}>
                    {icon}

                    <Text
                        style={[
                            styles.buttonText,
                            { fontSize: Dimensions.get('window').height / 75 }
                        ]}
                        numberOfLines={1}>
                        {text}
                    </Text>
                </View>
            </TouchableHighlight>
            {isNew && <Banner message="New" />}
        </View>
    );
};

export default CategoryBoxButton;
