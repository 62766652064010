import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    feedbackSectionButton: {
        width: '100%',
        backgroundColor: 'white',
        marginBottom: 10,
        padding: 15,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: 'black'
    },

    feedbackSectionView: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    feedbackSectionText: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#0D0334',
        fontWeight: 'bold'
    },

    extendedView: {
        backgroundColor: '#0D0334',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        marginBottom: 0
    },

    detailedFeedbackView: {
        width: '100%',
        backgroundColor: 'white',
        marginBottom: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderWidth: 2,
        borderColor: 'black'
    },

    questionTitle: {
        fontSize: Dimensions.get('window').height / 60,
        fontWeight: 'bold',
        padding: 18,
        color: '#0D0334',
        paddingBottom: 0,
        width: '92%'
    },

    border: {
        borderBottomWidth: 1,
        borderColor: '#F3F2F5',
        marginRight: 10,
        marginLeft: 10,
        flex: 1,
        marginBottom: 0
    },

    extendedExpandedAnswer: {
        fontSize: Dimensions.get('window').height / 70,
        padding: 10,
        width: '92%'
    },

    correctAnswer: {
        borderWidth: 2,
        borderColor: '#8CC63F',
        marginRight: 20,
        borderRadius: 5,
        marginBottom: 20
    },

    selectedAnswer: {
        borderWidth: 2,
        borderColor: '#F0040F',
        marginRight: 20,
        borderRadius: 5,
        marginBottom: 20
    },

    questionChevronView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    image: {
        width: 100,
        height: 100,
        resizeMode: 'contain'
    },

    questionTitleImage: {
        width: '100%',
        height: 100,
        resizeMode: 'contain',
        marginBottom: 10
    },

    answer: {
        color: '#868199',
        fontSize: Dimensions.get('window').height / 70,
        alignSelf: 'flex-start',
        padding: 8
    }
});

export default styles;
