import React, { useEffect, useState, useRef } from 'react';
import { Text, View, Animated, Modal, Image, Dimensions } from 'react-native';
import { useHistory } from 'react-router-dom';
import styles from './styles.web';

import PrimaryButton from '../Buttons/PrimaryButton';

const ModalPoup = ({ visible, children }) => {
    const [showModal, setShowModal] = useState(visible);
    const scaleValue = useRef(new Animated.Value(0)).current;
    useEffect(() => {
        toggleModal();
    }, [visible]);
    const toggleModal = () => {
        if (visible) {
            setShowModal(true);
            Animated.spring(scaleValue, {
                toValue: 1,
                duration: 300,
                useNativeDriver: true
            }).start();
        } else {
            setTimeout(() => setShowModal(false), 200);
            Animated.timing(scaleValue, {
                toValue: 0,
                duration: 300,
                useNativeDriver: true
            }).start();
        }
    };

    return (
        <Modal transparent visible={showModal}>
            <View style={styles.modalBackGround}>
                <Animated.View style={[{ transform: [{ scale: scaleValue }] }]}>
                    <View style={[styles.hintInfoContainer]}>
                        <Text
                            style={{
                                color: 'white',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: Dimensions.get('window').height / 60
                            }}>
                            Hints are not available in the real test
                        </Text>
                    </View>
                    <View style={[styles.modalContainer]}>{children}</View>
                </Animated.View>
            </View>
            <View />
        </Modal>
    );
};

const HintPopup = ({
    visible,
    setVisible,
    image,
    title,
    secondaryText,
    buttonText,
    buttonLink,
    subText,
    skip
}) => {
    const history = useHistory();

    return (
        <ModalPoup visible={visible}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Image
                    style={{ resizeMode: 'contain', height: 25, width: 25 }}
                    source={require('../../../assets/images/PracticeZone/PNG/icon-hint-yellow.png')}
                />
                <Text style={styles.title}>Here's a hint</Text>
            </View>

            <Text style={styles.secondaryText}>{secondaryText}</Text>

            <PrimaryButton
                text={`Got it`}
                onPress={() => setVisible(false)}
                textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
            />
        </ModalPoup>
    );
};

export default HintPopup;
