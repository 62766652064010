import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    imageButton: {
        width: 140,
        height: 140,
        borderRadius: 10,
        margin: 10,
        borderWidth: 3,
        borderColor: '#0D03341A',
        shadowColor: 'green',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    selected: {
        borderColor: '#0D0334'
    },

    image: {
        width: 100,
        height: 100,
        resizeMode: 'contain'
    }
});

export default styles;
