import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, TouchableHighlight, Image, Dimensions } from 'react-native';
import styles from './styles.web';

import PrimaryLink from '../../Buttons/PrimaryLink';
import PrimaryButton from '../../Buttons/PrimaryButton';
import Dial from '../../Dial';
import { Link } from 'react-router-dom';
import { saveTest } from '../../../API/RealmComms.web';
import { GET_ONLINE_IMAGE_PATH } from '../../../API/Environment.web';

const ResultsScreen = ({
    questionFeedbackBreakdown,
    userScore,
    totalQuestions,
    questionsTracker,
    currentUser
}) => {
    const [seeResults, setSeeResults] = useState(false);
    const [extended, setExtended] = useState();
    const [sort, setSort] = useState('All');

    const scorePercentage = userScore === 0 ? 0 : ((userScore / totalQuestions) * 100).toFixed(0);

    useEffect(() => {
        (async () => {
            await saveTest(
                userScore,
                totalQuestions,
                questionsTracker,
                questionFeedbackBreakdown,
                2
            );
        })();
    }, []);

    const QuestionBreakdown = ({ question, index }) => {
        const answers = JSON.parse(question[0].answers);
        const selectedAnswerText = question[1].text;
        const selectedAnswerImage = question[1].image;
        return (
            <View style={styles.questionView}>
                <TouchableHighlight
                    style={styles.questionButton}
                    onPress={() => {
                        extended !== index ? setExtended(index) : setExtended(null);
                    }}
                    underlayColor="transparent">
                    <>
                        <Text style={styles.questionTitle} numberOfLines={10}>
                            {question[0].question}
                        </Text>

                        {extended === index ? (
                            <Image
                                style={{
                                    marginRight: 10,
                                    marginTop: 18,
                                    resizeMode: 'contain',
                                    height: 15,
                                    width: 15
                                }}
                                onPress={() => setExtendedExpanded(null)}
                                source={require('../../../../assets/images/TrainingPlan/PNG/icon-up-arrow.png')}
                            />
                        ) : (
                            <Image
                                style={{
                                    marginRight: 10,
                                    marginTop: 18,
                                    resizeMode: 'contain',
                                    height: 15,
                                    width: 15
                                }}
                                source={require('../../../../assets/images/TrainingPlan/PNG/icon-down-arrow.png')}
                            />
                        )}
                    </>
                </TouchableHighlight>
                {extended === index &&
                    Object.keys(answers).map((answer, index) => {
                        return (
                            <>
                                {answers[answer].correct === '1' ? (
                                    <View>
                                        {/* <Image style={{left: -65, top: 9, resizeMode: "contain", height: "3vh", width: "3vw"}} source={require("../../../../assets/images/TrainingPlan/PNG/icon-green-tick.png")} /> */}
                                    </View>
                                ) : answers[answer].text === selectedAnswerText ? (
                                    <View>
                                        {/* <Image style={{left: -65, top: 9, resizeMode: "contain", height: "3vh", width: "3vw"}} source={require("../../../../assets/images/TrainingPlan/PNG/icon-red-cross.png")} /> */}
                                    </View>
                                ) : null}
                                <View
                                    style={[
                                        styles.answer,
                                        answers[answer].text === selectedAnswerText &&
                                            answers[answer].image === selectedAnswerImage &&
                                            styles.selectedAnswer,
                                        answers[answer].correct === '1' && styles.correctAnswer
                                    ]}>
                                    {answers[answer].image ? (
                                        <Image
                                            style={styles.image}
                                            source={{
                                                uri: `${GET_ONLINE_IMAGE_PATH}${answers[answer].image}`
                                            }}
                                        />
                                    ) : (
                                        <Text style={styles.answer}>{answers[answer].text}</Text>
                                    )}
                                    {answers[answer].correct === '1' &&
                                        question[0].hint.length > 0 && (
                                            <>
                                                <Text style={styles.answer}>
                                                    <Text
                                                        style={{
                                                            color: '#0D0334',
                                                            fontWeight: 'bold'
                                                        }}>
                                                        Explanation
                                                    </Text>
                                                    {'\n'}
                                                    {question[0].hint}
                                                </Text>
                                            </>
                                        )}
                                </View>
                            </>
                        );
                    })}
            </View>
        );
    };

    const ToggleButton = ({ text, onPress, selected }) => {
        return (
            <TouchableHighlight
                onPress={onPress}
                underlayColor="#cac7c6"
                style={[styles.toggleButton, selected && styles.selectedButton]}>
                <Text style={[styles.toggleText, selected && styles.selectedText]}> {text} </Text>
            </TouchableHighlight>
        );
    };

    return (
        <View style={{ flex: 1, maxWidth: '75%', margin: 'auto' }}>
            <ScrollView contentContainerStyle={{ flexGrow: 1, backgroundColor: 'white' }}>
                {!seeResults ? (
                    <>
                        <Dial
                            scorePercentage={scorePercentage}
                            noPercentage={true}
                            noNote={true}
                            prompt={false}
                        />

                        <Text style={styles.scoreResult}>
                            {userScore}/{totalQuestions}
                        </Text>

                        <Text
                            style={[
                                styles.scorePassFail,
                                scorePercentage > 50 ? { color: '#8CC63F' } : { color: '#F0040F' }
                            ]}>
                            {scorePercentage > 50 ? 'Pass' : 'Fail'}
                        </Text>

                        <Text style={styles.dialTextDetails}>
                            We've updated your dashboard with these results to help you get started!
                        </Text>

                        <View style={styles.feedbackView}>
                            <View style={{ width: '100%' }}>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        marginBottom: 10,
                                        fontSize: Dimensions.get('window').height / 60
                                    }}>
                                    Do you want to see the answers?
                                </Text>

                                <PrimaryButton
                                    text="Yes, see answers"
                                    onPress={() => setSeeResults(true)}
                                    textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                                />

                                <Link
                                    to="/dashboard"
                                    style={{
                                        marginTop: 20,
                                        width: '100%',
                                        padding: 10,
                                        borderRadius: 20,
                                        textAlign: 'center'
                                    }}>
                                    <Text style={styles.secondaryButtonText}> Cancel </Text>
                                </Link>
                            </View>
                        </View>
                    </>
                ) : (
                    <>
                        <View style={styles.feedbackViewWeb}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignSelf: 'flex-start',
                                    paddingBottom: 20
                                }}>
                                <ToggleButton
                                    text={'All'}
                                    onPress={() => {
                                        setSort('All'), setExtended();
                                    }}
                                    selected={sort === 'All'}
                                />
                                <ToggleButton
                                    text={'✓ Correct'}
                                    onPress={() => {
                                        setSort('Correct'), setExtended();
                                    }}
                                    selected={sort === 'Correct'}
                                />
                                <ToggleButton
                                    text={'X Incorrect'}
                                    onPress={() => {
                                        setSort('Incorrect'), setExtended();
                                    }}
                                    selected={sort === 'Incorrect'}
                                />
                            </View>
                            {questionFeedbackBreakdown.map((question, index) => {
                                return sort === 'All' ? (
                                    <QuestionBreakdown question={question} index={index} />
                                ) : sort === 'Correct' && question[1].correct === '1' ? (
                                    <QuestionBreakdown question={question} index={index} />
                                ) : (
                                    sort === 'Incorrect' &&
                                    question[1].correct === '0' && (
                                        <QuestionBreakdown question={question} index={index} />
                                    )
                                );
                            })}

                            <PrimaryLink
                                text={'Back to Practice Zone'}
                                link={'/practice-zone'}
                                customStyle={{ bottom: 0 }}
                                textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                            />
                        </View>
                    </>
                )}
            </ScrollView>
        </View>
    );
};

export default ResultsScreen;
