import React, { useEffect, useState } from 'react';

import { fetchQuestions } from '../../API/Joomla.web';
import { fetchCategories } from '../../API/Joomla.web';

import Introduction from '../../Components/TrainingPlan/Introduction/index.web';
import QuestionParent from '../../Components/TrainingPlan/Test/QuestionParent/index.web';
import Results from '../../Components/ResultTemplates/ResultsPage/index.web';
import LoadingState from '../../Components/LoadingState';

const TrainingPlan = () => {
    const [trainingPlanStage, setTrainingPlanStage] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [userScore, setUserScore] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [questionNumber, setQuestionNumber] = useState(56);
    const [categories, setCategories] = useState(null);
    const [questionFeedbackBreakdown, setQuestionsFeedbackBreakdown] = useState({});
    const [questionsTracker, setQuestionsTracker] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const res = await fetchQuestions();
            const categories = await fetchCategories();
            var questions = [];
            for (var i = 1; i < questionNumber + 1; i++) {
                questions.push(res[Math.floor(Math.random() * res.length)]);
            }
            setQuestions(questions);
            setCategories(categories);
            setLoading(false);
        })();
    }, []);

    if (trainingPlanStage == 1) {
        return (
            <Introduction
                setTrainingPlanStage={setTrainingPlanStage}
                setQuestionNumber={setQuestionNumber}
                questionNumber={questionNumber}
            />
        );
    } else if (trainingPlanStage == 2) {
        return (
            <QuestionParent
                setTrainingPlanStage={setTrainingPlanStage}
                totalQuestions={questionNumber}
                questions={questions}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                userScore={userScore}
                setUserScore={setUserScore}
                questionFeedbackBreakdown={questionFeedbackBreakdown}
                setQuestionsFeedbackBreakdown={setQuestionsFeedbackBreakdown}
                questionsTracker={questionsTracker}
                setQuestionsTracker={setQuestionsTracker}
            />
        );
    } else if (trainingPlanStage == 3) {
        return (
            <Results
                userScore={userScore}
                totalQuestions={questionNumber}
                questionFeedbackBreakdown={questionFeedbackBreakdown}
                categories={categories}
                questionsTracker={questionsTracker}
            />
        );
    }

    return <LoadingState />;
};

export default TrainingPlan;
