import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    buttonView: {
        width: '100%',
        marginBottom: 15,
        flexDirection: 'row'
    },

    button: {
        backgroundColor: '#F3F2F5',
        borderRadius: 50,
        marginRight: 10,
        alignItems: 'center',
        width: '90%',
        height: 'auto',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    buttonWeb: {
        backgroundColor: '#F3F2F5',
        borderRadius: 50,
        marginRight: 10,
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    buttonText: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#868199',
        margin: 15
    },

    buttonTts: {
        marginTop: 5
    },

    testButtonTts: {
        resizeMode: 'contain',
        width: 50,
        height: 50
    },

    imageView: {
        width: 300,
        height: 200,
        marginBottom: 20,
        alignSelf: 'center',
        borderRadius: 10
    },

    selectedButton: {
        backgroundColor: '#0D0334'
    },

    selectedText: {
        color: 'white'
    },

    image: {
        width: '100%',
        height: '100%',
        resizeMode: 'contain'
    }
});

export default styles;
