import React from 'react';
import ModalPopup from '../Popup/index.web';

const TrialPopup = ({ trialExceeded, setTrialExceeded, onPress }) => {
    return (
        <ModalPopup
            visible={trialExceeded}
            setVisible={setTrialExceeded}
            title={'Trial Exceeded'}
            secondaryText={'Please upgrade your plan to gain access to this section.'}
            buttonText={'Choose a plan'}
            onPress={onPress}
            skip
        />
    );
};

export default TrialPopup;
