import React, { useState } from 'react';
import { View } from 'react-native';
import styles from './styles';

import { formatISO9075 } from 'date-fns';

import HazardPerceptionQuestion from '../../QuestionTemplates/HazardPerceptionQuestion/index.web';

const QuestionParent = ({
    questions,
    setMockTestStage,
    questionFeedbackBreakdown,
    setQuestionFeedbackBreakdown,
    currentIndex,
    setCurrentIndex,
    testScore,
    setTestScore,
    questionsTracker,
    setQuestionsTracker
}) => {
    const [clickLogger, setClickLogger] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(questions.length);

    const saveQuestion = (currentQuestion, tooManyClicks) => {
        var scoreArray = [];
        if (!tooManyClicks) {
            clickLogger.forEach((time) => {
                Object.entries(JSON.parse(currentQuestion.clipwindow)).forEach(([key, value]) => {
                    if (time >= value.starttime && time <= value.endtime) {
                        scoreArray.push(value.score);
                    }
                });
            });

            var newScore;
            if (scoreArray.length > 0) {
                newScore = testScore + Math.max(...scoreArray);
            } else {
                newScore = testScore;
            }

            setTestScore(newScore);
        }

        const questionScore = scoreArray.length > 0 ? Math.max(...scoreArray) : 0;

        var questionDict = questionFeedbackBreakdown;
        questionDict[currentQuestion.id] = [currentQuestion, questionScore, clickLogger];
        setQuestionFeedbackBreakdown(questionDict);

        const dateTime = formatISO9075(new Date());
        const updateTracker = questionsTracker;

        updateTracker.push({
            userid: localStorage.getItem('userid'),
            hazardid: `${currentQuestion.id}`,
            score: `${String(questionScore)}`,
            correct: '1',
            dateadded: `${dateTime}`
        });
        setQuestionsTracker(updateTracker);

        if (currentIndex + 1 < totalQuestions) {
            setCurrentIndex(currentIndex + 1);
        } else {
            setMockTestStage(2);
        }
    };

    return (
        <View style={styles.container}>
            <HazardPerceptionQuestion
                currentQuestion={questions[currentIndex]}
                clickLogger={clickLogger}
                setClickLogger={setClickLogger}
                saveQuestion={(currentQuestion, tooManyClicks) =>
                    saveQuestion(currentQuestion, tooManyClicks)
                }
                totalQuestions={totalQuestions}
                currentIndex={currentIndex}
            />
        </View>
    );
};

export default QuestionParent;
