import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    modalBackGround: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    modalContainer: {
        backgroundColor: 'white',
        paddingHorizontal: 30,
        paddingVertical: 20,
        borderRadius: 10,
        elevation: 20,
        width: '90%',
        maxHeight: '100%',
        marginVertical: 10,
        alignSelf: 'center'
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        fontSize: 22,
        color: '#121148',
        fontWeight: 'bold'
    },
    xmark: {
        fontSize: 18,
        color: '#121148',
        fontWeight: 'bold'
    },
    border: {
        height: 0.5,
        backgroundColor: '#121148',
        marginHorizontal: -30,
        marginVertical: 20
    },
    subtitle: {
        fontSize: 16,
        color: '#121148',
        fontWeight: 'bold'
    },
    text: {
        lineHeight: 18,
        marginVertical: 10
    },
    button: {
        borderRadius: 5,
        marginTop: 20
    },
    criteriaContainer: {
        padding: 0,
        display: 'flex',
        flexShrink: 1
    },
    criteriaContainerWeb: {
        padding: 0,
        display: 'flex',
        justifyContent: 'left',
        flexShrink: 1
    },
    criteriaText: {
        color: ''
    },
    buttonText: {
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'center'
    },
    exitIcon: {
        padding: 5,
        borderRadius: 25
    },
    tcs: {
        color: '#121148',
        textAlign: 'center',
        marginTop: 20
    },
    tcsUnderline: {
        color: '#121148',
        textDecorationLine: 'underline',
        textAlign: 'center',
        marginTop: 20
    }
});

export default styles;
