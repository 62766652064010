export const API_BASE = `https://theorytestpass.com/api`;

export const GET_ONLINE_IMAGE_PATH = `https://theorytestpass.com/`;
export const IOS_STORE_URL = `https://apps.apple.com/us/app/theory-test-pass-2022/id1617066091`;
export const ANDROID_STORE_URL = `https://play.google.com/store/apps/details?id=com.theorytestpass.ttp`;
export const TERMS_URL = `https://theorytestpass.com/terms-and-conditions`;
export const PASS_PROTECT_TERMS_URL = `https://theorytestpass.com/pass-protection-terms-conditions`;

export const HIGHWAY_CODE_URL = `https://theorytestpass.com/highway-code`;
export const CANCEL_SUBSCRIPTION_URL = `https://theorytestpass.com/cancel`;
export const UPGRADE_URL = `https://theorytestpass.com/upgrade`;
export const PASS_PROTECTION_URL = `https://theorytestpass.com/pass-protection`;
export const ADDITIONAL_VIDEOS_URL = `https://theorytestpass.com/additional-videos`;

export const MOCK_PASS_SCORE = 43;
export const HGV_MOCK_PASS_SCORE = 85;

export const HAZARD_PERCEPTION_PASS = 58;
export const HGV_HAZARD_PERCEPTION_PASS = 67;

export const HAZARD_PERCEPTION_VIDEO = `https://www.theorytestpass.com/videos/hazard-perception/`;
export const DRIVING_LESSONS_VIDEO = `https://www.theorytestpass.com/videos/driving-lessons/`;
export const CASE_STUDY_VIDEO = `https://www.theorytestpass.com/videos/case-studies/`;

export const SCREEN_BAR_WIDTH = 1000;
