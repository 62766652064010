import * as React from 'react';
import { View, Text, TouchableHighlight, ScrollView } from 'react-native';
import { useHistory } from 'react-router-dom';
import styles from './styles.web';

import Tts from 'react-native-tts';

import PageContainer from '../../../PageContainer';
import QuestionBlock from '../../../QuestionTemplates/QuestionBlock/index.web';
import ImagesQuestion from '../../../QuestionTemplates/ImagesQuestion/index.web';
import ImageTitleQuestion from '../../../QuestionTemplates/ImageTitleQuestion/index.web';
import CaseStudyQuestion from '../CaseStudyQuestion/index.web';

const QuestionParent = ({
    totalQuestions,
    questions,
    currentIndex,
    setCurrentIndex,
    userScore,
    setUserScore,
    setQuickTestStage,
    categoryName,
    questionFeedbackBreakdown,
    setQuestionsFeedbackBreakdown
}) => {
    const handleVoice = (ttsText) => {
        Tts.setDefaultVoice('com.apple.ttsbundle.Samantha-compact');
        Tts.setDefaultLanguage('en-US');
        Tts.speak(ttsText);
    };

    const [index, setIndex] = React.useState(1);

    const currentQuestion = questions[currentIndex];
    const answers = JSON.parse(currentQuestion.answers);
    var correctAnswer = null;

    React.useEffect(() => {
        setIndex(null);
    }, [currentIndex]);

    Object.keys(answers).forEach(function (answer) {
        if (answers[answer].correct === '1') {
            correctAnswer = answers[answer];
        }
    });

    const saveQuestion = () => {
        var questionDict = questionFeedbackBreakdown;
        const selectedAnswer = answers[`answers${index}`];

        if (!questionDict[currentQuestion.category]) {
            questionDict[currentQuestion.category] = [[currentQuestion, selectedAnswer]];
        } else {
            questionDict[currentQuestion.category].push([currentQuestion, selectedAnswer]);
        }

        setQuestionsFeedbackBreakdown(questionDict);
    };

    const history = useHistory();

    return (
        <View style={{ flex: 1 }}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                <PageContainer>
                    <Text style={styles.headingQuestion}>
                        Quick Test | {currentIndex + 1}/{totalQuestions}
                    </Text>

                    <Text style={styles.headingText}>{currentQuestion.question} </Text>

                    {currentQuestion.casestudy === '1' ? (
                        <CaseStudyQuestion
                            image={currentQuestion.image}
                            questionTitle={currentQuestion.question}
                            questionOne={answers.answers0.text}
                            questionTwo={answers.answers1.text}
                            questionThree={answers.answers2.text}
                            questionFour={answers.answers3.text}
                            correctAnswer={correctAnswer}
                            index={index}
                            setIndex={setIndex}
                            currentIndex={currentIndex}
                            videoRef={currentQuestion.videoref}
                        />
                    ) : currentQuestion.image.length > 0 ? (
                        <ImageTitleQuestion
                            image={currentQuestion.image}
                            questionOne={answers.answers0.text}
                            questionTwo={answers.answers1.text}
                            questionThree={answers.answers2.text}
                            questionFour={answers.answers3.text}
                            correctAnswer={correctAnswer}
                            index={index}
                            setIndex={setIndex}
                        />
                    ) : answers.answers0.image.length > 0 ? (
                        <ImagesQuestion
                            questionOne={answers.answers0.image}
                            questionTwo={answers.answers1.image}
                            questionThree={answers.answers2.image}
                            questionFour={answers.answers3.image}
                            index={index}
                            setIndex={setIndex}
                            correctAnswer={correctAnswer}
                        />
                    ) : (
                        <QuestionBlock
                            questionOne={answers.answers0.text}
                            questionTwo={answers.answers1.text}
                            questionThree={answers.answers2.text}
                            questionFour={answers.answers3.text}
                            index={index}
                            setIndex={setIndex}
                            correctAnswer={correctAnswer}
                        />
                    )}
                </PageContainer>
            </ScrollView>
            {(index || index === 0) && (
                <View style={styles.navigationButtons}>
                    <TouchableHighlight
                        style={styles.previousButton}
                        onPress={() => {
                            if (currentIndex > 0) {
                                setCurrentIndex((currentIndex -= 1));
                            } else {
                                history.push('quick-test-dashboard');
                            }
                        }}
                        underlayColor="#cac7c6">
                        <Text style={styles.previousButtonText}> Previous </Text>
                    </TouchableHighlight>

                    <TouchableHighlight
                        style={styles.nextButton}
                        onPress={() => {
                            saveQuestion();
                            if (currentIndex + 1 < totalQuestions) {
                                if (answers[`answers${index}`].correct == '1') {
                                    setUserScore((userScore += 1));
                                }
                                setCurrentIndex((currentIndex += 1));
                            } else {
                                if (answers[`answers${index}`].correct == '1') {
                                    setUserScore((userScore += 1));
                                }
                                setQuickTestStage(2);
                            }
                        }}
                        underlayColor="#cac7c6">
                        <Text style={styles.nextButtonText}> Next </Text>
                    </TouchableHighlight>
                </View>
            )}
        </View>
    );
};

export default QuestionParent;
