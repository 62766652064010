import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    input: {
        width: '100%',
        backgroundColor: '#fff',
        marginBottom: 15
    }
});

export default styles;
