import React, { useEffect, useState } from 'react';
import { View, Image, Pressable, Platform } from 'react-native';
import styles from './styles';

import Video from 'react-player';
import { useHistory, useLocation } from 'react-router-dom';

import { DRIVING_LESSONS_VIDEO } from '../../../API/Environment.web';

const LearnHowToVideoPlayer = ({}) => {
    const [videoURL, setVideoURL] = useState();
    const [muted, setMuted] = useState(Platform.OS === 'ios');

    const history = useHistory();
    const location = useLocation();

    const hazard = location.hazard;

    useEffect(() => {
        const videoUrl = `${DRIVING_LESSONS_VIDEO}${hazard.videoref}.mp4`;
        setVideoURL(videoUrl);
    }, []);

    return (
        <>
            <View style={{ width: '80%', height: '80%', backgroundColor: 'black' }}>
                <Pressable
                    onPress={() =>
                        history.push({
                            pathname: '/learn-how-to',
                            heading: location?.heading,
                            id: location?.id
                        })
                    }
                    style={styles.exitIconWeb}>
                    <Image
                        style={{ width: 50, height: 50 }}
                        source={require('../../../../assets/images/HazardPerception/SVG/icon-video-close.svg')}
                    />
                </Pressable>
                <Pressable onPress={() => setMuted(false)}>
                    <View style={styles.videoWeb}>
                        <Video
                            url={videoURL}
                            onEnded={() =>
                                history.push({
                                    pathname: '/learn-how-to',
                                    heading: location?.heading,
                                    id: location?.id
                                })
                            }
                            muted={muted ? true : false}
                            resizeMode="cover"
                            playing={true}
                            playsinline={true}
                            width="100%"
                            height="100%"
                        />
                    </View>
                </Pressable>
            </View>
        </>
    );
};

export default LearnHowToVideoPlayer;
