import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginBottom: 20,
        backgroundColor: 'white',
        borderRadius: 10
    },

    innerContainer: {
        flexDirection: 'row'
    },

    image: {
        width: 50,
        height: 50,
        resizeMode: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10
    },

    textContainer: {
        margin: 10,
        flexShrink: 1
    },

    heading: {
        color: '#0D0334',
        fontSize: 18,
        fontWeight: 'bold'
    },

    headingWeb: {
        color: '#0D0334',
        fontSize: Dimensions.get('window').height / 70,
        fontWeight: 'bold'
    },

    text: {
        color: '#868199',
        fontSize: 14
    },

    textWeb: {
        color: '#868199',
        fontSize: Dimensions.get('window').height / 80
    }
});

export default styles;
