import React, { useEffect, useState } from 'react';
import { View, Text, TouchableHighlight, ScrollView, Image } from 'react-native';
import styles from './styles.web';

import Tts from 'react-native-tts';
import { formatISO9075 } from 'date-fns';

import PageContainer from '../../../PageContainer';
import QuestionBlock from '../../../../Components/QuestionTemplates/QuestionBlock/index.web';
import ImageTitleQuestion from '../../../../Components/QuestionTemplates/ImageTitleQuestion/index.web';
import ImagesQuestion from '../../../../Components/QuestionTemplates/ImagesQuestion/index.web';

const QuestionParent = ({
    totalQuestions,
    questions,
    currentIndex,
    setCurrentIndex,
    userScore,
    setUserScore,
    setTrainingPlanStage,
    questionFeedbackBreakdown,
    setQuestionsFeedbackBreakdown,
    questionsTracker,
    setQuestionsTracker
}) => {
    const handleVoice = (ttsText) => {
        Tts.setIgnoreSilentSwitch('ignore')
            .then(Tts.setDefaultVoice('com.apple.ttsbundle.Samantha-compact'))
            .then(Tts.setDefaultLanguage('en-US'))
            .then(Tts.speak(ttsText));
    };

    const [index, setIndex] = useState(0);

    const currentQuestion = questions[currentIndex];

    const answers = JSON.parse(currentQuestion.answers);

    useEffect(() => {
        setIndex(null);
    }, [currentIndex]);

    const saveQuestion = () => {
        var questionDict = questionFeedbackBreakdown;
        const selectedAnswer = answers[`answers${index}`];

        if (!questionDict[currentQuestion.category]) {
            questionDict[currentQuestion.category] = [[currentQuestion, selectedAnswer]];
        } else {
            questionDict[currentQuestion.category].push([currentQuestion, selectedAnswer]);
        }

        setQuestionsFeedbackBreakdown(questionDict);
    };

    return (
        <View style={{ flex: 1, maxWidth: '75%', margin: 'auto' }}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                <PageContainer>
                    <Text style={styles.headingQuestion}>
                        Training Plan | {currentIndex + 1}/{totalQuestions}
                    </Text>

                    <Image
                        style={styles.testButtonTts}
                        onPress={() => handleVoice(`${currentQuestion.question}`)}
                        source={require('../../../../../assets/images/TrainingPlan/PNG/icon-read-out-loud.png')}
                    />

                    <Text style={styles.headingText}>{currentQuestion.question}</Text>

                    {currentQuestion.image.length > 0 ? (
                        <ImageTitleQuestion
                            image={currentQuestion.image}
                            questionOne={answers.answers0.text}
                            questionTwo={answers.answers1.text}
                            questionThree={answers.answers2.text}
                            questionFour={answers.answers3.text}
                            index={index}
                            setIndex={setIndex}
                        />
                    ) : answers.answers0.image.length > 0 ? (
                        <ImagesQuestion
                            questionOne={answers.answers0.image}
                            questionTwo={answers.answers1.image}
                            questionThree={answers.answers2.image}
                            questionFour={answers.answers3.image}
                            index={index}
                            setIndex={setIndex}
                        />
                    ) : (
                        <QuestionBlock
                            questionOne={answers.answers0.text}
                            questionTwo={answers.answers1.text}
                            questionThree={answers.answers2.text}
                            questionFour={answers.answers3.text}
                            index={index}
                            setIndex={setIndex}
                        />
                    )}
                </PageContainer>
            </ScrollView>
            {(index || index === 0) && (
                <View style={styles.navigationButtons}>
                    <TouchableHighlight
                        style={styles.previousButton}
                        onPress={() => {
                            if (currentIndex > 0) {
                                setCurrentIndex((currentIndex -= 1));
                            } else {
                                setTrainingPlanStage(1);
                            }
                        }}
                        underlayColor="#cac7c6">
                        <Text style={styles.previousButtonText}> Previous </Text>
                    </TouchableHighlight>

                    <TouchableHighlight
                        style={styles.nextButton}
                        onPress={() => {
                            saveQuestion();
                            const dateTime = formatISO9075(new Date());
                            const updateTracker = questionsTracker;
                            if (currentIndex + 1 < totalQuestions) {
                                if (answers[`answers${index}`].correct == '1') {
                                    setUserScore((userScore += 1));
                                    updateTracker.push({
                                        userid: localStorage.getItem('userid'),
                                        section: '1',
                                        question: `${currentQuestion.id}`,
                                        category: `${currentQuestion.category}`,
                                        answer: `${answers[`answers${index}`].text}`,
                                        correct: `${answers[`answers${index}`].correct}`,
                                        dateadded: `${dateTime}`
                                    });
                                    setQuestionsTracker(updateTracker);
                                    setCurrentIndex((currentIndex += 1));
                                } else {
                                    updateTracker.push({
                                        userid: localStorage.getItem('userid'),
                                        section: '1',
                                        question: `${currentQuestion.id}`,
                                        category: `${currentQuestion.category}`,
                                        answer: `${answers[`answers${index}`].text}`,
                                        correct: `${answers[`answers${index}`].correct}`,
                                        dateadded: `${dateTime}`
                                    });
                                    setQuestionsTracker(updateTracker);
                                    setCurrentIndex((currentIndex += 1));
                                }
                            } else {
                                if (answers[`answers${index}`].correct == '1') {
                                    setUserScore((userScore += 1));
                                    updateTracker.push({
                                        userid: localStorage.getItem('userid'),
                                        section: '1',
                                        question: `${currentQuestion.id}`,
                                        category: `${currentQuestion.category}`,
                                        answer: `${answers[`answers${index}`].text}`,
                                        correct: `${answers[`answers${index}`].correct}`,
                                        dateadded: `${dateTime}`
                                    });
                                    setQuestionsTracker(updateTracker);
                                    setTrainingPlanStage(3);
                                } else {
                                    updateTracker.push({
                                        userid: localStorage.getItem('userid'),
                                        section: '1',
                                        question: `${currentQuestion.id}`,
                                        category: `${currentQuestion.category}`,
                                        answer: `${answers[`answers${index}`].text}`,
                                        correct: `${answers[`answers${index}`].correct}`,
                                        dateadded: `${dateTime}`
                                    });
                                    setQuestionsTracker(updateTracker);
                                    setTrainingPlanStage(3);
                                }
                            }
                        }}
                        underlayColor="#cac7c6">
                        <Text style={styles.nextButtonText}> Next </Text>
                    </TouchableHighlight>
                </View>
            )}
        </View>
    );
};

export default QuestionParent;
