import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        backgroundColor: '#F3F2F5',
        alignItems: 'center',
        padding: 36,
        paddingTop: Platform.OS === 'ios' ? 50 : 36,
        flex: 1
    },

    containerWeb: {
        height: '100vh',
        backgroundColor: '#F3F2F5',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 36,
        paddingTop: 85,
        flex: 1
    }
});

export default styles;
