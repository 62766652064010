import React from 'react';
import { View, Text, ScrollView, Image, Dimensions } from 'react-native';
import { Link } from 'react-router-dom';
import styles from './styles.web';

import PageContainer from '../../PageContainer';
import PrimaryButton from '../../Buttons/PrimaryButton';

const Tip = ({ text, icon }) => {
    return (
        <View style={styles.tipViewWeb}>
            {icon}
            <Text style={styles.tipText}>{text}</Text>
        </View>
    );
};

const IntroductionScreen = ({ setPracticeZoneStage, categoryName, totalQuestions }) => {
    return (
        <View style={{ height: '100%', flex: 1, maxWidth: '75%', margin: 'auto' }}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                <PageContainer>
                    <Image
                        style={{ width: '35vw', height: '35vh', resizeMode: 'contain' }}
                        source={require('../../../../assets/images/PracticeZone/PNG/practise-zone-test.png')}
                    />

                    <Text style={styles.categoryName}>{categoryName}</Text>

                    <Tip
                        icon={
                            <Image
                                style={{ resizeMode: 'contain', height: 20, width: 20 }}
                                source={require('../../../../assets/images/PracticeZone/PNG/icon-question.png')}
                            />
                        }
                        text={`${totalQuestions} questions`}
                    />

                    <Tip
                        icon={
                            <Image
                                style={{ resizeMode: 'contain', height: 20, width: 20 }}
                                source={require('../../../../assets/images/PracticeZone/PNG/icon-limit.png')}
                            />
                        }
                        text={'No time limit'}
                    />

                    <Tip
                        icon={
                            <Image
                                style={{ resizeMode: 'contain', height: 20, width: 20 }}
                                source={require('../../../../assets/images/PracticeZone/PNG/icon-hint.png')}
                            />
                        }
                        text={"Use 'Hints' when stuck"}
                    />

                    <View style={{ marginBottom: 20 }} />

                    <PrimaryButton
                        text="Start Practice"
                        onPress={() => setPracticeZoneStage(2)}
                        textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                    />

                    <Link
                        to="/practice-zone"
                        style={{
                            marginTop: 20,
                            width: '100%',
                            padding: 10,
                            borderRadius: 20,
                            textAlign: 'center'
                        }}>
                        <Text style={styles.secondaryButtonText}> Cancel </Text>
                    </Link>
                </PageContainer>
            </ScrollView>
        </View>
    );
};

export default IntroductionScreen;
