import React, { useEffect, useState } from 'react';

import Introduction from '../../../Components/MockTest/Introduction/index.web';
import QuestionParent from '../../../Components/MockTest/Test/QuestionParent/index.web';
import Results from '../../../Components/MockTest/Results/index.web';
import LoadingState from '../../../Components/LoadingState';
import FlaggedQuestionsBreakdown from '../FlaggedQuestionsBreakdown/index.web';

import { fetchRandomQuestions } from '../../../API/RealmComms.web';
import FlaggedQuestionParent from '../../../Components/MockTest/Test/FlaggedQuestionParent/index.web';

const MockTest = () => {
    const [mockTestStage, setMockTestStage] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [userScore, setUserScore] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [questionFeedbackBreakdown, setQuestionsFeedbackBreakdown] = useState({});
    const [questionsTracker, setQuestionsTracker] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flaggedQuestions, setFlaggedQuestions] = useState([]);
    const [minutes, setMinutes] = useState(null);
    const [seconds, setSeconds] = useState(59);
    const [timeup, setTimeup] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const isHgv = localStorage.getItem('testtype') === '2';
            const res = await fetchRandomQuestions(isHgv ? 100 : 50, true);
            setMinutes(isHgv ? 114 : 56);
            setQuestions(res);
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
            if (minutes === 0 && seconds === 0) {
                setTimeup(true);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [seconds]);

    const isHgv = localStorage.getItem('testtype') === '2';
    const formatTimer = `${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;

    if (loading) {
        return <LoadingState />;
    } else if (mockTestStage == 1) {
        return <Introduction setMockTestStage={setMockTestStage} isHgv={isHgv} />;
    } else if (mockTestStage == 2) {
        return (
            <QuestionParent
                setMockTestStage={setMockTestStage}
                totalQuestions={isHgv ? 100 : 50}
                questions={questions}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                userScore={userScore}
                setUserScore={setUserScore}
                questionFeedbackBreakdown={questionFeedbackBreakdown}
                setQuestionsFeedbackBreakdown={setQuestionsFeedbackBreakdown}
                questionsTracker={questionsTracker}
                setQuestionsTracker={setQuestionsTracker}
                flaggedQuestions={flaggedQuestions}
                setFlaggedQuestions={setFlaggedQuestions}
                timer={formatTimer}
                timeup={timeup}
                isHgv={isHgv}
            />
        );
    } else if (mockTestStage == 3) {
        return (
            <FlaggedQuestionsBreakdown
                questionFeedbackBreakdown={questionFeedbackBreakdown}
                userScore={userScore}
                questionsTracker={questionsTracker}
                flaggedQuestions={flaggedQuestions}
                setFlaggedQuestions={setFlaggedQuestions}
                setMockTestStage={setMockTestStage}
                timer={formatTimer}
                timeup={timeup}
            />
        );
    } else if (mockTestStage == 4) {
        return (
            <FlaggedQuestionParent
                setMockTestStage={setMockTestStage}
                totalQuestions={isHgv ? 100 : 50}
                userScore={userScore}
                setUserScore={setUserScore}
                questionFeedbackBreakdown={questionFeedbackBreakdown}
                setQuestionsFeedbackBreakdown={setQuestionsFeedbackBreakdown}
                questionsTracker={questionsTracker}
                setQuestionsTracker={setQuestionsTracker}
                flaggedQuestions={flaggedQuestions}
                setFlaggedQuestions={setFlaggedQuestions}
                timer={formatTimer}
                timeup={timeup}
                isHgv={isHgv}
            />
        );
    } else if (mockTestStage == 5) {
        return (
            <Results
                questionFeedbackBreakdown={questionFeedbackBreakdown}
                userScore={userScore}
                isHgv={isHgv}
                totalQuestions={isHgv ? 100 : 50}
                questionsTracker={questionsTracker}
            />
        );
    }
};

export default MockTest;
