import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    container: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 250,
        width: '100%',
        borderColor: '#c8983b',
        borderWidth: 2,
        marginBottom: 30,
        marginTop: 28,
        borderRadius: 10,
        backgroundColor: '#fff',
        paddingHorizontal: 10
    },
    webContainer: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 250,
        width: '100%',
        borderColor: '#c8983b',
        borderWidth: 2,
        marginBottom: 30,
        marginTop: 28,
        borderRadius: 10,
        backgroundColor: '#fff',
        padding: 10,
        paddingHorizontal: 25,
        paddingTop: 0
    },
    ppImage: {
        width: '100%',
        height: 110,
        resizeMode: 'contain',
        top: '-12%'
    },
    text: {
        fontWeight: 'bold',
        fontSize: 18,
        textAlign: 'center',
        color: '#121148'
    },
    textWeb: {
        fontWeight: 'bold',
        fontSize: Dimensions.get('window').height / 65,
        textAlign: 'center',
        color: '#121148'
    },
    button: {
        marginVertical: 20,
        borderRadius: 5
    },
    buttonText: {
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'center'
    },
    buttonTextWeb: {
        fontWeight: 'bold',
        fontSize: Dimensions.get('window').height / 65,
        textAlign: 'center'
    }
});

export default styles;
