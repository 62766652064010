import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Introduction from '../../../Components/PracticeZone/Introduction/index.web';
import QuestionParent from '../../../Components/PracticeZone/Test/QuestionParent/index.web';
import Results from '../../../Components/PracticeZone/Results/index.web';
import LoadingState from '../../../Components/LoadingState';

import { fetchQuestions } from '../../../API/Joomla.web';

const PractiseZoneTest = () => {
    const [practiceZoneStage, setPracticeZoneStage] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [indexTracker, setIndexTracker] = useState([]);
    const [userScore, setUserScore] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [questionFeedbackBreakdown, setQuestionsFeedbackBreakdown] = useState([]);
    const [questionsTracker, setQuestionsTracker] = useState([]);
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const categoryName = location.categoryName;
    const categoryId = location.categoryId;

    useEffect(() => {
        (async () => {
            setLoading(true);
            var res = await fetchQuestions();
            var questions = [];
            res.forEach((question) => {
                if (parseInt(categoryId) === parseInt(question.category)) {
                    questions.push(question);
                }
            });
            questions = questions.sort(() => Math.random() - 0.5);
            setQuestions(questions);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <LoadingState />;
    } else if (practiceZoneStage == 1) {
        return (
            <Introduction
                setPracticeZoneStage={setPracticeZoneStage}
                categoryName={categoryName}
                totalQuestions={questions.length}
            />
        );
    } else if (practiceZoneStage == 2) {
        return (
            <QuestionParent
                setPracticeZoneStage={setPracticeZoneStage}
                totalQuestions={questions.length}
                questions={questions}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                indexTracker={indexTracker}
                setIndexTracker={setIndexTracker}
                userScore={userScore}
                setUserScore={setUserScore}
                categoryName={categoryName}
                questionFeedbackBreakdown={questionFeedbackBreakdown}
                setQuestionsFeedbackBreakdown={setQuestionsFeedbackBreakdown}
                questionsTracker={questionsTracker}
                setQuestionsTracker={setQuestionsTracker}
            />
        );
    } else if (practiceZoneStage == 3) {
        return (
            <Results
                questionFeedbackBreakdown={questionFeedbackBreakdown}
                userScore={userScore}
                totalQuestions={questions.length}
                questionsTracker={questionsTracker}
            />
        );
    }
};

export default PractiseZoneTest;
