import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: 10,
        borderRadius: 10,
        marginBottom: 10,
        flexShrink: 1
    },
    containerWeb: {
        backgroundColor: '#fff',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        borderRadius: 10,
        marginBottom: 10,
        flexShrink: 1
    },
    text: {
        marginLeft: 10,
        fontWeight: 'bold',
        color: '#121148',
        fontSize: 15
    },
    textContainer: {
        width: '80%'
    },
    checkCrossWeb: {
        width: 35,
        height: 35,
        resizeMode: 'contain'
    }
});

export default styles;
