import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    headerBackgroundImageWeb: {
        height: 100,
        backgroundColor: 'white'
    },

    buttonRowViews: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5
    },

    buttonRightView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        marginRight: '10%'
    },

    dashboardHeaderProfileButton: {
        borderRadius: 10,
        width: 'auto',
        height: 'auto',
        marginRight: '10%'
    },

    dashboardHeaderProfileButtonLeft: {
        borderRadius: 10,
        width: 'auto',
        height: 'auto'
    },

    dashboardTitle: {
        fontSize: 36,
        marginBottom: 24,
        color: '#fff',
        fontWeight: 'bold',
        marginLeft: '20%'
    },

    dashboardTitleButtons: {
        fontSize: 36,
        marginBottom: 24,
        color: '#fff',
        fontWeight: 'bold'
    }
});

export default styles;
