import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, Dimensions } from 'react-native';
import styles from './styles.web';

import { useHistory } from 'react-router-dom';

import PageContainer from '../../../Components/PageContainer';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton';
import SelectionButton from '../../../Components/Buttons/SelectionButton';
import Loading from '../../../Components/LoadingState';
import TrialPopup from '../../../Components/TrialPopup/index.web';

import { arrayRows } from '../../../API/Joomla.web';
import { useAuth } from '../../../Context/AuthContext';
import { UPGRADE_URL } from '../../../API/Environment.web';
import { fetchCategoriesWithoutRoadsignsOrEmpties } from '../../../API/RealmComms.web';

const IntroductionScreen = () => {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [questionCount, setQuestionCount] = useState(10);
    const [questionType, setQuestionType] = useState(1);
    const [categories, setCategories] = useState(null);
    const [loading, setLoading] = useState(true);
    const [trialExceeded, setTrialExceeded] = useState(false);

    const history = useHistory();
    const auth = useAuth();

    useEffect(() => {
        (async () => {
            setLoading(true);
            const categories = await fetchCategoriesWithoutRoadsignsOrEmpties();
            setCategories(categories);
            setLoading(false);
        })();
    }, []);

    const addCategory = (category) => {
        const arrayOfCategory = selectedCategories.indexOf(category.id);
        if (arrayOfCategory > -1) {
            const removeCategory = [...selectedCategories];
            removeCategory.splice(arrayOfCategory, 1);
            setSelectedCategories(removeCategory);
        } else {
            const addCategory = [...selectedCategories];
            addCategory.push(category.id);
            setSelectedCategories(addCategory);
        }
    };

    if (loading) {
        return <Loading />;
    } else {
        return (
            <View style={{ height: '100%', flex: 1, maxWidth: '75%', margin: 'auto' }}>
                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <TrialPopup
                        trialExceeded={trialExceeded}
                        setTrialExceeded={setTrialExceeded}
                        onPress={() =>
                            (window.location.href = `${UPGRADE_URL}${auth.user.urlParams}`)
                        }
                    />

                    <PageContainer>
                        <Text style={styles.headingText}>Select your categories </Text>

                        {arrayRows(categories, 2).map((slice, index) => {
                            return (
                                <View style={styles.selectionContainer}>
                                    {slice.map((category, i) => {
                                        return (
                                            <SelectionButton
                                                text={
                                                    auth.user.trial === 'No' ||
                                                    category.showontrial == '1'
                                                        ? category.name
                                                        : `${category.name} (Locked)`
                                                }
                                                key={i}
                                                onPress={() => {
                                                    auth.user.trial === 'No' ||
                                                    category.showontrial == '1'
                                                        ? addCategory(category)
                                                        : setTrialExceeded(true);
                                                }}
                                                selected={selectedCategories.includes(category.id)}
                                                style={
                                                    auth.user.trial === 'No' ||
                                                    category.showontrial == '1'
                                                        ? { width: '50%', marginRight: 10 }
                                                        : {
                                                              width: '50%',
                                                              marginRight: 10,
                                                              backgroundColor: 'grey'
                                                          }
                                                }
                                                textStyle={
                                                    auth.user.trial === 'No' ||
                                                    category.showontrial == '1'
                                                        ? {
                                                              fontSize:
                                                                  Dimensions.get('window').height /
                                                                  60,
                                                              textAlign: 'center'
                                                          }
                                                        : {
                                                              fontSize:
                                                                  Dimensions.get('window').height /
                                                                  60,
                                                              textAlign: 'center',
                                                              color: 'white'
                                                          }
                                                }
                                            />
                                        );
                                    })}
                                </View>
                            );
                        })}

                        <Text style={styles.headingText}>How many questions? </Text>

                        <View style={styles.selectionContainer}>
                            <SelectionButton
                                text="10"
                                onPress={() => setQuestionCount(10)}
                                selected={questionCount === 10}
                                style={{ width: '25%', marginRight: 10 }}
                                textStyle={styles.selectionText}
                            />

                            <SelectionButton
                                text="20"
                                onPress={() => setQuestionCount(20)}
                                selected={questionCount === 20}
                                style={{ width: '25%', marginRight: 10 }}
                                textStyle={styles.selectionText}
                            />

                            <SelectionButton
                                text="30"
                                onPress={() => setQuestionCount(30)}
                                selected={questionCount === 30}
                                style={{ width: '25%', marginRight: 10 }}
                                textStyle={styles.selectionText}
                            />

                            <SelectionButton
                                text="40"
                                onPress={() => setQuestionCount(40)}
                                selected={questionCount === 40}
                                style={{ width: '25%' }}
                                textStyle={styles.selectionText}
                            />
                        </View>

                        <Text style={styles.headingText}>Which type of questions? </Text>

                        <SelectionButton
                            text="Any questions"
                            onPress={() => setQuestionType(1)}
                            selected={questionType === 1}
                            textStyle={styles.selectionText}
                        />

                        <SelectionButton
                            text="Previously wrong questions"
                            onPress={() => setQuestionType(2)}
                            selected={questionType === 2}
                            textStyle={styles.selectionText}
                        />

                        <SelectionButton
                            text="Unseen questions"
                            onPress={() => setQuestionType(3)}
                            selected={questionType === 3}
                            textStyle={styles.selectionText}
                        />

                        <PrimaryButton
                            text="Start test"
                            onPress={() =>
                                history.push({
                                    pathname: '/quick-test',
                                    categories: selectedCategories,
                                    categoryList: categories,
                                    noOfQuestions: questionCount,
                                    questionType: questionType
                                })
                            }
                            disabled={selectedCategories.length < 1}
                            textStyle={styles.selectionText}
                            style={
                                selectedCategories.length < 1
                                    ? { backgroundColor: 'grey' }
                                    : { backgroundColor: '#8CC63F' }
                            }
                        />
                    </PageContainer>
                </ScrollView>
            </View>
        );
    }
};

export default IntroductionScreen;
