import React, { useEffect, useState } from 'react';
import { View, Text, TouchableHighlight, ScrollView, Image } from 'react-native';
import styles from './styles.web';

import Tts from 'react-native-tts';
import { formatISO9075 } from 'date-fns';

import PageContainer from '../../../PageContainer';
import QuestionBlock from '../../../QuestionTemplates/QuestionBlock/index.web';
import ImagesQuestion from '../../../QuestionTemplates/ImagesQuestion/index.web';
import ImageTitleQuestion from '../../../QuestionTemplates/ImageTitleQuestion/index.web';
import CaseStudyQuestion from '../../../QuestionTemplates/CaseStudyQuestion/index.web';
import HintPopup from '../../../HintPopup';
import PopupModal from '../../../Popup';

const FlaggedQuestionParent = ({
    totalQuestions,
    userScore,
    setUserScore,
    setMockTestStage,
    questionFeedbackBreakdown,
    setQuestionsFeedbackBreakdown,
    questionsTracker,
    setQuestionsTracker,
    currentUser,
    flaggedQuestions,
    setFlaggedQuestions,
    timer,
    timeup,
    isHgv
}) => {
    const [index, setIndex] = useState(null);
    const [hintModal, setHintModal] = useState(0);
    const [questionIndex, setQuestionIndex] = useState(0);

    const currentQuestion = flaggedQuestions[questionIndex].question;
    const answers = JSON.parse(currentQuestion.answers);
    var correctAnswer = null;

    Object.keys(answers).forEach(function (answer) {
        if (answers[answer].correct === '1') {
            correctAnswer = answers[answer];
        }
    });

    useEffect(() => {
        setIndex(0);
    }, [questionIndex]);

    const saveQuestion = (currentQuestion) => {
        var questionDict = questionFeedbackBreakdown;
        const selectedAnswer = answers[`answers${index}`];
        const dateTime = formatISO9075(new Date());
        const updateTracker = questionsTracker;

        if (!questionDict[currentQuestion.category]) {
            questionDict[currentQuestion.category] = [[currentQuestion, selectedAnswer]];
        } else {
            questionDict[currentQuestion.category].push([currentQuestion, selectedAnswer]);
        }

        setQuestionsFeedbackBreakdown(questionDict);
        updateTracker.push({
            userid: localStorage.getItem('userid'),
            section: '3',
            question: `${currentQuestion.id}`,
            category: `${currentQuestion.category}`,
            answer: `${answers[`answers${index}`].text}`,
            correct: `${answers[`answers${index}`].correct}`,
            dateadded: `${dateTime}`
        });
        setQuestionsTracker(updateTracker);

        if (answers[`answers${index}`].correct == '1') {
            setUserScore((userScore += 1));
        }

        setIndex(null);

        if (questionIndex + 1 < flaggedQuestions.length) {
            const newIndex = questionIndex + 1;
            setQuestionIndex(newIndex);
        } else {
            setMockTestStage(5);
        }
    };

    return (
        <View style={{ flex: 1 }}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                <PageContainer>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.headingQuestion}>
                            Mock Test | {flaggedQuestions[questionIndex].questionNumber}/
                            {totalQuestions}
                        </Text>

                        <Text style={styles.timer}>{timer}</Text>
                    </View>

                    <PopupModal
                        visible={timeup}
                        setVisible={null}
                        title={'Time up'}
                        secondaryText={'Your time is up, you will now be taken to the results page'}
                        buttonText={'Go to results page'}
                        onPress={() => setMockTestStage(5)}
                    />

                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginBottom: 20
                        }}>
                        {currentQuestion.hint.length > 0 && (
                            <TouchableHighlight
                                onPress={() => setHintModal(true)}
                                underlayColor="#cac7c6"
                                style={styles.hintButton}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        padding: 10,
                                        alignItems: 'center'
                                    }}>
                                    <Image
                                        style={{ resizeMode: 'contain', height: 15, width: 15 }}
                                        source={require('../../../../../assets/images/PracticeZone/PNG/icon-hint-white.png')}
                                    />
                                    <Text style={styles.hintText}>Hint</Text>
                                </View>
                            </TouchableHighlight>
                        )}
                    </View>

                    <HintPopup
                        visible={hintModal}
                        setVisible={setHintModal}
                        secondaryText={currentQuestion.hint}
                    />

                    {currentQuestion.casestudy === '1' ? (
                        <Text style={styles.caseStudyHeadingText}>
                            Please watch the video before answering these 3 questions
                        </Text>
                    ) : (
                        <Text style={styles.headingText}>{currentQuestion.question} </Text>
                    )}

                    {currentQuestion.casestudy === '1' ? (
                        <CaseStudyQuestion
                            image={currentQuestion.image}
                            questionTitle={currentQuestion.question}
                            questionOne={answers.answers0.text}
                            questionTwo={answers.answers1.text}
                            questionThree={answers.answers2.text}
                            questionFour={answers.answers3.text}
                            correctAnswer={correctAnswer}
                            index={index}
                            setIndex={setIndex}
                            currentIndex={flaggedQuestions[questionIndex].questionNumber}
                            videoRef={currentQuestion.videoref}
                            isHgv={isHgv}
                        />
                    ) : currentQuestion.image.length > 0 ? (
                        <ImageTitleQuestion
                            image={currentQuestion.image}
                            questionOne={answers.answers0.text}
                            questionTwo={answers.answers1.text}
                            questionThree={answers.answers2.text}
                            questionFour={answers.answers3.text}
                            correctAnswer={correctAnswer}
                            index={index}
                            setIndex={setIndex}
                        />
                    ) : answers.answers0.image.length > 0 ? (
                        <ImagesQuestion
                            questionOne={answers.answers0.image}
                            questionTwo={answers.answers1.image}
                            questionThree={answers.answers2.image}
                            questionFour={answers.answers3.image}
                            index={index}
                            setIndex={setIndex}
                            correctAnswer={correctAnswer}
                        />
                    ) : (
                        <QuestionBlock
                            questionOne={answers.answers0.text}
                            questionTwo={answers.answers1.text}
                            questionThree={answers.answers2.text}
                            questionFour={answers.answers3.text}
                            index={index}
                            setIndex={setIndex}
                            correctAnswer={correctAnswer}
                        />
                    )}
                </PageContainer>
            </ScrollView>

            <View style={styles.navigationButtons}>
                <TouchableHighlight
                    style={styles.previousButton}
                    onPress={() => {
                        setMockTestStage(5);
                    }}
                    underlayColor="#cac7c6">
                    <Text style={styles.previousButtonText}> Submit </Text>
                </TouchableHighlight>

                <TouchableHighlight
                    style={styles.nextButton}
                    onPress={() => {
                        saveQuestion(currentQuestion);
                    }}
                    underlayColor="#cac7c6">
                    <Text style={styles.nextButtonText}> Next Flag </Text>
                </TouchableHighlight>
            </View>
        </View>
    );
};

export default FlaggedQuestionParent;
