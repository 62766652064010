import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    headerBackgroundImageWeb: {
        height: 110,
        backgroundColor: 'white'
    },

    buttonRowViews: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10
    },

    buttonRightView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        marginRight: '10%'
    },

    dashboardHeaderProfileButton: {
        borderRadius: 10,
        width: 50,
        height: 50,
        marginRight: '10%'
    },

    dashboardTitle: {
        fontSize: 36,
        marginBottom: 24,
        color: '#fff',
        fontWeight: 'bold',
        marginLeft: '20%'
    }
});

export default styles;
