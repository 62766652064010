import React, { useEffect, useState } from 'react';
import { TouchableHighlight, View, Text, Image } from 'react-native';
import styles from './styles.web';

import { useHistory } from 'react-router-dom';

import LoadingState from '../../../Components/LoadingState/index.web';
import ModalPopup from '../../../Components/Popup';

import { useAuth } from '../../../Context/AuthContext';
import { fetchCategoryNameMapping, fetchGroupedHazardClips } from '../../../API/RealmComms.web';
import { fetchUserHazards, arrayRows } from '../../../API/Joomla.web';
import { GET_ONLINE_IMAGE_PATH, UPGRADE_URL } from '../../../API/Environment.web';

const HazardPerceptionPractice = () => {
    const [groupedHazards, setGroupedHazards] = useState();
    const [userHazards, setUserHazards] = useState();
    const [categoryMappings, setCategoryMappings] = useState();
    const [loading, setLoading] = useState(false);
    const [trialExceeded, setTrialExceeded] = useState(false);

    const history = useHistory();
    const auth = useAuth();

    useEffect(() => {
        (async () => {
            setLoading(true);
            const hazards = await fetchGroupedHazardClips();
            const categoryNameMappings = await fetchCategoryNameMapping();
            const userHazards = await fetchUserHazards();
            setUserHazards(userHazards);
            setGroupedHazards(hazards);
            setCategoryMappings(categoryNameMappings);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <LoadingState />;
    }
    return (
        <View style={styles.container}>
            <ModalPopup
                visible={trialExceeded}
                setVisible={setTrialExceeded}
                title={'Trial Exceeded'}
                secondaryText={'Please upgrade your plan to gain access to this video.'}
                buttonText={'Choose a plan'}
                onPress={() => (window.location.href = `${UPGRADE_URL}${auth.user.urlParams}`)}
                skip
            />
            {groupedHazards &&
                Object.entries(groupedHazards).map((group) => {
                    return (
                        <>
                            <Text style={styles.groupTitle}>{categoryMappings[group[0]]}</Text>
                            {arrayRows(group[1], 3, true).map((slice, key) => {
                                return (
                                    <View style={styles.hazardRow} id={key}>
                                        {slice.map((hazard, key) => {
                                            if (hazard === null) {
                                                return <View style={styles.videoButtonStyle} />;
                                            } else {
                                                const score =
                                                    userHazards !== undefined
                                                        ? userHazards
                                                              .filter(
                                                                  (x) => x.hazardid === hazard.id
                                                              )
                                                              .map((x) => x.score)
                                                        : [0];
                                                return (
                                                    <>
                                                        <TouchableHighlight
                                                            onPress={() => {
                                                                auth.user.trial === 'No' ||
                                                                hazard.showontrial == '1'
                                                                    ? history.push({
                                                                          pathname:
                                                                              '/hazard-perception-test',
                                                                          hazard: hazard
                                                                      })
                                                                    : setTrialExceeded(true);
                                                            }}
                                                            style={[
                                                                styles.videoButtonStyle,
                                                                score > 0 && score < 2
                                                                    ? { borderColor: 'red' }
                                                                    : score > 2 && score <= 4
                                                                    ? { borderColor: 'orange' }
                                                                    : score > 4
                                                                    ? { borderColor: 'green' }
                                                                    : { borderColor: 'black' }
                                                            ]}
                                                            underlayColor="#cac7c6"
                                                            id={key}>
                                                            <>
                                                                <Image
                                                                    style={
                                                                        localStorage.getItem(
                                                                            'trial'
                                                                        ) == 'No' ||
                                                                        hazard.showontrial == '1'
                                                                            ? [styles.thumbnail]
                                                                            : [
                                                                                  styles.thumbnail,
                                                                                  { opacity: 0.3 }
                                                                              ]
                                                                    }
                                                                    source={{
                                                                        uri: `${GET_ONLINE_IMAGE_PATH}${hazard.thumbnail}`
                                                                    }}
                                                                />
                                                                <View
                                                                    style={[
                                                                        styles.score,
                                                                        score > 0 && score < 2
                                                                            ? {
                                                                                  borderColor:
                                                                                      'red',
                                                                                  textAlign:
                                                                                      'center'
                                                                              }
                                                                            : score > 2 &&
                                                                              score <= 4
                                                                            ? {
                                                                                  borderColor:
                                                                                      'orange',
                                                                                  textAlign:
                                                                                      'center'
                                                                              }
                                                                            : score > 4
                                                                            ? {
                                                                                  borderColor:
                                                                                      'green',
                                                                                  textAlign:
                                                                                      'center'
                                                                              }
                                                                            : {
                                                                                  borderColor:
                                                                                      'black',
                                                                                  textAlign:
                                                                                      'center'
                                                                              }
                                                                    ]}>
                                                                    <Text
                                                                        style={{
                                                                            justifyContent:
                                                                                'center',
                                                                            alignItems: 'center',
                                                                            fontSize: 10
                                                                        }}>
                                                                        {score[0] ? score : 0}/5
                                                                    </Text>
                                                                </View>
                                                                <Image
                                                                    source={
                                                                        localStorage.getItem(
                                                                            'trial'
                                                                        ) == 'No' ||
                                                                        hazard.showontrial == '1'
                                                                            ? require('../../../../assets/images/HazardPerception/SVG/icon-play-blue.svg')
                                                                            : require('../../../../assets/images/HazardPerception/PNG/padlock.png')
                                                                    }
                                                                    style={[styles.playIcon]}
                                                                />
                                                            </>
                                                        </TouchableHighlight>
                                                    </>
                                                );
                                            }
                                        })}
                                    </View>
                                );
                            })}
                        </>
                    );
                })}
        </View>
    );
};

export default HazardPerceptionPractice;
