import React, { useState, useEffect } from 'react';
import { View, Text, TouchableHighlight, Dimensions, Image } from 'react-native';
import Video from 'react-player';
import styles from './styles.web';
import { CASE_STUDY_VIDEO } from '../../../API/Environment.web';

const QuestionRow = ({ text, selected, onPress, disabled }) => {
    return (
        <View style={styles.buttonView}>
            <TouchableHighlight
                style={[styles.button, selected && styles.selectedButton]}
                onPress={onPress}
                underlayColor="#cac7c6"
                disabled={!!disabled}>
                <>
                    <Text style={[styles.buttonText, selected && styles.selectedText]}>{text}</Text>
                </>
            </TouchableHighlight>
        </View>
    );
};

const CaseStudyQuestion = ({
    questionTitle,
    questionOne,
    questionTwo,
    questionThree,
    questionFour,
    correctAnswer,
    index,
    setIndex,
    currentIndex,
    videoRef,
    isHgv
}) => {
    const [videoURL, setVideoURL] = useState();
    const [videoPlaying, setVideoPlaying] = useState(true);

    useEffect(() => {
        (async () => {
            const URL = `${CASE_STUDY_VIDEO}${videoRef}.mp4`;
            setVideoURL(URL);
        })();
    }, []);

    return (
        <View style={{ width: '100%' }}>
            <View style={styles.videoView}>
                {!videoPlaying && (
                    <TouchableHighlight
                        onPress={() => {
                            setVideoPlaying(true);
                        }}
                        style={styles.pauseButton}
                        underlayColor="#cac7c6">
                        <Image
                            style={styles.playIcon}
                            source={require('../../../../assets/images/HazardPerception/SVG/icon-play-blue.svg')}
                        />
                    </TouchableHighlight>
                )}

                {videoURL && (
                    <Video
                        url={videoURL}
                        onEnded={() => setVideoPlaying(false)}
                        style={{ alignSelf: 'center', marginVertical: 20 }}
                        playing={videoPlaying}
                        playsinline={true}
                        width="70vw"
                        height="35vw"
                    />
                )}
            </View>

            <Text style={{ fontSize: Dimensions.get('window').height / 60 }}>
                Q{isHgv ? currentIndex - 96 : currentIndex - 46} OF 3 VIDEO QUESTIONS
            </Text>

            <Text style={styles.titleText}>{questionTitle}</Text>

            <QuestionRow
                text={questionOne}
                onPress={() => setIndex(0)}
                selected={index == 0}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionTwo}
                onPress={() => setIndex(1)}
                selected={index == 1}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionThree}
                onPress={() => setIndex(2)}
                selected={index == 2}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionFour}
                onPress={() => setIndex(3)}
                selected={index == 3}
                correctAnswer={correctAnswer}
                index={index}
            />
        </View>
    );
};

export default CaseStudyQuestion;
