import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    buttonRowView: {
        flexDirection: 'row',
        paddingTop: 20,
        justifyContent: 'space-evenly',
        width: '100%',
        paddingBottom: 20
    },

    selectionButton: {
        backgroundColor: '#F3F2F5',
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: '30%',
        height: 50
    },

    selectionButtonText: {
        fontSize: Dimensions.get('window').height / 70,
        marginVertical: 5,
        marginHorizontal: 1,
        textAlign: 'center'
    }
});

export default styles;
