import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        minHeight: '100vh',
        width: '100vw',
        margin: 'auto',
        marginTop: 40,
        paddingHorizontal: 36,
        backgroundColor: '#F3F2F5'
    },

    videoButtonStyle: {
        height: 200,
        width: '29%',
        borderRadius: 12,
        marginHorizontal: 20,
        marginBottom: 10,
        justifyContent: 'center',
        marginTop: 20
    },

    hazardRow: {
        flexDirection: 'row',
        width: '100%',
        marginBottom: 20,
        alignItems: 'center',
        justifyContent: 'center'
    },

    thumbnail: {
        height: '100%',
        width: '100%',
        borderRadius: 10,
        resizeMode: 'stretch'
    },

    groupTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#0D0334',
        marginBottom: 20
    },

    scoreView: {
        height: 'auto'
    },

    score: {
        flex: 1,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        height: 40,
        width: 40,
        borderWidth: 2,
        zIndex: 100,
        position: 'absolute',
        top: -20,
        right: 5
    },

    scoreWeb: {
        fontSize: 10,
        fontWeight: 'bold',
        color: '#0D0334',
        backgroundColor: '#fff',
        borderColor: 'black',
        padding: 10,
        borderRadius: 20,
        height: 40,
        width: 45,
        borderWidth: 2,
        alignSelf: 'flex-end',
        zIndex: 100,
        position: 'absolute',
        top: -20,
        right: 5
    },

    playIcon: {
        width: 75,
        height: 75,
        resizeMode: 'contain',
        position: 'absolute',
        alignSelf: 'center'
    }
});

export default styles;
