import * as React from 'react';
import { View, Text, ScrollView, TouchableHighlight, FlatList, Image } from 'react-native';
import styles from './styles.web';

import PopupModal from '../../../Components/Popup';

const QuestionButton = ({ question }) => {
    const questionNumber = question.item.questionNumber;
    var question = question.item.question;

    return (
        <TouchableHighlight style={styles.questionButtonStyle} disabled>
            <View style={styles.questionButtonItems}>
                <Text style={styles.questionNumber}>
                    Q{questionNumber < 10 && 0}
                    {questionNumber}
                </Text>
                <Text numberOfLines={10} style={styles.questionTitle}>
                    {question.question}
                </Text>
            </View>
        </TouchableHighlight>
    );
};

const FlaggedQuestionsParent = ({ flaggedQuestions, setMockTestStage, timer, timeup }) => {
    return (
        <View style={{ flex: 1 }}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                <PopupModal
                    visible={timeup}
                    setVisible={null}
                    title={'Time up'}
                    secondaryText={'Your time is up, you will now be taken to the results page'}
                    buttonText={'Go to results page'}
                    onPress={() => setMockTestStage(5)}
                />

                <View style={styles.container}>
                    <Text style={styles.title}>Do you want to answer the flagged questions?</Text>

                    <FlatList
                        data={flaggedQuestions}
                        keyExtractor={(question) => question.id}
                        renderItem={(question) => <QuestionButton question={question} />}
                    />
                </View>
            </ScrollView>

            <View style={styles.finishButtonView}>
                <TouchableHighlight
                    style={styles.finishButton}
                    onPress={() => {
                        setMockTestStage(4);
                    }}
                    underlayColor="#cac7c6">
                    <View style={styles.finishButtonItems}>
                        <Text style={styles.finishButtonText}>Answer</Text>
                    </View>
                </TouchableHighlight>

                <TouchableHighlight
                    style={styles.finishButton}
                    onPress={() => {
                        setMockTestStage(5);
                    }}
                    underlayColor="#cac7c6">
                    <View style={styles.finishButtonItems}>
                        <Image
                            style={{ resizeMode: 'contain', height: 25, width: 25 }}
                            source={require('../../../../assets/images/MockTest/PNG/icon-btn-flag-white.png')}
                        />
                        <Text style={styles.finishButtonText}> Finish Test</Text>
                    </View>
                </TouchableHighlight>
            </View>
        </View>
    );
};

export default FlaggedQuestionsParent;
