import React, { useState, useEffect } from 'react';
import { View, Image, Text } from 'react-native';
import { useHistory } from 'react-router-dom';

import styles from './styles';
import PrimaryButton from '../Buttons/PrimaryButton';

import { calculatePPPercentage, notNumbersOrSpecialChars } from '../../API/RealmComms.web';
import PassProtectionPopup from '../PassProtectionPopup/index.web';
import { useAuth } from '../../Context/AuthContext';

const PassProtection = ({ status, style }) => {
    const history = useHistory();
    const [message, setMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const auth = useAuth();

    if (!status) return;

    const { requirements, numOfCompleted } = calculatePPPercentage(status);
    const numOfRequirements = requirements.length;

    const passProtectionPurchased = auth.user.passProtectionPurchased == '1';

    const isCovered = passProtectionPurchased && numOfCompleted === numOfRequirements;
    const userGreeting = notNumbersOrSpecialChars(localStorage.getItem('firstname'))
        ? localStorage.getItem('firstname')
        : 'Hi';

    useEffect(() => {
        if (isCovered) {
            setMessage("Congratulations you're now covered by Pass Protection");
        } else if (!passProtectionPurchased && numOfCompleted === numOfRequirements) {
            setMessage(
                `You’re now eligible for Pass Protect` +
                    `${
                        notNumbersOrSpecialChars(localStorage.getItem('firstname'))
                            ? `, ${localStorage.getItem('firstname')}!`
                            : '.'
                    }`
            );
        } else if (!passProtectionPurchased && numOfCompleted !== numOfRequirements) {
            setMessage(`${userGreeting}, you're close to unlocking pass protection!`);
        } else {
            setMessage(
                `Complete ${
                    numOfRequirements - numOfCompleted
                } more tasks to activate pass protection & priority support.`
            );
        }
    }, []);

    return (
        <View style={[styles.webContainer, style]}>
            <Image
                style={styles.ppImage}
                source={require('../../../assets/images/PassProtection/pp-icon.png')}
            />
            <Text style={styles.textWeb}>{message}</Text>
            <PrimaryButton
                text={!passProtectionPurchased ? 'EXPLORE PASS PROTECT' : 'VIEW PROGRESS'}
                onPress={() => {
                    if (!passProtectionPurchased) {
                        setShowPopup(true);
                    } else {
                        history.push('/pass-protect');
                    }
                }}
                style={styles.button}
                textStyle={styles.buttonTextWeb}
            />
            <PassProtectionPopup
                visible={showPopup}
                onClose={() => setShowPopup(false)}
                status={requirements}
            />
        </View>
    );
};

export default PassProtection;
