import React, { useEffect, useState } from 'react';
import { View, Text, Image, Dimensions, Linking } from 'react-native';
import styles from './styles.web';

import moment from 'moment';
import { useHistory } from 'react-router-dom';

import ModalPopup from '../../../Components/Popup/index.web';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton';
import CategoryBoxButton from '../../../Components/Buttons/CategoryBox';
import InfoBoxButton from '../../../Components/Buttons/InfoBox';
import LoadingState from '../../../Components/LoadingState/index.web';
import PassProtection from '../../../Components/PassProtection/index.web';

import { useAuth } from '../../../Context/AuthContext';
import {
    formatDaysUntilTest,
    formatQuestionsPerDay,
    getCorrectlyAnsweredQuestions,
    getUserTestType,
    notNumbersOrSpecialChars
} from '../../../API/RealmComms.web';
import { accountStatus, getTrialTime, checkPPStatus } from '../../../API/Joomla.web';
import { IOS_STORE_URL, ANDROID_STORE_URL } from '../../../API/Environment';
import { HIGHWAY_CODE_URL, UPGRADE_URL } from '../../../API/Environment.web';

import { useScreenSize } from '../../../Utilities/utils';

const DashboardScreen = () => {
    const [trainingVisible, setTrainingVisible] = useState(false);
    const [trialEndVisible, setTrialEndVisible] = useState(false);
    const [trialVisible, setTrialVisible] = useState(false);
    const [daysUntilTest, setDaysUntilTest] = useState(null);
    const [questionsPerDay, setQuestionsPerDay] = useState(null);
    const [trialLeft, setTrialLeft] = useState();
    const [trialData, setTrialData] = useState({});
    const [loading, setLoading] = useState(false);
    const [trialExceeded, setTrialExceeded] = useState(false);
    const [ppStatus, setPPStatus] = useState();

    const screenSize = useScreenSize();
    const auth = useAuth();

    useEffect(() => {
        const getData = async () => {
            setLoading(true);

            if (localStorage.getItem('dateoftest') !== '0000-00-00 00:00:00') {
                const questionsLeft = await getCorrectlyAnsweredQuestions();
                const dateOfTest = moment(localStorage.getItem('dateoftest'));
                const currentDate = moment();
                const daysUntilTest = dateOfTest.diff(currentDate, 'days');
                const questionsPerDay = daysUntilTest
                    ? (questionsLeft / daysUntilTest).toFixed(0)
                    : null;

                setDaysUntilTest(daysUntilTest);
                setQuestionsPerDay(questionsPerDay);
            }

            const trialData = await accountStatus(localStorage.getItem('username'));
            setTrialData(trialData);

            setPPStatus(
                await checkPPStatus(localStorage.getItem('session'), localStorage.getItem('userid'))
            );

            if (auth.user.trial === 'Yes') {
                if (parseFloat(trialData.hoursremaining) < 0) {
                    setTrialEndVisible(true);
                } else {
                    const timeLeft = await getTrialTime(trialData.hoursremaining);
                    setTrialLeft(timeLeft);
                    setTrialVisible(true);
                }
            }

            if (localStorage.getItem('trainingCompleted') !== 'Yes') {
                if (auth.user.trial === 'Yes') {
                    const mockUserTests = await getUserTestType(null, '1');
                    if (mockUserTests !== null || mockUserTests !== undefined) {
                        if (mockUserTests?.length >= 1) {
                            return;
                        } else {
                            setTrainingVisible(true);
                        }
                    } else {
                        setTrainingVisible(true);
                    }
                    localStorage.setItem('trainingCompleted', 'Yes');
                }
            }

            setLoading(false);
        };
        getData();
    }, []);

    const history = useHistory();

    const PageButtonRow = ({
        firstOnPress,
        firstText,
        firstIcon,
        secondOnPress,
        secondText,
        secondIcon,
        thirdOnPress,
        thirdText,
        thirdIcon,
        webView = false
    }) => {
        return (
            <View style={styles.buttonRowViewsAdditional}>
                <CategoryBoxButton
                    text={firstText}
                    onPress={firstOnPress}
                    icon={firstIcon}
                    customTextSize={webView ? 28 : null}
                />

                <CategoryBoxButton
                    text={secondText}
                    onPress={secondOnPress}
                    icon={secondIcon}
                    customTextSize={webView ? 28 : null}
                />

                <CategoryBoxButton
                    text={thirdText}
                    onPress={thirdOnPress}
                    icon={thirdIcon}
                    customTextSize={webView ? 28 : null}
                />
            </View>
        );
    };

    const PageButtonRowTwo = ({
        firstOnPress,
        firstText,
        firstIcon,
        secondOnPress,
        secondText,
        secondIcon
    }) => {
        return (
            <View
                style={[
                    styles.buttonRowViewsWeb,
                    screenSize.large && { justifyContent: 'center' }
                ]}>
                <CategoryBoxButton text={firstText} onPress={firstOnPress} icon={firstIcon} />

                <CategoryBoxButton text={secondText} onPress={secondOnPress} icon={secondIcon} />
            </View>
        );
    };

    if (loading) {
        return <LoadingState />;
    }

    if (screenSize.large) {
        return (
            <View style={styles.mobileViewContainer}>
                <ModalPopup
                    visible={trialExceeded}
                    setVisible={setTrialExceeded}
                    image={
                        <Image
                            style={styles.popupImage}
                            source={require('../../../../assets/images/Popup/SVG/trial-finished.svg')}
                        />
                    }
                    title={'Trial Exceeded'}
                    secondaryText={'Please upgrade your plan to gain access to this section.'}
                    buttonText={'Choose a plan'}
                    buttonLink={'/choose-a-plan'}
                    skip
                />

                <ModalPopup
                    visible={trainingVisible}
                    setVisible={setTrainingVisible}
                    image={
                        <Image
                            style={styles.largePopupImage}
                            source={require('../../../../assets/images/Popup/PNG/getting-started.png')}
                        />
                    }
                    title={'Getting started'}
                    secondaryText={
                        "Take a short test and we'll build you a bespoke training plan so you can focus your learning on your weakest topics."
                    }
                    buttonText={'Get started'}
                    buttonLink={'/training'}
                    subText={'Short on time? Skip this and create a training plan later.'}
                    skip
                />

                <ModalPopup
                    visible={trialEndVisible}
                    setVisible={setTrialEndVisible}
                    image={
                        <Image
                            style={styles.popupImage}
                            source={require('../../../../assets/images/Popup/PNG/trial-finished.png')}
                        />
                    }
                    title={'Trial Finished'}
                    secondaryText={'Please select a plan to continue your learning.'}
                    buttonText={'Choose a plan'}
                    onPress={() => {
                        window.location.href = `${UPGRADE_URL}${auth.user.urlParams}`;
                    }}
                    skip
                    logoutOnSkip={true}
                />

                <ModalPopup
                    visible={trialVisible}
                    setVisible={setTrialVisible}
                    image={
                        <Image
                            style={styles.popupImage}
                            source={require('../../../../assets/images/Popup/PNG/trial-finished.png')}
                        />
                    }
                    title={'Free Trial'}
                    subText={`You have ` + String(trialLeft) + ` of your free trial left.`}
                    buttonText={'Choose a plan'}
                    onPress={() => {
                        window.location.href = `${UPGRADE_URL}${auth.user.urlParams}`;
                    }}
                    skip
                />
                <View
                    style={{
                        width: '80%',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        marginTop: 50,
                        flexDirection: 'column',
                        paddingVertical: 20,
                        borderRadius: 50
                    }}>
                    <Text
                        style={{
                            fontSize: Dimensions.get('window').height / 70,
                            textAlign: 'center',
                            color: 'black',
                            marginBottom: 25
                        }}>
                        On your mobile? If so, download our app now!
                    </Text>

                    <PrimaryButton
                        text={'Download For iOS'}
                        onPress={() => Linking.openURL(IOS_STORE_URL)}
                        textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                        style={{ width: '75%', marginVertical: 10 }}
                    />

                    <PrimaryButton
                        text={'Download For Android'}
                        onPress={() => Linking.openURL(ANDROID_STORE_URL)}
                        textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                        style={{ width: '75%', marginVertical: 10 }}
                    />
                </View>

                <View style={{ width: '100%', alignItems: 'center', marginTop: 50 }}>
                    <InfoBoxButton
                        text={'Based on your results so far.'}
                        info={formatQuestionsPerDay(questionsPerDay)}
                        icon={
                            <Image
                                style={styles.mobileDashboardIcon}
                                source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/icon-test.png')}
                            />
                        }
                    />

                    <InfoBoxButton
                        text={'Get the most from our practise tools.'}
                        info={formatDaysUntilTest(daysUntilTest)}
                        icon={
                            <Image
                                style={styles.mobileDashboardIcon}
                                source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/icon-test.png')}
                            />
                        }
                    />

                    {auth.user.testtype !== '2' && (
                        <PassProtection style={{ width: '80%', marginTop: 60 }} status={ppStatus} />
                    )}

                    <PrimaryButton
                        text={
                            daysUntilTest == null || daysUntilTest < 0
                                ? `Start Training Plan`
                                : `Redo training plan`
                        }
                        onPress={async () => {
                            if (auth.user.trial === 'Yes') {
                                const mockUserTests = await getUserTestType(null, '1');
                                if (mockUserTests !== null || mockUserTests !== undefined) {
                                    if (mockUserTests?.length >= 1) {
                                        setTrialExceeded(true);
                                    } else {
                                        setTrainingVisible(true);
                                    }
                                } else {
                                    setTrainingVisible(true);
                                }
                            } else {
                                setTrainingVisible(true);
                            }
                        }}
                        textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                        style={{ width: '75%', marginVertical: 20 }}
                    />

                    <View
                        style={[
                            styles.buttonRowViewsWeb,
                            { justifyContent: 'center', marginVertical: 20 }
                        ]}>
                        <Text style={styles.pageTitle}>
                            {notNumbersOrSpecialChars(localStorage.getItem('firstname'))
                                ? `Welcome back, ${localStorage.getItem('firstname')}!`
                                : `Welcome back!`}
                        </Text>
                    </View>

                    <Text style={[styles.pageTitle, { marginBottom: 20 }]}> Revision tools</Text>

                    <PageButtonRowTwo
                        firstText={'Practice Zone'}
                        secondText={'Mock Test'}
                        firstOnPress={() => history.push('/practice-zone')}
                        secondOnPress={() => history.push('/mock-test')}
                        firstIcon={
                            <Image
                                style={styles.mobileDashboardIcon}
                                source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/practice-zone-icon.png')}
                            />
                        }
                        secondIcon={
                            <Image
                                style={styles.mobileDashboardIcon}
                                source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/mock-test-icon.png')}
                            />
                        }
                        webView={true}
                    />

                    <PageButtonRowTwo
                        firstText={'Quick Test'}
                        secondText={'Hazard Perception'}
                        firstOnPress={() => history.push('/quick-test-dashboard')}
                        secondOnPress={() => history.push('/hazard-perception-dashboard')}
                        firstIcon={
                            <Image
                                style={styles.mobileDashboardIcon}
                                source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/quick-test-icon.png')}
                            />
                        }
                        secondIcon={
                            <Image
                                style={styles.mobileDashboardIcon}
                                source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/hazard-perception-icon.png')}
                            />
                        }
                        webView={true}
                    />

                    <PageButtonRowTwo
                        firstText={'Road Signs'}
                        secondText={'Highway Code'}
                        firstOnPress={() => history.push('/roadsign-dashboard')}
                        secondOnPress={() => Linking.openURL(HIGHWAY_CODE_URL)}
                        firstIcon={
                            <Image
                                style={styles.mobileDashboardIcon}
                                source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/road-signs-icon.png')}
                            />
                        }
                        secondIcon={
                            <Image
                                style={styles.mobileDashboardIcon}
                                source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/highway-code-icon.png')}
                            />
                        }
                        webView={true}
                    />

                    {auth.user.testtype !== '2' && (
                        <View style={[styles.buttonRowViewsWeb, { justifyContent: 'center' }]}>
                            <CategoryBoxButton
                                isNew={true}
                                text={'Driving Lessons'}
                                onPress={() => history.push('/learn-how-to-dashboard')}
                                icon={
                                    <Image
                                        style={styles.mobileDashboardIcon}
                                        source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/video-icon.png')}
                                    />
                                }
                                webView={true}
                            />
                        </View>
                    )}

                    <PrimaryButton
                        text={`Go to Progress Zone`}
                        onPress={() => {
                            history.push('/progress');
                        }}
                        textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                        style={{ width: '75%', marginVertical: 35 }}
                    />
                </View>
            </View>
        );
    } else {
        return (
            <View style={styles.container}>
                <ModalPopup
                    visible={trialExceeded}
                    setVisible={setTrialExceeded}
                    image={
                        <Image
                            style={styles.popupImage}
                            source={require('../../../../assets/images/Popup/SVG/trial-finished.svg')}
                        />
                    }
                    title={'Trial Exceeded'}
                    secondaryText={'Please upgrade your plan to gain access to this section.'}
                    buttonText={'Choose a plan'}
                    buttonLink={'/choose-a-plan'}
                    skip
                />
                <ModalPopup
                    visible={trainingVisible}
                    setVisible={setTrainingVisible}
                    image={
                        <Image
                            style={styles.largePopupImage}
                            source={require('../../../../assets/images/Popup/PNG/getting-started.png')}
                        />
                    }
                    title={'Getting started'}
                    secondaryText={
                        "Take a short test and we'll build you a bespoke training plan so you can focus your learning on your weakest topics."
                    }
                    buttonText={'Get started'}
                    buttonLink={'/training'}
                    subText={'Short on time? Skip this and create a training plan later.'}
                    skip
                />

                <ModalPopup
                    visible={trialEndVisible}
                    setVisible={setTrialEndVisible}
                    image={
                        <Image
                            style={styles.popupImage}
                            source={require('../../../../assets/images/Popup/PNG/trial-finished.png')}
                        />
                    }
                    title={'Trial Finished'}
                    secondaryText={'Please select a plan to continue your learning.'}
                    buttonText={'Choose a plan'}
                    onPress={() => {
                        window.location.href = `${UPGRADE_URL}${auth.user.urlParams}`;
                    }}
                    skip
                    logoutOnSkip={true}
                />

                <ModalPopup
                    visible={trialVisible}
                    setVisible={setTrialVisible}
                    image={
                        <Image
                            style={styles.popupImage}
                            source={require('../../../../assets/images/Popup/PNG/trial-finished.png')}
                        />
                    }
                    title={'Free Trial'}
                    subText={`You have ` + String(trialLeft) + ` of your free trial left.`}
                    buttonText={'Choose a plan'}
                    onPress={() => {
                        window.location.href = `${UPGRADE_URL}${auth.user.urlParams}`;
                    }}
                    skip
                />

                <View style={styles.pageContainerWeb}>
                    <View style={{ width: '55%', marginRight: 30, marginLeft: 50 }}>
                        <View style={styles.buttonRowViewsWeb}>
                            <Text style={styles.pageTitle}>
                                {notNumbersOrSpecialChars(localStorage.getItem('firstname'))
                                    ? `Welcome back, ${localStorage.getItem('firstname')}!`
                                    : `Welcome back!`}
                            </Text>
                        </View>

                        <Text style={styles.pageTitle}> Revision tools</Text>

                        <PageButtonRow
                            firstText={'Practice Zone'}
                            secondText={'Road Signs'}
                            thirdText={'Highway Code'}
                            firstOnPress={() => history.push('/practice-zone')}
                            secondOnPress={() => history.push('/roadsign-dashboard')}
                            thirdOnPress={() => Linking.openURL(HIGHWAY_CODE_URL)}
                            firstIcon={
                                <Image
                                    style={styles.mobileDashboardIcon}
                                    source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/practice-zone-icon.png')}
                                />
                            }
                            secondIcon={
                                <Image
                                    style={styles.mobileDashboardIcon}
                                    source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/road-signs-icon.png')}
                                />
                            }
                            thirdIcon={
                                <Image
                                    style={styles.mobileDashboardIcon}
                                    source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/highway-code-icon.png')}
                                />
                            }
                        />

                        <PageButtonRow
                            firstText={'Hazard Perception'}
                            secondText={'Mock Test'}
                            thirdText={'Quick Test'}
                            firstOnPress={() => history.push('/hazard-perception-dashboard')}
                            secondOnPress={() => history.push('/mock-test')}
                            thirdOnPress={() => history.push('/quick-test-dashboard')}
                            firstIcon={
                                <Image
                                    style={styles.mobileDashboardIcon}
                                    source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/hazard-perception-icon.png')}
                                />
                            }
                            secondIcon={
                                <Image
                                    style={styles.mobileDashboardIcon}
                                    source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/mock-test-icon.png')}
                                />
                            }
                            thirdIcon={
                                <Image
                                    style={styles.mobileDashboardIcon}
                                    source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/quick-test-icon.png')}
                                />
                            }
                        />

                        {auth.user.testtype !== '2' && (
                            <View style={styles.buttonRowViewsAdditional}>
                                <CategoryBoxButton
                                    isNew={true}
                                    text={'Driving Lessons'}
                                    onPress={() => history.push('/learn-how-to-dashboard')}
                                    icon={
                                        <Image
                                            style={styles.mobileDashboardIcon}
                                            source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/video-icon.png')}
                                        />
                                    }
                                />
                            </View>
                        )}
                    </View>

                    <View style={{ width: '40%', marginTop: 60 }}>
                        {auth.user.testtype !== '2' && (
                            <PassProtection
                                style={{ width: '80%', marginTop: 0 }}
                                status={ppStatus}
                            />
                        )}
                        <InfoBoxButton
                            text={'Based on your results so far.'}
                            info={formatQuestionsPerDay(questionsPerDay)}
                            icon={
                                <Image
                                    style={{ width: 40, height: 40, resizeMode: 'contain' }}
                                    source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/icon-test.png')}
                                />
                            }
                        />

                        <InfoBoxButton
                            text={'Get the most from our practise tools.'}
                            info={formatDaysUntilTest(daysUntilTest)}
                            icon={
                                <Image
                                    style={{ width: 40, height: 40, resizeMode: 'contain' }}
                                    source={require('../../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/icon-test.png')}
                                />
                            }
                        />

                        <PrimaryButton
                            text={
                                daysUntilTest == null || daysUntilTest < 0
                                    ? `Start Training Plan`
                                    : `Redo training plan`
                            }
                            textStyle={{ fontSize: 12 }}
                            onPress={async () => {
                                if (auth.user.trial === 'Yes') {
                                    if (mockUserTests != null || mockUserTests != undefined) {
                                        if (mockUserTests?.length > 0) {
                                            setTrialExceeded(true);
                                        } else {
                                            setTrainingVisible(true);
                                        }
                                    } else {
                                        setTrainingVisible(true);
                                    }
                                } else {
                                    setTrainingVisible(true);
                                }
                            }}
                            style={{
                                marginTop: 15,
                                backgroundColor: '#0D0334',
                                width: 'fit-content'
                            }}
                        />
                    </View>
                </View>
            </View>
        );
    }
};

export default DashboardScreen;
