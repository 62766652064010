import React from 'react';
import { Text, TouchableHighlight, View } from 'react-native';
import styles from './styles';

const CategoryBoxButton = ({ text, info, icon }) => {
    return (
        <View style={styles.buttonWebView}>
            <TouchableHighlight underlayColor="#cac7c6" style={styles.buttonStyle}>
                <View style={styles.buttonInnerView}>
                    <View>{icon}</View>

                    <View style={styles.textContainer}>
                        <View>
                            <Text style={styles.text}>{text}</Text>
                        </View>

                        <View>
                            <Text style={styles.info}>{info}</Text>
                        </View>
                    </View>
                </View>
            </TouchableHighlight>
        </View>
    );
};

export default CategoryBoxButton;
