import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    modalBackGround: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },

    hintInfoContainer: {
        minWidth: '75%',
        backgroundColor: '#F0040F',
        paddingHorizontal: 20,
        paddingVertical: 15,
        marginHorizontal: 50,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        elevation: 20
    },

    modalContainer: {
        backgroundColor: 'white',
        paddingHorizontal: 20,
        marginHorizontal: 50,
        paddingBottom: 25,
        paddingTop: 10,
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        elevation: 20
    },

    title: {
        marginVertical: 5,
        fontSize: Dimensions.get('window').height / 50,
        textAlign: 'center',
        color: '#0D0334',
        fontWeight: 'bold',
        textAlign: 'left',
        paddingLeft: 10
    },

    secondaryText: {
        marginVertical: 5,
        fontSize: Dimensions.get('window').height / 60,
        textAlign: 'left',
        color: '#868199',
        paddingBottom: 20
    },

    testButtonTts: {
        resizeMode: 'contain',
        width: 25,
        height: 25,
        marginLeft: 10
    }
});

export default styles;
