import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import LoadingState from '../../../Components/LoadingState';
import HazardQuestionParent from '../../../Components/HazardPerception/HazardQuestionParent/index.web';
import HazardResults from '../../../Components/HazardPerception/HazardResults/index.web';

import { fetchHazardClips } from '../../../API/RealmComms.web';

const MockTest = () => {
    const [questions, setQuestions] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [mockTestStage, setMockTestStage] = useState(1);
    const [questionFeedbackBreakdown, setQuestionFeedbackBreakdown] = useState({});
    const [questionsTracker, setQuestionsTracker] = useState([]);
    const [testScore, setTestScore] = useState(0);

    const location = useLocation();

    useEffect(() => {
        (async () => {
            setLoading(true);
            const isHgv = localStorage.getItem('testtype') === '2';
            if (location.hazard) {
                setQuestions([location.hazard]);
            } else {
                const hazards = await fetchHazardClips(isHgv ? 20 : 15);
                setQuestions(hazards);
            }
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <LoadingState />;
    } else if (mockTestStage == 1) {
        return (
            <HazardQuestionParent
                questions={questions}
                setMockTestStage={setMockTestStage}
                questionFeedbackBreakdown={questionFeedbackBreakdown}
                setQuestionFeedbackBreakdown={setQuestionFeedbackBreakdown}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                testScore={testScore}
                setTestScore={setTestScore}
                questionsTracker={questionsTracker}
                setQuestionsTracker={setQuestionsTracker}
            />
        );
    } else if (mockTestStage == 2) {
        return (
            <HazardResults
                questionFeedbackBreakdown={questionFeedbackBreakdown}
                testScore={testScore}
                maxScore={questions.length * 5}
                questionsTracker={questionsTracker}
            />
        );
    }
};

export default MockTest;
