import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    headingText: {
        paddingBottom: 33,
        fontWeight: 'bold',
        fontSize: Dimensions.get('window').height / 60,
        color: '#0D0334',
        alignSelf: 'flex-start'
    },

    navigationButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 10,
        borderTopColor: '#0D03341A',
        borderTopWidth: 1,
        paddingBottom: 15,
        backgroundColor: 'white'
    },

    headingQuestion: {
        paddingBottom: 33,
        fontWeight: 'bold',
        fontSize: Dimensions.get('window').height / 60,
        alignSelf: 'center',
        color: '#0D0334'
    },

    pageContainer: {
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        alignItems: 'center',
        padding: 36,
        paddingTop: 0,
        flex: 1
    },

    nextButton: {
        backgroundColor: '#8CC63F',
        borderRadius: 50,
        marginRight: 25,
        alignItems: 'center',
        width: '40%'
    },

    nextButtonText: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#F6F9FC',
        margin: 15
    },

    previousButton: {
        backgroundColor: '#F3F2F5',
        borderRadius: 50,
        marginLeft: 25,
        alignItems: 'center',
        width: '40%'
    },

    previousButtonText: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#868199',
        margin: 15
    },

    hintButton: {
        backgroundColor: '#FCB040',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },

    hintText: {
        color: '#fff',
        marginLeft: 5
    }
});

export default styles;
