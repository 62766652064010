import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#F3F2F5',
        height: '100%',
        paddingHorizontal: 28
    },

    title: {
        fontSize: Dimensions.get('window').height / 60,
        fontWeight: 'bold',
        color: '#0D0334',
        marginVertical: 20
    },

    finishButtonView: {
        backgroundColor: '#F3F2F5',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 32,
        paddingBottom: 10
    },

    finishButton: {
        backgroundColor: '#8CC63F',
        borderRadius: 50,
        width: '45%',
        alignItems: 'center'
    },

    finishButtonItems: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 15,
        paddingHorizontal: 25
    },

    finishButtonText: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#fff',
        marginLeft: 5
    },

    questionButtonStyle: {
        backgroundColor: '#fff',
        marginBottom: 12,
        borderRadius: 10,
        padding: 15
    },

    questionButtonItems: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    questionNumber: {
        color: '#0D0334',
        fontWeight: 'bold',
        fontSize: Dimensions.get('window').height / 60,
        marginRight: 10
    },

    questionTitle: {
        width: '90%',
        fontSize: Dimensions.get('window').height / 60
    }
});

export default styles;
