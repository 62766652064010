import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useLocation } from 'react-router-dom';
import { fetchCategories } from '../../../API/Joomla.web';
import {
    fetchPreviouslyWrongQuestions,
    fetchQuestionsForCategories,
    fetchUnseenQuestions
} from '../../../API/RealmComms.web';

import QuestionParent from '../../../Components/QuickTest/Test/QuestionParent';
import Results from '../../../Components/ResultTemplates/ResultsPage';
import Loading from '../../../Components/LoadingState';
import ModalPopup from '../../../Components/Popup';

const QuickTest = () => {
    const [quickTestStage, setQuickTestStage] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [userScore, setUserScore] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [questionFeedbackBreakdown, setQuestionsFeedbackBreakdown] = useState({});
    const [loading, setLoading] = useState(true);
    const [questionsTracker, setQuestionsTracker] = useState([]);
    const [categories, setCategories] = useState();
    const [noQuestionsPopup, setNoQuestionsPopup] = useState();

    const location = useLocation();
    const categoryIds = location.categories;
    const categoryList = location.categoryList;
    const noOfQuestions = location.noOfQuestions;
    const questionType = location.questionType;

    useEffect(() => {
        (async () => {
            setLoading(true);
            const res = await fetchQuestionsForCategories(categoryIds);
            const categories = await fetchCategories();
            var questions = [];
            if (questionType === 1) {
                for (var i = 0; i < res.length; i++) {
                    if (questions.length === noOfQuestions) {
                        break;
                    }
                    questions.push(res[i]);
                }
            } else if (questionType === 2) {
                const previouslyWrongQuestions = await fetchPreviouslyWrongQuestions(res);
                for (var x = 0; x < previouslyWrongQuestions.length; x++) {
                    if (questions.length === noOfQuestions) {
                        break;
                    }
                    questions.push(previouslyWrongQuestions[x]);
                }
            } else {
                const unseenQuestions = await fetchUnseenQuestions(res);
                for (var x = 0; x < unseenQuestions.length; x++) {
                    if (questions.length === noOfQuestions) {
                        break;
                    }
                    questions.push(unseenQuestions[x]);
                }
            }

            setQuestions(questions);
            setCategories(categories);
            setQuickTestStage(1);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <Loading />;
    } else if (questions.length === 0) {
        return (
            <View style={{ flex: 1, backgroundColor: 'white' }}>
                <ModalPopup
                    visible={true}
                    setVisible={setNoQuestionsPopup}
                    title={'No questions found'}
                    secondaryText={
                        'There were no questions found within those parameters. Please select different options.'
                    }
                    buttonText={'Back to Quick Test'}
                    buttonLink={'/quick-test-dashboard'}
                />
            </View>
        );
    } else if (quickTestStage == 1) {
        return (
            <QuestionParent
                setQuickTestStage={setQuickTestStage}
                totalQuestions={questions.length}
                questions={questions}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                userScore={userScore}
                setUserScore={setUserScore}
                questionFeedbackBreakdown={questionFeedbackBreakdown}
                setQuestionsFeedbackBreakdown={setQuestionsFeedbackBreakdown}
                questionsTracker={questionsTracker}
                setQuestionsTracker={setQuestionsTracker}
            />
        );
    } else if (quickTestStage == 2) {
        return (
            <Results
                userScore={userScore}
                totalQuestions={questions.length}
                questionFeedbackBreakdown={questionFeedbackBreakdown}
                categoryList={categoryList}
                categories={categories}
                questionsTracker={questionsTracker}
            />
        );
    }
};

export default QuickTest;
