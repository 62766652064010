import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        margin: 'auto',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50
    },

    sectionTitle: {
        fontSize: Dimensions.get('window').height / 30,
        fontWeight: 'bold',
        color: 'black'
    },

    sectionInfo: {
        padding: 16,
        fontSize: Dimensions.get('window').height / 60
    },

    version: {
        textAlign: 'right',
        margin: 10,
        fontSize: Dimensions.get('window').height / 70
    }
});

export default styles;
