import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({
    feedbackView: {
        backgroundColor: '#F3F2F5',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        padding: 36,
        paddingTop: Platform.OS === 'ios' ? 50 : 36
    },

    feedbackViewWeb: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        padding: 36
    },

    selectionButtonTextSelected: {
        color: 'white'
    },

    feedbackSectionButton: {
        width: '100%',
        backgroundColor: 'white',
        marginBottom: 5,
        padding: 15,
        borderRadius: 10
    },

    feedbackSectionView: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    feedbackSectionText: {
        fontSize: 16,
        color: '#0D0334',
        fontWeight: 'bold'
    },

    dialTextDetails: {
        textAlign: 'center',
        color: '#868199',
        marginHorizontal: 50,
        marginBottom: 30
    },

    scoreResult: {
        fontWeight: 'bold',
        fontSize: 50,
        color: '#0D0334',
        alignSelf: 'center'
    },

    scoreResultWeb: {
        fontWeight: 'bold',
        fontSize: 50,
        color: '#0D0334',
        alignSelf: 'center',
        marginBottom: 20
    },

    scorePassFail: {
        alignSelf: 'center',
        fontSize: 18,
        fontWeight: 'bold'
    }
});

export default styles;
