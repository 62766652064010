import * as React from 'react';
import { View, Text, TouchableHighlight, Image } from 'react-native';
import styles from './styles.web';
import { GET_ONLINE_IMAGE_PATH } from '../../../API/Environment.web';

const QuestionRow = ({ text, selected, onPress, disabled, correctAnswer, index }) => {
    return (
        <View style={styles.buttonView}>
            <TouchableHighlight
                style={[styles.buttonWeb, selected && styles.selectedButton]}
                onPress={onPress}
                underlayColor="#cac7c6"
                disabled={!!disabled}>
                <>
                    <Text style={[styles.buttonText, selected && styles.selectedText]}>{text}</Text>
                </>
            </TouchableHighlight>
        </View>
    );
};

const ImageTitleQuestion = ({
    image,
    questionOne,
    questionTwo,
    questionThree,
    questionFour,
    correctAnswer,
    index,
    setIndex
}) => {
    return (
        <View style={{ width: '100%' }}>
            <View style={styles.imageView}>
                <Image
                    style={styles.image}
                    source={{ uri: `${GET_ONLINE_IMAGE_PATH}${image}` }}></Image>
            </View>

            <QuestionRow
                text={questionOne}
                onPress={() => setIndex(0)}
                selected={index == 0}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionTwo}
                onPress={() => setIndex(1)}
                selected={index == 1}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionThree}
                onPress={() => setIndex(2)}
                selected={index == 2}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionFour}
                onPress={() => setIndex(3)}
                selected={index == 3}
                correctAnswer={correctAnswer}
                index={index}
            />
        </View>
    );
};

export default ImageTitleQuestion;
