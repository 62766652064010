import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    headerBackground: {
        backgroundColor: '#fff'
    },

    score: {
        fontWeight: 'bold',
        color: '#0D0334',
        fontSize: Dimensions.get('window').height / 40,
        textAlign: 'center'
    },

    scoreFeedback: {
        fontWeight: 'bold',
        color: 'green',
        fontSize: Dimensions.get('window').height / 60,
        textAlign: 'center',
        marginBottom: 15
    },

    feedbackView: {
        flex: 1
    },

    feedbackViewWeb: {
        flex: 1
    },

    buttonView: {
        width: '85%',
        marginTop: 40
    },

    buttonViewWeb: {
        justifyContent: 'center',
        alignItems: 'center'
    },

    hazardButton: {
        backgroundColor: '#fff',
        marginTop: 10,
        marginHorizontal: 28,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: 'black'
    },

    hazardButtonView: {
        padding: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    hazardButtonText: {
        fontSize: Dimensions.get('window').height / 60,
        fontWeight: 'bold',
        color: '#0D0334'
    },

    hazardButtonScore: {
        fontSize: Dimensions.get('window').height / 60,
        fontWeight: 'bold',
        color: '#0D0334',
        marginRight: 15,
        borderWidth: 2,
        borderRadius: 20,
        padding: 6,
        textAlign: 'center'
    },

    hazardButtonInnerView: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    green: {
        borderColor: '#99C455'
    },

    amber: {
        borderColor: '#FCB040'
    },

    red: {
        borderColor: '#F0040F'
    },

    pass: {
        color: '#99C455'
    },

    fail: {
        color: '#F0040F'
    }
});

export default styles;
