import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, FlatList, Dimensions } from 'react-native';
import styles from './styles';

import { useLocation } from 'react-router-dom';

import FlipCard from '../../../Components/CardFlip/index.web';
import StaticCard from '../../../Components/CardStatic/index.web';
import SelectionButton from '../../../Components/Buttons/SelectionButton';
import LoadingState from '../../../Components/LoadingState';

import { fetchRoadsignsForCategories } from '../../../API/RealmComms.web';
import { arrayRows } from '../../../API/Joomla.web';
import { useScreenSize } from '../../../Utilities/utils';

const RoadSigns = () => {
    const screenSize = useScreenSize();

    const location = useLocation();

    const id = location.id;

    const [isCardView, setIsCardView] = useState(true);
    const [loading, setLoading] = useState(true);
    const [roadsigns, setRoadsigns] = useState(null);

    useEffect(() => {
        (async () => {
            const res = await fetchRoadsignsForCategories(id);
            setRoadsigns(res);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <LoadingState />;
    } else if (isCardView) {
        return (
            <View style={{ width: screenSize.large ? '100%' : '80%' }}>
                <ScrollView contentContainerStyle={styles.containerWeb}>
                    <View
                        style={{
                            flexDirection: 'row',
                            width: '100%',
                            height: 75,
                            marginTop: 5
                        }}>
                        <SelectionButton
                            text="Card View"
                            onPress={() => setIsCardView(true)}
                            selected={isCardView}
                            style={{ width: '48%', marginRight: 10 }}
                            textStyle={{ fontSize: Dimensions.get('window').height / 70 }}
                        />

                        <SelectionButton
                            text="List View"
                            onPress={() => setIsCardView(false)}
                            selected={!isCardView}
                            style={{ width: '48%', backgroundColor: 'white' }}
                            textStyle={{ fontSize: Dimensions.get('window').height / 70 }}
                        />
                    </View>

                    <Text
                        style={{
                            color: '#868199',
                            fontSize: Dimensions.get('window').height / 70,
                            marginBottom: 20
                        }}>
                        {' '}
                        Tap to reveal the answer{' '}
                    </Text>

                    {arrayRows(roadsigns, 2).map((slice) => {
                        return (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    width: '100%',
                                    height: 150,
                                    marginBottom: 65
                                }}>
                                {slice.map((roadsign) => {
                                    return (
                                        <FlipCard
                                            image={roadsign.image}
                                            heading={roadsign.name}
                                            text={roadsign.description}
                                        />
                                    );
                                })}
                            </View>
                        );
                    })}
                </ScrollView>
            </View>
        );
    }

    return (
        <View style={{ width: screenSize.large ? '100%' : '80%' }}>
            <ScrollView contentContainerStyle={styles.containerWeb}>
                <View
                    style={{
                        flexDirection: 'row',
                        width: '100%',
                        height: 75,
                        marginTop: 5
                    }}>
                    <SelectionButton
                        text="Card View"
                        onPress={() => setIsCardView(true)}
                        selected={isCardView}
                        style={{ width: '48%', marginRight: 10, backgroundColor: 'white' }}
                        textStyle={{ fontSize: Dimensions.get('window').height / 70 }}
                    />

                    <SelectionButton
                        text="List View"
                        onPress={() => setIsCardView(false)}
                        selected={!isCardView}
                        style={{ width: '48%' }}
                        textStyle={{ fontSize: Dimensions.get('window').height / 70 }}
                    />
                </View>

                <FlatList
                    style={{ width: '90%' }}
                    data={roadsigns}
                    keyExtractor={(item) => item.id}
                    renderItem={({ item }) => (
                        <StaticCard
                            image={item.image}
                            heading={item.name}
                            text={item.description}
                        />
                    )}
                />
            </ScrollView>
        </View>
    );
};

export default RoadSigns;
