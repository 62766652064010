import { StyleSheet, Platform, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    pageTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'black',
        padding: 30,
        textAlign: 'center'
    },

    containerWeb: {
        height: '100vh',
        width: '100vw',
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 36,
        paddingTop: Platform.OS === 'ios' ? 50 : 36,
        flex: 1,
        margin: 'auto'
    },

    dateFieldView: {
        width: '100%',
        flexDirection: 'row',
        borderRadius: 0,
        justifyContent: 'center',
        marginBottom: 15
    },

    input: {
        width: '80%',
        backgroundColor: '#fff'
    },

    dateButton: {
        width: '20%',
        backgroundColor: '#0D0334',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 5,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
        marginLeft: -2
    },

    testDateText: {
        marginBottom: 15,
        fontSize: Dimensions.get('window').height / 70
    },

    headingText: {
        fontSize: Dimensions.get('window').height / 70,
        marginBottom: 15
    }
});

export default styles;
