import React, { useEffect, useState } from 'react';
import { View, Image, Pressable, Platform } from 'react-native';
import Video from 'react-player';
import { useHistory, useLocation } from 'react-router-dom';
import { HAZARD_PERCEPTION_VIDEO } from '../../../API/Environment.web';

const HazardPerceptionQuestion = ({}) => {
    const [videoURL, setVideoURL] = useState();
    const [muted, setMuted] = useState(Platform.OS === 'ios');

    const history = useHistory();
    const location = useLocation();

    const hazard = location.hazard;

    useEffect(() => {
        const videoUrl = `${HAZARD_PERCEPTION_VIDEO}${hazard.videoref}.mp4`;
        setVideoURL(videoUrl);
    }, []);

    return (
        <>
            <View style={{ width: '80%', height: '80%', backgroundColor: 'black' }}>
                <Pressable
                    onPress={() =>
                        history.goBack({
                            heading: location?.heading
                        })
                    }
                    style={{
                        position: 'absolute',
                        alignSelf: 'flex-end',
                        right: 10,
                        top: 10,
                        zIndex: 100
                    }}>
                    <Image
                        style={{ width: 50, height: 50 }}
                        source={require('../../../../assets/images/HazardPerception/SVG/icon-video-close.svg')}
                    />
                </Pressable>
                <Pressable onPress={() => setMuted(false)}>
                    <View style={{ width: '100%', height: '100%' }}>
                        <Video
                            url={videoURL}
                            onEnded={() => history.goBack()}
                            muted={muted ? true : false}
                            resizeMode="cover"
                            playing={true}
                            playsinline={true}
                            width="100%"
                            height="100%"
                        />
                    </View>
                </Pressable>
            </View>
        </>
    );
};

export default HazardPerceptionQuestion;
