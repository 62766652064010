import React from 'react';
import { View, ImageBackground, Text, TouchableHighlight, Image } from 'react-native';
import styles from './styles';

import { useHistory } from 'react-router-dom';

import Sidebar from '../Sidebar/index.web';

import { useScreenSize } from '../../Utilities/utils';
import { logout } from '../../API/Joomla.web';

const Parent = ({ screen, name }) => {
    const Name = screen;
    const history = useHistory();
    const screenSize = useScreenSize();

    return (
        <View style={{ minHeight: '100vh', width: '100%', flex: 1 }}>
            <ImageBackground
                source={require('../../../assets/images/Header/PNG/header.png')}
                resizeMode="stretch"
                style={styles.headerBackgroundImageWeb}>
                <View style={styles.buttonRowViews}>
                    <Text style={[styles.dashboardTitle, screenSize.large && { fontSize: 26 }]}>
                        {String(name)}
                    </Text>

                    <View style={styles.buttonRightView}>
                        <TouchableHighlight
                            onPress={() => history.push('/profile')}
                            underlayColor="#cac7c6"
                            style={styles.dashboardHeaderProfileButton}>
                            <Image
                                style={{ width: 50, height: 50, resizeMode: 'contain' }}
                                source={require('../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/profile-icon.png')}
                            />
                        </TouchableHighlight>

                        <TouchableHighlight
                            onPress={async () => {
                                await logout(localStorage.getItem('session'));
                                history.push('/login');
                            }}
                            underlayColor="#cac7c6"
                            style={styles.dashboardHeaderProfileButton}>
                            <Image
                                style={{ width: 50, height: 50, resizeMode: 'contain' }}
                                source={require('../../../assets/images/Login-Signup-Dashboard/Dashboard/PNG/icon-logout.png')}
                            />
                        </TouchableHighlight>
                    </View>
                </View>
            </ImageBackground>

            <View style={{ flex: 1, flexDirection: 'row' }}>
                <Sidebar />

                <Name />
            </View>

            <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                <ImageBackground
                    source={require('../../../assets/images/Footer/footer.png')}
                    style={{
                        height: 150,
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}></ImageBackground>
            </View>
        </View>
    );
};

export default Parent;
