import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 36,
        paddingTop: 36,
        flex: 1,
        margin: 'auto',
        height: '100vh',
        width: '100vw'
    },

    trialFinished: {
        width: 150,
        height: 150,
        resizeMode: 'contain'
    },

    logoImage: {
        width: '25vw',
        height: '25vh',
        marginBottom: 10,
        resizeMode: 'contain'
    },

    pageTitle: {
        fontSize: Dimensions.get('window').height / 30,
        fontWeight: 'bold',
        color: 'black',
        paddingTop: 40
    },

    text: {
        textAlign: 'center',
        padding: 15,
        fontSize: Dimensions.get('window').height / 70
    },

    modalBackGround: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center'
    },

    modalContainer: {
        width: '80%',
        backgroundColor: 'white',
        paddingHorizontal: 20,
        paddingVertical: 30,
        borderRadius: 20,
        elevation: 20
    },

    secondaryButton: {
        marginTop: 20,
        padding: 10,
        borderRadius: 10
    },

    secondaryButtonText: {
        fontSize: Dimensions.get('window').height / 70,
        color: '#0D0334'
    },

    resetEmailText: {
        marginVertical: 5,
        fontSize: 14,
        textAlign: 'center',
        color: '#868199',
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 20
    },

    startingLineText: {
        marginVertical: 5,
        fontSize: 34,
        textAlign: 'center',
        color: '#0D0334',
        fontWeight: 'bold'
    },

    errorMessage: {
        marginVertical: 5,
        fontSize: 14,
        textAlign: 'center',
        color: '#868199',
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 20
    },

    oops: {
        marginVertical: 5,
        fontSize: 34,
        textAlign: 'center',
        color: '#0D0334',
        fontWeight: 'bold'
    }
});

export default styles;
