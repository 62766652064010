import React from 'react';
import { TextInput } from 'react-native-paper';
import styles from './styles';

const PrimaryTextInput = (props) => {
    return (
        <TextInput
            label={props.label}
            style={[styles.input, props.customStyle]}
            mode="outlined"
            theme={{ colors: { primary: 'grey' } }}
            value={props.value}
            placeholder={props.placeholder}
            onChangeText={props.onChangeText}
            secureTextEntry={props.secureTextEntry === true}
        />
    );
};

export default PrimaryTextInput;
