import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    testButtonView: {
        width: '100%',
        marginBottom: 15,
        flexDirection: 'row'
    },

    testButton: {
        backgroundColor: '#F3F2F5',
        borderRadius: 50,
        marginRight: 10,
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        height: 'auto'
    },

    testButtonWeb: {
        backgroundColor: '#F3F2F5',
        borderRadius: 50,
        marginRight: 10,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'auto'
    },

    testButtonText: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#868199',
        margin: 15
    },

    testButtonTts: {
        resizeMode: 'contain',
        width: 50,
        height: 50
    },

    selectedButton: {
        backgroundColor: '#0D0334'
    },

    selectedText: {
        color: 'white'
    }
});

export default styles;
