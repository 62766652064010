import React from 'react';
import { Text, TouchableHighlight } from 'react-native';
import styles from './styles';

const PrimaryButton = ({ text, onPress, style, textStyle, disabled, noOfLines }) => {
    return (
        <TouchableHighlight
            style={[!!disabled ? styles.disabled : styles.primaryButton, style]}
            onPress={onPress}
            underlayColor="#cac7c6"
            disabled={!!disabled}>
            <Text style={[styles.primaryButtonText, textStyle]} numberOfLines={noOfLines || 10}>
                {' '}
                {text}{' '}
            </Text>
        </TouchableHighlight>
    );
};

export default PrimaryButton;
