import React from 'react';
import { Text, TouchableHighlight, View } from 'react-native';
import styles from './styles';

const SelectionButton = ({
    text,
    subText,
    onPress,
    selected,
    style,
    textStyle,
    hint,
    hintStyle
}) => {
    return (
        <TouchableHighlight
            style={[styles.selectionButton, selected && styles.buttonSelected, style]}
            onPress={onPress}
            underlayColor="#cac7c6">
            <View>
                {hint && (
                    <View style={styles.hintView}>
                        <Text style={[styles.hintText, hintStyle]}>{hint}</Text>
                    </View>
                )}

                <Text
                    style={[
                        styles.selectionButtonText,
                        selected && styles.buttonTextSelected,
                        hint && styles.selectionButtonTextWithHint,
                        textStyle
                    ]}>
                    {' '}
                    {text}{' '}
                </Text>
                {subText && (
                    <Text
                        style={[
                            styles.selectionButtonSubText,
                            selected && styles.buttonTextSelected
                        ]}>
                        {' '}
                        {subText}{' '}
                    </Text>
                )}
            </View>
        </TouchableHighlight>
    );
};

export default SelectionButton;
