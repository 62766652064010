import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    cardContainer: {
        width: '48%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 5
    },

    card: {
        width: '98%',
        height: 175,
        backgroundColor: '#FE474C',
        borderRadius: 5,
        shadowColor: 'rgba(0,0,0,0.5)',
        shadowOffset: {
            width: 0,
            height: 1
        },
        shadowOpacity: 0.5,
        justifyContent: 'center',
        alignItems: 'center'
    },

    card1: {
        backgroundColor: 'white',
        borderRadius: 20
    },

    card2: {
        backgroundColor: '#0D0334',
        borderRadius: 20
    },

    label: {
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: 'System',
        color: '#ffffff',
        backgroundColor: 'transparent',
        margin: 5
    },

    labelWeb: {
        textAlign: 'center',
        fontSize: Dimensions.get('window').height / 70,
        fontWeight: 'bold',
        fontFamily: 'System',
        color: '#ffffff',
        backgroundColor: 'transparent',
        margin: 2
    },

    image: {
        width: 100,
        height: 100,
        resizeMode: 'contain',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default styles;
