import * as React from 'react';
import { View, TouchableHighlight, Image } from 'react-native';
import styles from './styles';
import { GET_ONLINE_IMAGE_PATH } from '../../../../API/Environment.web';

const QuestionRow = ({ onPress, image, selected, index, correctAnswer }) => {
    return (
        <TouchableHighlight
            style={[
                styles.imageButton,
                selected && correctAnswer.image === image
                    ? styles.correctButton
                    : selected && correctAnswer.image !== image && styles.incorrectButton,
                correctAnswer.image === image &&
                    (index || index === 0) &&
                    styles.correctNonSelectedButton
            ]}
            onPress={onPress}
            underlayColor="#cac7c6"
            disabled={!!index}>
            <>
                <Image style={styles.image} source={{ uri: `${GET_ONLINE_IMAGE_PATH}${image}` }} />

                {correctAnswer.image === image && (index || index === 0) && (
                    <Image
                        style={{
                            resizeMode: 'contain',
                            height: 30,
                            width: 30,
                            left: 90,
                            bottom: 120,
                            position: 'absolute'
                        }}
                        source={require('../../../../../assets/images/PracticeZone/PNG/ttp-q-correct.png')}
                    />
                )}
                {selected && correctAnswer.image !== image && (
                    <Image
                        style={{
                            resizeMode: 'contain',
                            height: 30,
                            width: 30,
                            left: 90,
                            bottom: 120,
                            position: 'absolute'
                        }}
                        source={require('../../../../../assets/images/PracticeZone/PNG/ttp-q-wrong.png')}
                    />
                )}
            </>
        </TouchableHighlight>
    );
};

const ImagesQuestion = ({
    questionOne,
    questionTwo,
    questionThree,
    questionFour,
    index,
    setIndex,
    correctAnswer
}) => {
    return (
        <View>
            <View style={{ flexDirection: 'row' }}>
                <QuestionRow
                    image={questionOne}
                    onPress={() => setIndex(0)}
                    selected={index == 0}
                    index={index}
                    correctAnswer={correctAnswer}
                />

                <QuestionRow
                    image={questionTwo}
                    onPress={() => setIndex(1)}
                    selected={index == 1}
                    index={index}
                    correctAnswer={correctAnswer}
                />
            </View>

            <View style={{ flexDirection: 'row' }}>
                <QuestionRow
                    image={questionThree}
                    onPress={() => setIndex(2)}
                    selected={index == 2}
                    index={index}
                    correctAnswer={correctAnswer}
                />

                <QuestionRow
                    image={questionFour}
                    onPress={() => setIndex(3)}
                    selected={index == 3}
                    index={index}
                    correctAnswer={correctAnswer}
                />
            </View>
        </View>
    );
};

export default ImagesQuestion;
