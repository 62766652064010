import React, { useEffect, useState } from 'react';
import { View, ScrollView, Text, Dimensions } from 'react-native';
import styles from './styles.web';

import Dial from '../../Dial/index.web';
import PrimaryButton from '../../Buttons/PrimaryButton';
import { useHistory } from 'react-router-dom';
import { saveTest } from '../../../API/RealmComms.web';
import ResultCategoryGrouping from '../ResultCategoryGrouping/index.web';

import LoadingState from '../../LoadingState';
import SectionFeedback from '../SectionFeedback/index.web';

const ResultsScreen = ({
    totalQuestions,
    userScore,
    questionFeedbackBreakdown,
    categories,
    questionsTracker
}) => {
    const history = useHistory();
    const [groupedCategories, setGroupedCategories] = useState();
    const [loading, setLoading] = useState(true);
    const [index, setIndex] = useState('Needs work');

    useEffect(() => {
        const getData = async () => {
            await saveTest(
                userScore,
                totalQuestions,
                questionsTracker,
                questionFeedbackBreakdown,
                1
            );

            setLoading(true);

            const categoryGrouping = {
                'Needs work': [],
                'Good start': [],
                'Speeding ahead': []
            };

            Object.keys(questionFeedbackBreakdown).map((questionCategory, i) => {
                var score = 0;
                var scorePercentage = 0;

                questionFeedbackBreakdown[questionCategory].forEach((question, index) => {
                    if (question[1].correct === '1') {
                        score += 1;
                    }
                    if (score === 0) {
                        scorePercentage = 0;
                    } else {
                        scorePercentage = (score / (index + 1)) * 100;
                    }
                });

                if (scorePercentage < 33) {
                    categoryGrouping['Needs work'].push(
                        questionFeedbackBreakdown[questionCategory]
                    );
                } else if (scorePercentage > 33 && scorePercentage < 66) {
                    categoryGrouping['Good start'].push(
                        questionFeedbackBreakdown[questionCategory]
                    );
                } else {
                    categoryGrouping['Speeding ahead'].push(
                        questionFeedbackBreakdown[questionCategory]
                    );
                }
            });

            setGroupedCategories(categoryGrouping);

            setLoading(false);
        };
        getData();
    }, []);

    const CategoryName = ({ categoryID }) => {
        const categoryObject = categories.find((o) => o.id === categoryID);
        if (categoryObject) {
            return categoryObject.name;
        } else {
            return 'No name found';
        }
    };

    const totalScorePercentage = ((userScore / totalQuestions) * 100).toFixed(0);

    if (loading) {
        return <LoadingState />;
    }

    return (
        <View style={{ flex: 1, maxWidth: '100%', margin: 'auto' }}>
            <ScrollView contentContainerStyle={{ flexGrow: 1, backgroundColor: '#fff' }}>
                <Dial
                    scorePercentage={totalScorePercentage}
                    noPercentage={true}
                    noNote={true}
                    prompt={false}
                />

                <Text style={styles.scoreResultWeb}>
                    {userScore}/{totalQuestions}
                </Text>

                <View style={styles.feedbackViewWeb}>
                    <ResultCategoryGrouping
                        index={index}
                        setIndex={setIndex}
                        groupedCategories={groupedCategories}
                    />

                    <SectionFeedback
                        questionFeedbackBreakdown={groupedCategories[index]}
                        CategoryName={CategoryName}
                    />

                    <View style={{ width: '100%', marginTop: 40 }}>
                        <PrimaryButton
                            text="Go to dashboard"
                            onPress={() => history.push('/dashboard')}
                            textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                        />
                    </View>
                </View>
            </ScrollView>
        </View>
    );
};

export default ResultsScreen;
