import React, { useEffect, useState } from 'react';
import { Text, Image, View, Dimensions } from 'react-native';
import styles from './styles.web';

import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

import ModalPopup from '../../../Components/Popup/index.web';
import LoadingState from '../../../Components/LoadingState';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton';
import PrimaryTextInput from '../../../Components/TextInput/Primary';

import { useAuth } from '../../../Context/AuthContext';
import { login, accountStatus } from '../../../API/Joomla.web';
import { GET_ONLINE_IMAGE_PATH, UPGRADE_URL } from '../../../API/Environment.web';

const LoginScreen = () => {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(true);
    const [appVisible, setAppVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [transferVisible, setTransferVisible] = useState(false);
    const [subscriptionEndVisible, setSubscriptionEndVisible] = useState(false);
    const [upgradeUrl, setUpgradeUrl] = useState(null);
    const [errorMessage, setErrorMessage] = useState(
        'Your username or password is incorrect. Please try again.'
    );

    const auth = useAuth();
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        isMobile ? setAppVisible(true) : setAppVisible(false);
        setLoading(false);
    }, []);

    async function checkExpiry(loginDetails, subscriptionDetails, currentDate) {
        try {
            const expiryDate = moment(subscriptionDetails.expirydate);
            const isExpired = expiryDate.diff(currentDate, 'days');

            if (isExpired < 0 || subscriptionDetails === 'No Active Subscriptions') {
                setUpgradeUrl(
                    `${UPGRADE_URL}?userid=${loginDetails.userid}&testtype=${loginDetails.testtype}`
                );
                setSubscriptionEndVisible(true);
            } else {
                await auth.saveUser(email, loginDetails, subscriptionDetails);
                history.push('/dashboard');
            }
        } catch (error) {
            setErrorMessage(error);
            setErrorVisible(true);
        }
    }

    async function loginHandler(email, password) {
        try {
            const loginDetails = await login(email, password);

            const subscriptionDetails = await accountStatus(email);
            const currentDate = moment();

            await checkExpiry(loginDetails, subscriptionDetails, currentDate);
        } catch (error) {
            setErrorMessage(error),
                error ==
                'Your account has been successfully transferred to our new system, you must reset your password in order to login for the first time. Please click the button below to start the password reset process or contact us for assistance.'
                    ? setTransferVisible(true)
                    : setErrorVisible(true);
        }
    }

    if (loading) {
        return <LoadingState />;
    }
    return (
        <View style={styles.containerView}>
            <ModalPopup
                visible={errorVisible}
                setVisible={setErrorVisible}
                image={
                    <Image
                        style={styles.trailFinished}
                        source={require('../../../../assets/images/Popup/PNG/trial-finished.png')}
                    />
                }
                title={'Oops!'}
                secondaryText={errorMessage}
                buttonText={'Back to login'}
                onPress={() => setErrorVisible(false)}
            />

            <ModalPopup
                visible={subscriptionEndVisible}
                setVisible={setSubscriptionEndVisible}
                image={
                    <Image
                        style={styles.trailFinished}
                        source={require('../../../../assets/images/Popup/PNG/trial-finished.png')}
                    />
                }
                title={'Plan Expired'}
                secondaryText={'Please select a new plan to continue your learning.'}
                buttonText={'Choose a plan'}
                onPress={() => {
                    window.location.href = upgradeUrl;
                }}
            />

            <ModalPopup
                visible={transferVisible}
                setVisible={setTransferVisible}
                image={
                    <Image
                        style={styles.trailFinished}
                        source={require('../../../../assets/images/Popup/PNG/trial-finished.png')}
                    />
                }
                title={'Account Transferred'}
                secondaryText={errorMessage}
                buttonText={'Change Password'}
                onPress={() => {
                    history.push('/password');
                    setTransferVisible(false);
                }}
            />

            <ModalPopup
                visible={appVisible}
                setVisible={setAppVisible}
                image={
                    <Image
                        style={styles.trailFinished}
                        source={require('../../../../assets/images/Popup/PNG/trial-finished.png')}
                    />
                }
                title={'Did You Know?'}
                secondaryText={
                    'We have an app! Download it now for a better experience whilst using our service.'
                }
                downloadIOS
                downloadAndroid
                skip></ModalPopup>

            <Image
                style={styles.logoImage}
                source={require('../../../../assets/images/Login-Signup-Dashboard/Welcome/ttp-logo.png')}
            />

            <Text style={styles.pageTitle}> Login </Text>

            <PrimaryTextInput
                label={'Email Address'}
                value={email}
                onChangeText={(val) => setEmail(val)}
                customStyle={{
                    fontSize: Dimensions.get('window').height / 60,
                    width: '75%'
                }}
            />

            <PrimaryTextInput
                label={'Password'}
                value={password}
                onChangeText={(val) => setPassword(val)}
                secureTextEntry={true}
                customStyle={{
                    fontSize: Dimensions.get('window').height / 60,
                    width: '75%'
                }}
            />

            <Link to="/password" style={{ marginBottom: 33, alignSelf: 'center', width: '75%' }}>
                <Text style={styles.forgottenPasswordText}> Forgotten password? </Text>
            </Link>

            <PrimaryButton
                text="Log in"
                onPress={() => loginHandler(email, password)}
                disabled={![email, password].every(Boolean)}
                textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                style={{ width: '75%' }}
            />

            <View style={{ marginTop: 20, padding: 10, borderRadius: 10 }}>
                <Text
                    onPress={() => {
                        window.location.href = GET_ONLINE_IMAGE_PATH;
                    }}
                    style={styles.secondaryButtonText}>
                    {' '}
                    Go Back To Theory Test Pass{' '}
                </Text>
            </View>
        </View>
    );
};

export default LoginScreen;
