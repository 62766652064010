import React, { useEffect, useState } from 'react';
import { View, ScrollView, FlatList } from 'react-native';
import styles from './styles';

import LoadingState from '../../../Components/LoadingState';
import CategoryCard from '../../../Components/CardCategory';

import { fetchCategoryType } from '../../../API/RealmComms.web';
import { useScreenSize } from '../../../Utilities/utils';

const RoadSigns = () => {
    const screenSize = useScreenSize();

    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        (async () => {
            const res = await fetchCategoryType('1');
            setCategories(res);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <LoadingState />;
    }
    return (
        <View
            style={{
                width: screenSize.large ? '100%' : '80%',
                height: '90vh'
            }}>
            <ScrollView contentContainerStyle={styles.containerWeb}>
                <FlatList
                    numColumns={screenSize.large ? 1 : 3}
                    data={categories}
                    keyExtractor={(item) => item.id}
                    renderItem={({ item }) => (
                        <CategoryCard
                            heading={item.name}
                            text={item.description}
                            id={item.id}
                            pathname={'/roadsigns'}
                        />
                    )}
                />
            </ScrollView>
        </View>
    );
};

export default RoadSigns;
