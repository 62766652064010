import * as React from 'react';
import { View, Text, TouchableHighlight, ScrollView, Image } from 'react-native';
import styles from './styles.web';

import { formatISO9075 } from 'date-fns';

import PageContainer from '../../../PageContainer';
import QuestionBlock from '../QuestionBlock/index.web';
import ImagesQuestion from '../ImagesQuestion/index.web';
import ImageTitleQuestion from '../ImageTitleQuestion/index.web';
import CaseStudyQuestion from '../CaseStudyQuestion/index.web';
import HintPopup from '../../../HintPopup/index.web';
import PopupModal from '../../../Popup';

const QuestionParent = ({
    totalQuestions,
    questions,
    currentIndex,
    setCurrentIndex,
    indexTracker,
    setIndexTracker,
    userScore,
    setUserScore,
    setPracticeZoneStage,
    questionFeedbackBreakdown,
    setQuestionsFeedbackBreakdown,
    questionsTracker,
    setQuestionsTracker
}) => {
    const [index, setIndex] = React.useState(null);
    const [hintModal, setHintModal] = React.useState(0);
    const [visible, setVisible] = React.useState(false);

    const currentQuestion = questions[currentIndex];
    const answers = JSON.parse(currentQuestion.answers);
    var correctAnswer = null;

    Object.keys(answers).forEach(function (answer) {
        if (answers[answer].correct === '1') {
            correctAnswer = answers[answer];
        }
    });

    //load state to fix index issue?
    React.useEffect(() => {
        indexTracker[currentIndex] || indexTracker[currentIndex] == 0
            ? setIndex(indexTracker[currentIndex])
            : setIndex(null);
    }, [currentIndex]);

    const saveQuestion = async (currentQuestion) => {
        var questionDict = questionFeedbackBreakdown;
        const selectedAnswer = answers[`answers${index}`];
        const dateTime = formatISO9075(new Date());
        const updateTracker = questionsTracker;
        const trackIndex = indexTracker;

        async function checkID() {
            var check;
            await questionDict.forEach((element) => {
                if (element[0].id == currentQuestion.id) {
                    check = true;
                }
            });

            if (check != true) {
                questionDict.push([currentQuestion, selectedAnswer]);
                setQuestionsFeedbackBreakdown(questionDict);
                updateTracker.push({
                    userid: localStorage.getItem('userid'),
                    section: '2',
                    question: `${currentQuestion.id}`,
                    category: `${currentQuestion.category}`,
                    answer: `${answers[`answers${index}`].text}`,
                    correct: `${answers[`answers${index}`].correct}`,
                    dateadded: `${dateTime}`
                });
                setQuestionsTracker(updateTracker);

                if (answers[`answers${index}`].correct == '1') {
                    setUserScore((userScore += 1));
                }
            }
        }

        await checkID();

        if (!trackIndex[currentIndex] && trackIndex[currentIndex] !== 0) {
            trackIndex.push(index);
        }
        setIndexTracker(trackIndex);

        setIndex(null);

        if (currentIndex + 1 < totalQuestions) {
            setCurrentIndex((currentIndex += 1));
        } else {
            setPracticeZoneStage(3);
        }
    };

    const removeQuestion = (currentQuestion) => {
        setCurrentIndex((currentIndex -= 1));
    };

    return (
        <View style={{ flex: 1, maxWidth: '75%', margin: 'auto' }}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                <PageContainer>
                    <Text style={styles.headingQuestion}>
                        Practice Zone | {currentIndex + 1}/{totalQuestions}
                    </Text>

                    <PopupModal
                        visible={visible}
                        setVisible={setVisible}
                        title={'Exit Test'}
                        secondaryText={
                            'You will be taken back to the dashboard and your test attempt will be canceled.'
                        }
                        buttonText={'Back to dashboard'}
                        buttonLink={'/dashboard'}
                    />

                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginBottom: 20
                        }}>
                        {currentQuestion.hint.length > 0 && (
                            <TouchableHighlight
                                onPress={() => setHintModal(true)}
                                underlayColor="#cac7c6"
                                style={styles.hintButton}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        padding: 10,
                                        alignItems: 'center'
                                    }}>
                                    <Image
                                        style={{ resizeMode: 'contain', height: 15, width: 15 }}
                                        source={require('../../../../../assets/images/PracticeZone/PNG/icon-hint-white.png')}
                                    />
                                    <Text style={styles.hintText}>Hint</Text>
                                </View>
                            </TouchableHighlight>
                        )}
                    </View>

                    <HintPopup
                        visible={hintModal}
                        setVisible={setHintModal}
                        secondaryText={currentQuestion.hint}
                    />

                    <Text style={styles.headingText}>{currentQuestion.question} </Text>

                    {currentQuestion.casestudy === '1' ? (
                        <CaseStudyQuestion
                            image={currentQuestion.image}
                            questionTitle={currentQuestion.question}
                            questionOne={answers.answers0.text}
                            questionTwo={answers.answers1.text}
                            questionThree={answers.answers2.text}
                            questionFour={answers.answers3.text}
                            correctAnswer={correctAnswer}
                            index={index}
                            setIndex={setIndex}
                            currentIndex={currentIndex}
                            videoRef={currentQuestion.videoref}
                        />
                    ) : currentQuestion.image.length > 0 ? (
                        <ImageTitleQuestion
                            image={currentQuestion.image}
                            questionOne={answers.answers0.text}
                            questionTwo={answers.answers1.text}
                            questionThree={answers.answers2.text}
                            questionFour={answers.answers3.text}
                            correctAnswer={correctAnswer}
                            index={index}
                            setIndex={setIndex}
                        />
                    ) : answers.answers0.image.length > 0 ? (
                        <ImagesQuestion
                            questionOne={answers.answers0.image}
                            questionTwo={answers.answers1.image}
                            questionThree={answers.answers2.image}
                            questionFour={answers.answers3.image}
                            index={index}
                            setIndex={setIndex}
                            correctAnswer={correctAnswer}
                        />
                    ) : (
                        <QuestionBlock
                            questionOne={answers.answers0.text}
                            questionTwo={answers.answers1.text}
                            questionThree={answers.answers2.text}
                            questionFour={answers.answers3.text}
                            index={index}
                            setIndex={setIndex}
                            correctAnswer={correctAnswer}
                        />
                    )}
                </PageContainer>
            </ScrollView>

            {index || index === 0 ? (
                <View style={styles.navigationButtons}>
                    <TouchableHighlight
                        style={styles.previousButton}
                        onPress={() => {
                            if (currentIndex > 0) {
                                removeQuestion(questions[currentIndex - 1]);
                            }
                        }}
                        underlayColor="#cac7c6">
                        <Text style={styles.previousButtonText}> Previous </Text>
                    </TouchableHighlight>

                    <TouchableHighlight
                        style={styles.nextButton}
                        onPress={() => {
                            saveQuestion(currentQuestion);
                        }}
                        underlayColor="#cac7c6">
                        <Text style={styles.nextButtonText}> Next </Text>
                    </TouchableHighlight>
                </View>
            ) : null}
        </View>
    );
};

export default QuestionParent;
