import React, { useEffect, useState } from 'react';
import { View, Text, TouchableHighlight, Image } from 'react-native';
import styles from './styles.web';

import { useHistory, useLocation } from 'react-router-dom';

import ModalPopup from '../../../Components/Popup/index.web';
import LoadingState from '../../../Components/LoadingState';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton';

import { fetchHazards, arrayRows } from '../../../API/Joomla.web';
import { GET_ONLINE_IMAGE_PATH } from '../../../API/Environment.web';
import { useAuth } from '../../../Context/AuthContext';

const HazardPerceptionAdditional = () => {
    const [hazards, setHazards] = useState(null);
    const [loading, setLoading] = useState(true);
    const [permissionsExceeded, setPermissionsExceeded] = useState(false);

    const auth = useAuth();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const getData = async () => {
            const hazards = await fetchHazards();
            const filteredHazards = hazards.filter((hazard) => hazard.category === location.id);
            setHazards(filteredHazards);
            setLoading(false);
        };
        getData();
    }, []);

    if (loading) {
        return <LoadingState />;
    }

    return (
        <View style={styles.container}>
            <ModalPopup
                visible={permissionsExceeded}
                setVisible={setPermissionsExceeded}
                title={'Purchase Required'}
                secondaryText={
                    'A one time purchase of £6.99 is required to gain access to this section, including 25 training videos to help you pass!'
                }
                purchaseAdditionalVideos
                skip
            />

            <Text style={styles.learnHowToHeading}>{location?.heading || 'Learn How To'}</Text>

            {arrayRows(hazards, 3, true).map((slice) => {
                return (
                    <View style={styles.hazardRow}>
                        {slice.map((video, key) => {
                            if (!video) {
                                return <View style={styles.videoButtonStyle} />;
                            } else {
                                const isUnlocked =
                                    auth.user.additionalVideosPurchased == '1' ||
                                    video.showontrial === '1';
                                return (
                                    <View style={styles.videoButtonStyle}>
                                        <TouchableHighlight
                                            onPress={() => {
                                                isUnlocked
                                                    ? history.push({
                                                          pathname: '/learn-how-to-video',
                                                          hazard: video,
                                                          heading: location?.heading,
                                                          id: location?.id
                                                      })
                                                    : setPermissionsExceeded(true);
                                            }}
                                            style={styles.videoStyle}
                                            underlayColor="#cac7c6"
                                            id={key}>
                                            <>
                                                <Image
                                                    style={
                                                        isUnlocked
                                                            ? styles.thumbnail
                                                            : [styles.thumbnail, { opacity: 0.3 }]
                                                    }
                                                    source={{
                                                        uri: `${GET_ONLINE_IMAGE_PATH}${video.thumbnail}`
                                                    }}
                                                />
                                                <Image
                                                    style={styles.videoIcon}
                                                    source={
                                                        isUnlocked
                                                            ? require('../../../../assets/images/HazardPerception/SVG/icon-play-blue.svg')
                                                            : require('../../../../assets/images/LearnHowTo/PNG/lock.png')
                                                    }
                                                />
                                            </>
                                        </TouchableHighlight>
                                        <Text style={styles.videoTitle}>{video.name}</Text>
                                    </View>
                                );
                            }
                        })}
                    </View>
                );
            })}
            {localStorage.getItem('additionalVideosPurchased') === '0' && (
                <View style={styles.ctaButton}>
                    <PrimaryButton
                        textStyle={styles.ctaButtonText}
                        text={`Upgrade to access all videos`}
                        onPress={() => setPermissionsExceeded(true)}
                    />
                </View>
            )}
        </View>
    );
};

export default HazardPerceptionAdditional;
