import * as React from 'react';
import { View, Text, TouchableHighlight } from 'react-native';
import styles from './styles.web';

const QuestionRow = ({ text, selected, onPress }) => {
    return (
        <View style={styles.testButtonView}>
            <TouchableHighlight
                style={[styles.testButtonWeb, selected && styles.selectedButton]}
                onPress={onPress}
                underlayColor="#cac7c6">
                <Text style={[styles.testButtonText, selected && styles.selectedText]}>
                    {' '}
                    {text}{' '}
                </Text>
            </TouchableHighlight>
        </View>
    );
};

const QuestionBlock = ({
    questionOne,
    questionTwo,
    questionThree,
    questionFour,
    index,
    setIndex,
    correctAnswer
}) => {
    return (
        <View style={{ width: '100%' }}>
            <QuestionRow
                text={questionOne}
                onPress={() => setIndex(0)}
                selected={index == 0}
                disabled={index}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionTwo}
                onPress={() => setIndex(1)}
                selected={index == 1}
                disabled={index}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionThree}
                onPress={() => setIndex(2)}
                selected={index == 2}
                disabled={index}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionFour}
                onPress={() => setIndex(3)}
                selected={index == 3}
                disabled={index}
                correctAnswer={correctAnswer}
                index={index}
            />
        </View>
    );
};

export default QuestionBlock;
