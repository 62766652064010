import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, TouchableHighlight, Dimensions } from 'react-native';
import styles from './styles.web';
import { useHistory } from 'react-router-dom';
import LoadingState from '../../../Components/LoadingState';

import { fetchUserTests } from '../../../API/Joomla.web';
import { getUserTestType } from '../../../API/RealmComms.web';

const TestResultsBox = ({ userTest }) => {
    const history = useHistory();

    return (
        <>
            <TouchableHighlight
                onPress={() => {
                    history.push({
                        pathname: '/progress/individual-hazard',
                        userTest: userTest
                    });
                }}
                underlayColor="#cac7c6"
                style={styles.testResultsButton}>
                <View style={styles.testResultsView}>
                    <View
                        style={[
                            styles.testResult,
                            {
                                borderColor: userTest.item.result === '0' ? '#99C455' : '#DD3026'
                            }
                        ]}>
                        <Text style={{ fontSize: Dimensions.get('window').height / 60 }}>
                            {userTest.item.result === '1' ? 'Fail' : 'Pass'}
                        </Text>
                    </View>

                    <View style={{ flexDirection: 'column' }}>
                        {userTest.item.totalquestions > 5 && (
                            <Text
                                style={{
                                    textAlign: 'right',
                                    fontSize: Dimensions.get('window').height / 60
                                }}>
                                Mock Test
                            </Text>
                        )}

                        {userTest.item.totalquestions <= 5 && (
                            <Text
                                style={{
                                    textAlign: 'right',
                                    fontSize: Dimensions.get('window').height / 60
                                }}>
                                Single Video
                            </Text>
                        )}

                        <View style={{ flexDirection: 'row' }}>
                            <Text style={styles.testScore}>
                                {userTest.item.correctanswers}/{userTest.item.totalquestions}
                            </Text>

                            <Text style={styles.testDate}>{userTest.item.datecompleted}</Text>
                        </View>
                    </View>
                </View>
            </TouchableHighlight>
        </>
    );
};

const ProgressPage = () => {
    const [userTests, setUserTests] = useState(null);

    useEffect(() => {
        (async () => {
            const res = await fetchUserTests();
            const hazardUserTests = await getUserTestType(res, '3');

            setUserTests(hazardUserTests);
        })();
    }, []);

    if (userTests === null) {
        return <LoadingState />;
    } else {
        return (
            <View style={styles.container}>
                <View style={styles.resultsContainer}>
                    <FlatList
                        data={userTests}
                        keyExtractor={(test) => test.id}
                        renderItem={(test) => <TestResultsBox userTest={test} />}
                    />
                </View>
            </View>
        );
    }
};

export default ProgressPage;
