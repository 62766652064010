import React from 'react';
import { Image } from 'react-native';

const CategoryIcon = ({ categoryName, size = null }) => {
    const iconStyle = { resizeMode: 'contain', height: size || 30, width: size || 30 };
    const categoryIcons = {
        ALERTNESS: (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/alertness.png')}
            />
        ),
        ATTITUDE: (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/attitude.png')}
            />
        ),
        'SAFETY AND YOUR VEHICLE': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/safety.png')}
            />
        ),
        'SAFETY AND YOUR MOTORCYCLE': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/safety.png')}
            />
        ),
        'INCIDENTS, ACCIDENTS AND EMERGENCIES': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/alertness.png')}
            />
        ),
        'ACCIDENTS (HANDLING)': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/alertness.png')}
            />
        ),
        'SAFETY MARGINS': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/safety-margins.png')}
            />
        ),
        'HAZARD AWARENESS': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/hazard.png')}
            />
        ),
        'HAZARD AWARENESS (IMPAIRMENT)': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/hazard.png')}
            />
        ),
        'HAZARD AWARENESS (PERCEPTION)': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/hazard.png')}
            />
        ),
        'VULNERABLE ROAD USERS': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/vulnerable.png')}
            />
        ),
        'OTHER TYPES OF VEHICLE': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/other-vehicles.png')}
            />
        ),
        'VEHICLE/MOTORCYCLE HANDLING': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/handling.png')}
            />
        ),
        'VEHICLE HANDLING': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/handling.png')}
            />
        ),
        'MOTORCYCLE HANDLING (SLIPPERY PARTS)': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/handling.png')}
            />
        ),
        'MOTORCYCLE HANDLING': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/handling.png')}
            />
        ),
        'MOTORWAY RULES': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/motorway-rules.png')}
            />
        ),
        'RULES OF THE ROAD': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/rules.png')}
            />
        ),
        'ROAD AND TRAFFIC SIGNS': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/signs.png')}
            />
        ),
        DOCUMENTS: (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/documents.png')}
            />
        ),
        'ESSENTIAL DOCUMENTS': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/documents.png')}
            />
        ),
        'INCIDENTS, ACCIDENTS AND ...': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/incidents.png')}
            />
        ),
        'VEHICLE LOADING': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/vehicle-loading.png')}
            />
        ),
        'VEHICLE/MOTORCYCLE LOADING': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/vehicle-loading.png')}
            />
        ),
        'CASE STUDIES': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/documents.png')}
            />
        ),
        'MOTORWAY DRIVING': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/other-vehicles.png')}
            />
        ),
        'SAFETY AND YOUR MOTORCYCLE / VEHICLE': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/safety.png')}
            />
        ),
        'VEHICLE & MOTORCYCLE HANDLING': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/handling.png')}
            />
        ),
        'VEHICLE WEIGHTS AND DIMENSIONS': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/handling.png')}
            />
        ),
        "DRIVERS' HOURS AND REST PERIODS": (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/safety.png')}
            />
        ),
        'BRAKING SYSTEMS': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/safety.png')}
            />
        ),
        'THE DRIVER': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/vulnerable.png')}
            />
        ),
        'CARRYING PASSENGERS': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/vulnerable.png')}
            />
        ),
        'THE ROAD': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/handling.png')}
            />
        ),
        'VEHICLE CONDITION': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/safety.png')}
            />
        ),
        'LEAVING THE VEHICLE': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/vehicle-loading.png')}
            />
        ),
        'RESTRICTED VIEW': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/handling.png')}
            />
        ),
        'ENVIRONMENTAL ISSUES': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/documents.png')}
            />
        ),
        'OTHER ROAD USERS': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/PracticeZone/PNG/safety-margins.png')}
            />
        ),
        'PREPARING TO DRIVE': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/LearnHowTo/PNG/steering-wheel.png')}
            />
        ),
        JUNCTIONS: (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/LearnHowTo/PNG/t-junction.png')}
            />
        ),
        'CROSSINGS & GENERAL': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/LearnHowTo/PNG/road.png')}
            />
        ),
        CROSSROADS: (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/LearnHowTo/PNG/junction-two.png')}
            />
        ),
        ROUNDABOUTS: (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/LearnHowTo/PNG/junction.png')}
            />
        ),
        'MINI ROUNDABOUTS': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/LearnHowTo/PNG/junction.png')}
            />
        ),
        MANEUVERS: (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/LearnHowTo/PNG/cone.png')}
            />
        ),
        'SHOW ME, TELL ME': (
            <Image
                style={iconStyle}
                source={require('../../../assets/images/LearnHowTo/PNG/document.png')}
            />
        )
    };

    categoryName = categoryName && categoryName.toUpperCase();

    return <>{categoryIcons[categoryName]}</>;
};

export default CategoryIcon;
