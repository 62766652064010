import React, { useState } from 'react';
import { View, Text, TouchableHighlight, Image } from 'react-native';
import styles from './styles.web';
import { GET_ONLINE_IMAGE_PATH } from '../../../API/Environment.web';

const DetailedFeedback = ({ questionCategory, extendedExpanded, setExtendedExpanded, sort }) => {
    return (
        <View style={styles.detailedFeedbackView}>
            {Object.keys(questionCategory).map((question, i) => {
                const selectedAnswerText = questionCategory[question][1].text;
                const answers = JSON.parse(questionCategory[question][0].answers);
                if (
                    sort === 'All' ||
                    (questionCategory[question][1].correct === '1' && sort === 'Correct') ||
                    (questionCategory[question][1].correct === '0' && sort === 'Incorrect')
                ) {
                    return (
                        <>
                            <TouchableHighlight
                                onPress={() => {
                                    extendedExpanded !== i
                                        ? setExtendedExpanded(i)
                                        : setExtendedExpanded(null);
                                }}
                                underlayColor="transparent">
                                <View style={styles.questionChevronView}>
                                    <Text numberOfLines={10} style={styles.questionTitle}>
                                        {questionCategory[question][0].question}
                                    </Text>
                                    {extendedExpanded === i ? (
                                        <Image
                                            style={{
                                                marginRight: 10,
                                                marginTop: 18,
                                                resizeMode: 'contain',
                                                height: 15,
                                                width: 15
                                            }}
                                            onPress={() => setExtendedExpanded(null)}
                                            source={require('../../../../assets/images/TrainingPlan/PNG/icon-up-arrow.png')}
                                        />
                                    ) : (
                                        <Image
                                            style={{
                                                marginRight: 10,
                                                marginTop: 18,
                                                resizeMode: 'contain',
                                                height: 15,
                                                width: 15
                                            }}
                                            source={require('../../../../assets/images/TrainingPlan/PNG/icon-down-arrow.png')}
                                        />
                                    )}
                                </View>
                            </TouchableHighlight>

                            <Text style={styles.border} />

                            {extendedExpanded === i && (
                                <View style={{ paddingLeft: 20 }}>
                                    {questionCategory[question][0].image.length > 0 && (
                                        <Image
                                            style={styles.questionTitleImage}
                                            source={{
                                                uri: `${GET_ONLINE_IMAGE_PATH}${questionCategory[question][0].image}`
                                            }}
                                        />
                                    )}
                                    {Object.keys(answers).map((answer, index) => {
                                        return (
                                            <View style={{ flexDirection: 'column' }}>
                                                <View style={styles.extendedExpandedAnswer}>
                                                    {answers[answer].image ? (
                                                        <Image
                                                            style={[
                                                                styles.image,
                                                                styles.extendedExpandedAnswer,
                                                                answers[answer].text ===
                                                                    selectedAnswerText &&
                                                                    styles.selectedAnswer,
                                                                answers[answer].correct === '1' &&
                                                                    styles.correctAnswer
                                                            ]}
                                                            source={{
                                                                uri: `${GET_ONLINE_IMAGE_PATH}${answers[answer].image}`
                                                            }}
                                                        />
                                                    ) : (
                                                        <Text
                                                            style={[
                                                                styles.extendedExpandedAnswer,
                                                                answers[answer].text ===
                                                                    selectedAnswerText &&
                                                                    styles.selectedAnswer,
                                                                answers[answer].correct === '1' &&
                                                                    styles.correctAnswer
                                                            ]}>
                                                            {answers[answer].text}
                                                        </Text>
                                                    )}
                                                    {answers[answer].correct === '1' &&
                                                        questionCategory[question][0].hint.length >
                                                            0 && (
                                                            <Text style={styles.answer}>
                                                                <Text
                                                                    style={{
                                                                        color: '#0D0334',
                                                                        fontWeight: 'bold'
                                                                    }}>
                                                                    Explanation
                                                                </Text>
                                                                {'\n'}
                                                                {questionCategory[question][0].hint}
                                                            </Text>
                                                        )}
                                                </View>
                                            </View>
                                        );
                                    })}
                                </View>
                            )}
                        </>
                    );
                }
            })}
        </View>
    );
};

const SectionFeedback = ({ questionFeedbackBreakdown, CategoryName, sort = 'All' }) => {
    const [extended, setExtended] = useState();
    const [extendedExpanded, setExtendedExpanded] = useState();
    return Object.keys(questionFeedbackBreakdown).map((questionCategory, i) => {
        var score = 0;
        var finalScore = 0;
        var scorePercentage = 0;

        questionFeedbackBreakdown[questionCategory].forEach((question, index) => {
            if (question[1].correct === '1') {
                score += 1;
                finalScore = `${score}/${index + 1}`;
                if (score === 0) {
                    scorePercentage = 0;
                } else {
                    scorePercentage = (score / (index + 1)) * 100;
                }
            } else {
                finalScore = `${score}/${index + 1}`;
                if (score === 0) {
                    scorePercentage = 0;
                } else {
                    scorePercentage = (score / (index + 1)) * 100;
                }
            }
        });
        if (
            !sort ||
            sort === 'All' ||
            (sort === 'Incorrect' && scorePercentage < 100) ||
            (sort === 'Correct' && scorePercentage > 1)
        ) {
            return (
                <>
                    <View style={{ width: '100%' }}>
                        <TouchableHighlight
                            style={[
                                styles.feedbackSectionButton,
                                extended === i && styles.extendedView
                            ]}
                            onPress={() => {
                                extended === i ? setExtended(null) : setExtended(i);
                            }}
                            underlayColor="#cac7c6">
                            <View style={styles.feedbackSectionView}>
                                <Text
                                    style={[
                                        styles.feedbackSectionText,
                                        extended === i && { color: 'white' }
                                    ]}>
                                    <CategoryName
                                        categoryID={
                                            questionFeedbackBreakdown[questionCategory][0][0]
                                                .category
                                        }
                                    />
                                </Text>
                                <Text
                                    style={[
                                        styles.feedbackSectionText,
                                        extended === i && { color: 'white' }
                                    ]}>
                                    {finalScore}
                                </Text>
                            </View>
                        </TouchableHighlight>
                        {extended === i && (
                            <DetailedFeedback
                                questionCategory={questionFeedbackBreakdown[questionCategory]}
                                extendedExpanded={extendedExpanded}
                                setExtendedExpanded={setExtendedExpanded}
                                sort={sort}
                            />
                        )}
                    </View>
                </>
            );
        } else {
            return null;
        }
    });
};

export default SectionFeedback;
