import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
        paddingTop: 36,
        flex: 1,
        margin: 'auto',
        height: '100vh',
        width: '100vw'
    },

    hpImage: {
        width: '50vw',
        height: '50vh',
        marginBottom: -20,
        resizeMode: 'contain'
    },

    headingText: {
        paddingBottom: 15,
        fontWeight: 'bold',
        fontSize: 24,
        alignSelf: 'flex-start',
        color: '#0D0334'
    },

    secondaryButton: {
        marginTop: 20,
        width: '100%',
        padding: 10,
        borderRadius: 20
    },

    secondaryButtonText: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#0D0334',
        textAlign: 'center'
    },

    categoryName: {
        color: '#0D0334',
        fontSize: Dimensions.get('window').height / 30,
        textAlign: 'center',
        fontWeight: 'bold',
        marginVertical: 10
    },

    tipViewWeb: {
        alignSelf: 'center',
        marginVertical: 8,
        flexDirection: 'row'
    },

    tipText: {
        color: '#868199',
        fontSize: Dimensions.get('window').height / 80,
        marginLeft: 10
    },

    miniImage: {
        width: '3vw',
        height: '3vh',
        resizeMode: 'contain'
    }
});

export default styles;
