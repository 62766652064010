import React, { useEffect, useState, useRef } from 'react';
import { Text, View, Animated, Modal, Dimensions, Linking } from 'react-native';
import styles from './styles';

import { useHistory } from 'react-router-dom';

import PrimaryLink from '../Buttons/PrimaryLink';
import PrimaryButton from '../Buttons/PrimaryButton';

import { useAuth } from '../../Context/AuthContext';
import { logout } from '../../API/Joomla.web';

import { ADDITIONAL_VIDEOS_URL, ANDROID_STORE_URL, IOS_STORE_URL } from '../../API/Environment.web';

const ModalPoup = ({ visible, children }) => {
    const [showModal, setShowModal] = useState(visible);
    const scaleValue = useRef(new Animated.Value(0)).current;
    useEffect(() => {
        toggleModal();
    }, [visible]);
    const toggleModal = () => {
        if (visible) {
            setShowModal(true);
            Animated.spring(scaleValue, {
                toValue: 1,
                duration: 300,
                useNativeDriver: true
            }).start();
        } else {
            setTimeout(() => setShowModal(false), 200);
            Animated.timing(scaleValue, {
                toValue: 0,
                duration: 300,
                useNativeDriver: true
            }).start();
        }
    };

    return (
        <Modal transparent visible={showModal}>
            <View style={styles.modalBackGround}>
                <Animated.View
                    style={[styles.modalContainer, { transform: [{ scale: scaleValue }] }]}>
                    {children}
                </Animated.View>
            </View>
        </Modal>
    );
};

const Header = ({
    visible,
    setVisible,
    image,
    title,
    secondaryText,
    buttonText,
    buttonLink,
    subText,
    skip,
    onPress,
    logoutOnSkip,
    downloadAndroid,
    downloadIOS,
    purchaseAdditionalVideos
}) => {
    const history = useHistory();
    const auth = useAuth();

    return (
        <ModalPoup visible={visible}>
            <View style={{ alignItems: 'center' }}>{image}</View>

            <Text style={[styles.title, { fontSize: Dimensions.get('window').height / 60 }]}>
                {title}
            </Text>

            <Text
                style={[styles.secondaryText, { fontSize: Dimensions.get('window').height / 70 }]}>
                {secondaryText}
            </Text>

            {buttonLink ? (
                <PrimaryLink
                    text={`${buttonText}`}
                    textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                    link={`${buttonLink}`}
                    onPress={() => setVisible(false)}
                />
            ) : buttonText ? (
                <PrimaryButton
                    text={`${buttonText}`}
                    textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                    onPress={onPress}
                />
            ) : (
                <></>
            )}
            {downloadIOS && (
                <PrimaryButton
                    text={`Download For iOS`}
                    textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                    style={{ marginBottom: 25 }}
                    onPress={() => Linking.openURL(IOS_STORE_URL)}
                />
            )}
            {downloadAndroid && (
                <PrimaryButton
                    text={`Download For Android`}
                    textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                    style={{ marginBottom: 25 }}
                    onPress={() => Linking.openURL(ANDROID_STORE_URL)}
                />
            )}
            {purchaseAdditionalVideos && (
                <PrimaryButton
                    text={`Purchase now`}
                    textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                    onPress={() =>
                        (window.location.href = `${ADDITIONAL_VIDEOS_URL}${auth.user.urlParams}`)
                    }
                />
            )}
            {skip && (
                <Text
                    onPress={async () => {
                        if (logoutOnSkip) {
                            await logout(localStorage.getItem('session'));
                            history.push('/login');
                        } else {
                            setVisible(false);
                        }
                    }}
                    style={[styles.skipText, { fontSize: Dimensions.get('window').height / 60 }]}>
                    {' '}
                    {logoutOnSkip ? 'Logout' : 'Close'}
                </Text>
            )}
            {subText && (
                <Text style={[styles.subText, { fontSize: Dimensions.get('window').height / 70 }]}>
                    {subText}
                </Text>
            )}
        </ModalPoup>
    );
};

export default Header;
