import * as React from 'react';
import { View, TouchableHighlight, Image } from 'react-native';
import styles from './styles';
import { GET_ONLINE_IMAGE_PATH } from '../../../API/Environment.web';

const QuestionRow = ({ onPress, image, selected }) => {
    return (
        <TouchableHighlight
            style={[styles.imageButton, selected && styles.selected]}
            onPress={onPress}
            underlayColor="#cac7c6">
            <>
                <Image style={styles.image} source={{ uri: `${GET_ONLINE_IMAGE_PATH}${image}` }} />
            </>
        </TouchableHighlight>
    );
};

const ImagesQuestion = ({
    questionOne,
    questionTwo,
    questionThree,
    questionFour,
    index,
    setIndex,
    correctAnswer
}) => {
    return (
        <View>
            <View style={{ flexDirection: 'row' }}>
                <QuestionRow
                    image={questionOne}
                    onPress={() => setIndex(0)}
                    selected={index == 0}
                    index={index}
                    correctAnswer={correctAnswer}
                />

                <QuestionRow
                    image={questionTwo}
                    onPress={() => setIndex(1)}
                    selected={index == 1}
                    index={index}
                    correctAnswer={correctAnswer}
                />
            </View>

            <View style={{ flexDirection: 'row' }}>
                <QuestionRow
                    image={questionThree}
                    onPress={() => setIndex(2)}
                    selected={index == 2}
                    index={index}
                    correctAnswer={correctAnswer}
                />

                <QuestionRow
                    image={questionFour}
                    onPress={() => setIndex(3)}
                    selected={index == 3}
                    index={index}
                    correctAnswer={correctAnswer}
                />
            </View>
        </View>
    );
};

export default ImagesQuestion;
