import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    headingText: {
        paddingBottom: 15,
        fontWeight: 'bold',
        fontSize: Dimensions.get('window').height / 50,
        alignSelf: 'flex-start',
        color: '#0D0334'
    },

    secondaryTitle: {
        fontSize: 16,
        color: 'white',
        padding: 20,
        textAlign: 'center'
    },

    secondaryButton: {
        marginTop: 32
    },

    secondaryButtonText: {
        fontSize: Dimensions.get('window').height / 60,
        color: '#0D0334'
    },

    recommendedButton: {
        flexDirection: 'column',
        width: '100%'
    },

    dateFieldView: {
        width: '100%',
        flexDirection: 'row',
        borderRadius: 0,
        justifyContent: 'center',
        marginBottom: 15
    },

    input: {
        width: '80%',
        backgroundColor: '#fff'
    },

    dateButton: {
        width: '20%',
        backgroundColor: '#0D0334',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 5,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
        marginLeft: -2
    }
});

export default styles;
