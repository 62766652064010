import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, TouchableHighlight } from 'react-native';
import styles from './styles.web';
import { useHistory } from 'react-router-dom';
import LoadingState from '../../../Components/LoadingState';

import { fetchUserTests, fetchCategories } from '../../../API/Joomla.web';
import { getUserTestType } from '../../../API/RealmComms.web';

const TestResultsBox = ({ userTest, categories }) => {
    const history = useHistory();

    return (
        <TouchableHighlight
            onPress={() => {
                history.push({
                    pathname: '/progress/individual-mock',
                    categories: categories,
                    userTest: userTest
                });
            }}
            underlayColor="#cac7c6"
            style={styles.testResultsButton}>
            <View style={styles.testResultsView}>
                <Text
                    style={[
                        styles.testResult,
                        { borderColor: userTest.item.result === '0' ? '#99C455' : '#DD3026' }
                    ]}>
                    {userTest.item.result === '1' ? 'Fail' : 'Pass'}
                </Text>

                <Text style={styles.testScore}>
                    {userTest.item.correctanswers}/{userTest.item.totalquestions}
                </Text>

                <Text style={styles.testDate}>{userTest.item.datecompleted}</Text>
            </View>
        </TouchableHighlight>
    );
};

const ProgressPage = () => {
    const [userTests, setUserTests] = useState(null);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        (async () => {
            const res = await fetchUserTests();
            const categories = await fetchCategories();
            const mockUserTests = await getUserTestType(res, '0');

            setUserTests(mockUserTests);
            setCategories(categories);
        })();
    }, []);

    if (userTests === null) {
        return <LoadingState />;
    } else {
        return (
            <View style={styles.container}>
                <View style={styles.resultsContainer}>
                    <FlatList
                        data={userTests}
                        keyExtractor={(test) => test.id}
                        renderItem={(test) => (
                            <TestResultsBox categories={categories} userTest={test} />
                        )}
                    />
                </View>
            </View>
        );
    }
};

export default ProgressPage;
