import React, { useState } from 'react';
import { Text, TouchableOpacity, Image } from 'react-native';
import ReactCardFlip from 'react-card-flip';
import styles from './styles';
import { GET_ONLINE_IMAGE_PATH } from '../../API/Environment.web';

const Card = ({ image, heading }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    return (
        <ReactCardFlip
            isFlipped={isFlipped}
            flipDirection="vertical"
            containerStyle={styles.cardContainer}>
            <TouchableOpacity
                activeOpacity={1}
                style={[styles.card, styles.card1]}
                onPress={() => setIsFlipped(!isFlipped)}>
                <Image style={styles.image} source={{ uri: `${GET_ONLINE_IMAGE_PATH + image}` }} />
            </TouchableOpacity>
            <TouchableOpacity
                activeOpacity={1}
                style={[styles.card, styles.card2]}
                onPress={() => setIsFlipped(!isFlipped)}>
                <Text style={styles.labelWeb}>{heading}</Text>
            </TouchableOpacity>
        </ReactCardFlip>
    );
};

export default Card;
