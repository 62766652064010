import React from 'react';
import { View, Text, TouchableHighlight } from 'react-native';
import styles from './styles.web';

const CategoryButton = ({ buttonText, index, setIndex, bColor }) => {
    return (
        <>
            <TouchableHighlight
                style={[
                    styles.selectionButton,
                    index === buttonText && { backgroundColor: bColor }
                ]}
                onPress={() => setIndex(buttonText)}
                underlayColor="#cac7c6">
                <Text
                    style={[
                        styles.selectionButtonText,
                        index === buttonText && { color: 'white' }
                    ]}>
                    {buttonText}
                </Text>
            </TouchableHighlight>
        </>
    );
};

const ResultCategoryGrouping = ({ index, setIndex, groupedCategories }) => {
    var filledCategories = [];

    if (groupedCategories['Speeding ahead'].length > 0) {
        filledCategories.push('Speeding ahead');
    }

    if (groupedCategories['Good start'].length > 0) {
        filledCategories.push('Good start');
    }

    if (groupedCategories['Needs work'].length > 0) {
        filledCategories.push('Needs work');
    }

    if (filledCategories.length === 1) {
        setIndex(filledCategories[0]);
    }

    return (
        <View style={{ flexDirection: 'row', width: '95%', height: 75, marginTop: 5 }}>
            {filledCategories.length > 1 && (
                <View style={styles.buttonRowView}>
                    {groupedCategories['Needs work'].length > 0 && (
                        <CategoryButton
                            buttonText={'Needs work'}
                            bColor={'red'}
                            index={index}
                            setIndex={setIndex}
                        />
                    )}

                    {groupedCategories['Good start'].length > 0 && (
                        <CategoryButton
                            buttonText={'Good start'}
                            bColor={'#FCB040'}
                            index={index}
                            setIndex={setIndex}
                        />
                    )}

                    {groupedCategories['Speeding ahead'].length > 0 && (
                        <CategoryButton
                            buttonText={'Speeding ahead'}
                            bColor={'#8CC63F'}
                            index={index}
                            setIndex={setIndex}
                        />
                    )}
                </View>
            )}
        </View>
    );
};

export default ResultCategoryGrouping;
