import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1
    },

    scrollContainer: {
        backgroundColor: '#F3F2F5',
        paddingHorizontal: 28,
        paddingVertical: 20
    },

    pageContainer: {
        paddingHorizontal: 28,
        paddingVertical: 20,
        backgroundColor: '#F3F2F5'
    },

    pageContainerWeb: {
        flexDirection: 'row',
        paddingHorizontal: 28,
        paddingVertical: 20,
        backgroundColor: '#F3F2F5'
    },

    pageContainerWebMobile: {
        flexDirection: 'column',
        paddingVertical: 20,
        backgroundColor: '#F3F2F5',
        justifyContent: 'center',
        alignItems: 'center'
    },

    headerBackgroundImageWeb: {
        height: 350
    },

    buttonRowViewsWeb: {
        flexDirection: 'row',
        justifyContent: 'center'
    },

    categoryBoxButton: {
        backgroundColor: '#fff',
        marginRight: 10,
        padding: 15,
        borderRadius: 10,
        width: 150,
        height: 150
    },

    pageTitle: {
        fontWeight: 'bold',
        paddingBottom: 15,
        color: '#0D0334'
    },

    categoryBoxButtonWeb: {
        backgroundColor: '#fff',
        marginRight: 10,
        padding: 15,
        borderRadius: 10,
        height: 150,
        marginBottom: 10
    },

    categoryName: {
        fontSize: 18,
        color: '#0D0334',
        fontWeight: 'bold',
        marginVertical: 5,
        textTransform: 'capitalize'
    },

    categoryNameWeb: {
        color: '#0D0334',
        fontWeight: 'bold',
        marginVertical: 5,
        textTransform: 'capitalize'
    },

    percentageBar: {
        width: '90%',
        borderRadius: 5,
        marginVertical: 10,
        bottom: 32,
        position: 'absolute'
    },

    categoryAnsweredQuestions: {
        color: '#868199',
        bottom: 12,
        position: 'absolute',
        width: '80%'
    },

    dialView: {
        flexDirection: 'row',
        width: '75%',
        alignItems: 'center',
        justifyContent: 'center'
    },

    ResultsBoxView: {
        alignItems: 'center',
        marginVertical: 25,
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 10,
        marginHorizontal: 10
    },

    avgScoreText: {
        textTransform: 'uppercase',
        marginBottom: 20,
        color: '#868199'
    },

    percentageText: {
        fontSize: 46,
        fontWeight: 'bold',
        color: '#0D0334',
        marginTop: -30
    },

    percentageTextWeb: {
        fontSize: 46,
        fontWeight: 'bold',
        color: '#0D0334'
    }
});

export default styles;
