import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    parentContainer: {
        flex: 1,
        backgroundColor: '#408BFC'
    },
    container: {
        paddingHorizontal: 35,
        paddingVertical: 25,
        backgroundColor: '#F3F2F5'
    },
    background: {
        backgroundColor: '#fff'
    },
    introText: {
        lineHeight: 20,
        textAlign: 'center'
    },
    introTextWeb: {
        fontSize: 22,
        alignSelf: 'center'
    },
    bold: {
        fontWeight: 'bold'
    },
    percentageView: {
        alignItems: 'center',
        bottom: '8%'
    },
    percentage: {
        fontWeight: 'bold',
        fontSize: 40,
        color: '#121148'
    },
    encouragement: {
        alignSelf: 'center',
        fontWeight: 'bold',
        fontSize: 22,
        color: '#121148'
    },
    feedback: {
        textAlign: 'center',
        alignSelf: 'center',
        marginTop: 20
    },
    tcs: {
        color: '#121148',
        textDecorationLine: 'underline',
        textAlign: 'center',
        marginTop: 20
    },
    supportButton: {
        marginTop: 25,
        borderRadius: 5
    }
});

export default styles;
