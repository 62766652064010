import React from 'react';
import { View, Text, Image } from 'react-native';

import styles from './styles';

const CriteriaBox = ({ checked, text, style, textStyle }) => {
    return (
        <View style={[styles.containerWeb, style]}>
            <Image
                source={
                    checked
                        ? require('../../../assets/images/TrainingPlan/PNG/icon-green-tick.png')
                        : require('../../../assets/images/TrainingPlan/PNG/icon-red-cross.png')
                }
                style={styles.checkCrossWeb}
            />
            <Text style={[styles.text, textStyle]}>{text}</Text>
        </View>
    );
};

export default CriteriaBox;
