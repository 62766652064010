import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    buttonWebView: {
        backgroundColor: '#fff',
        borderRadius: 10,
        marginBottom: 10,
        marginRight: 10,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '80%'
    },

    buttonStyle: {
        borderRadius: 10,
        padding: 20,
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },

    buttonInnerView: {
        alignItems: 'center',
        flexDirection: 'row'
    },

    textContainer: {
        flexDirection: 'column',
        marginLeft: 20,
        flexShrink: 1
    },

    text: {
        color: '#868199',
        fontSize: Dimensions.get('window').height / 70,
        marginBottom: 15
    },

    info: {
        color: '#0D0334',
        fontWeight: 'bold',
        fontSize: Dimensions.get('window').height / 75,
        maxWidth: '70%'
    }
});

export default styles;
