import * as React from 'react';
import { View, Text, TouchableHighlight, Image } from 'react-native';
import styles from './styles.web';

const QuestionRow = ({ text, selected, onPress, disabled, correctAnswer, index }) => {
    return (
        <View style={styles.buttonView}>
            <TouchableHighlight
                style={[
                    styles.buttonWeb,
                    selected && correctAnswer.text === text
                        ? styles.correctButton
                        : selected && correctAnswer.text !== text && styles.incorrectButton,
                    correctAnswer.text === text &&
                        (index || index === 0) &&
                        styles.correctNonSelectedButton
                ]}
                onPress={onPress}
                underlayColor="#cac7c6"
                disabled={!!disabled}>
                <>
                    <Text
                        style={[
                            styles.buttonText,
                            selected && correctAnswer.text === text
                                ? styles.correctText
                                : selected && correctAnswer.text !== text && styles.incorrectText
                        ]}>
                        {text}
                    </Text>
                    {correctAnswer.text === text && (index || index === 0) && (
                        <Image
                            style={{
                                resizeMode: 'contain',
                                height: '3vh',
                                width: '3vw',
                                marginRight: 10
                            }}
                            source={require('../../../../../assets/images/PracticeZone/PNG/ttp-q-correct.png')}
                        />
                    )}
                    {selected && correctAnswer.text !== text && (
                        <Image
                            style={{
                                resizeMode: 'contain',
                                height: '3vh',
                                width: '3vw',
                                marginRight: 10
                            }}
                            source={require('../../../../../assets/images/PracticeZone/PNG/ttp-q-wrong.png')}
                        />
                    )}
                </>
            </TouchableHighlight>
        </View>
    );
};

const QuestionBlock = ({
    questionOne,
    questionTwo,
    questionThree,
    questionFour,
    index,
    setIndex,
    correctAnswer
}) => {
    return (
        <View style={{ width: '100%' }}>
            <QuestionRow
                text={questionOne}
                onPress={() => setIndex(0)}
                selected={index == 0}
                disabled={index}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionTwo}
                onPress={() => setIndex(1)}
                selected={index == 1}
                disabled={index}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionThree}
                onPress={() => setIndex(2)}
                selected={index == 2}
                disabled={index}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionFour}
                onPress={() => setIndex(3)}
                selected={index == 3}
                disabled={index}
                correctAnswer={correctAnswer}
                index={index}
            />
        </View>
    );
};

export default QuestionBlock;
