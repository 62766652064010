import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    touchableContainer: {
        width: '100%',
        marginBottom: 20,
        backgroundColor: 'white',
        borderRadius: 10
    },

    touchableContainerWeb: {
        width: '20vw',
        marginBottom: 20,
        marginRight: 20,
        backgroundColor: 'white',
        borderRadius: 10
    },

    touchableContainerWebFull: {
        width: '90vw',
        marginBottom: 20,
        marginRight: 20,
        backgroundColor: 'white',
        borderRadius: 10
    },

    heading: {
        color: '#0D0334',
        fontSize: 18,
        fontWeight: 'bold'
    },

    headingWeb: {
        color: '#0D0334',
        fontSize: Dimensions.get('window').height / 70,
        fontWeight: 'bold'
    },

    text: {
        color: '#868199',
        fontSize: 14
    },

    textWeb: {
        color: '#868199',
        fontSize: Dimensions.get('window').height / 80
    },

    arrowContainer: {
        marginLeft: 'auto',
        alignSelf: 'center',
        paddingRight: 20
    },

    arrow: {
        width: 30,
        height: 30,
        resizeMode: 'contain'
    },

    iconContainer: {
        marginLeft: 5,
        justifyContent: 'center'
    },

    textContainer: {
        margin: 10,
        flexShrink: 1
    },

    container: { flexDirection: 'row' }
});

export default styles;
