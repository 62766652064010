import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    mainContainer: {
        padding: 36,
        backgroundColor: '#fff',
        alignItems: 'center'
    },

    dialTextTitle: {
        color: '#0D0334',
        fontSize: 50,
        fontWeight: 'bold',
        marginTop: 0
    },

    dialTextPassFail: {
        fontSize: 15,
        fontWeight: 'bold'
    },

    dialTextDetails: {
        textAlign: 'center',
        color: '#868199',
        marginTop: 100,
        marginHorizontal: 20
    },

    queryTest: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        color: '#0D0334',
        marginVertical: 20
    }
});

export default styles;
