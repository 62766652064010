import React, { useEffect, useState } from 'react';
import { View, Text, TouchableHighlight } from 'react-native';
import Video from 'react-player';
import styles from './styles.web';
import { CASE_STUDY_VIDEO } from '../../../../API/Environment.web';

const QuestionRow = ({ text, selected, onPress, disabled }) => {
    return (
        <View style={styles.buttonView}>
            <TouchableHighlight
                style={[styles.button, selected && styles.selectedButton]}
                onPress={onPress}
                underlayColor="#cac7c6"
                disabled={!!disabled}>
                <>
                    <Text style={[styles.buttonText, selected && styles.selectedText]}>{text}</Text>
                </>
            </TouchableHighlight>
        </View>
    );
};

const CaseStudyQuestion = ({
    questionOne,
    questionTwo,
    questionThree,
    questionFour,
    correctAnswer,
    index,
    setIndex,
    videoRef
}) => {
    const [videoURL, setVideoURL] = useState();

    useEffect(() => {
        (async () => {
            const URL = `${CASE_STUDY_VIDEO}${videoRef}.mp4`;
            setVideoURL(URL);
        })();
    }, [videoRef]);

    return (
        <View style={{ width: '100%' }}>
            <View style={styles.videoView}>
                {videoURL && (
                    <Video
                        url={videoURL}
                        style={{ alignSelf: 'center', marginVertical: 20 }}
                        playing={true}
                        playsinline={true}
                        loop={true}
                        width="60vw"
                        height="30vw"
                    />
                )}
            </View>

            <QuestionRow
                text={questionOne}
                onPress={() => setIndex(0)}
                selected={index == 0}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionTwo}
                onPress={() => setIndex(1)}
                selected={index == 1}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionThree}
                onPress={() => setIndex(2)}
                selected={index == 2}
                correctAnswer={correctAnswer}
                index={index}
            />

            <QuestionRow
                text={questionFour}
                onPress={() => setIndex(3)}
                selected={index == 3}
                correctAnswer={correctAnswer}
                index={index}
            />
        </View>
    );
};

export default CaseStudyQuestion;
