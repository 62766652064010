import * as Joomla from './Joomla.web';

export async function fetchUserQuestionsCategoryList() {
    const userQuestions = await Joomla.fetchUserQuestions();
    var userQDict = {};

    userQuestions.forEach((userQuestion) => {
        if (userQuestion.correct === '1') {
            if (userQDict[userQuestion.category]) {
                userQDict[userQuestion.category].push(userQuestion);
            } else {
                userQDict[userQuestion.category] = [userQuestion];
            }
        }
    });

    return userQDict;
}

export async function fetchRandomQuestions(noOfQuestions, caseStudy = false) {
    const res = await Joomla.fetchQuestions();
    var questions = [];
    var allCaseStudyQuestions = [];
    var duplicateCheck = [];

    if (caseStudy) {
        noOfQuestions -= 3;
        for (var i = 0; i < res.length; i++) {
            const q = res[Math.floor(Math.random() * res.length)];
            if (duplicateCheck.includes(q.id)) {
                i--;
            } else if (res[i].casestudy === '1') {
                allCaseStudyQuestions.push(res[i]);
            } else if (questions.length < noOfQuestions && q.casestudy != '1') {
                duplicateCheck.push(q.id);
                questions.push(q);
            }
        }

        var groupedCaseStudies = {};
        allCaseStudyQuestions.forEach((question) => {
            if (groupedCaseStudies[question.id] || groupedCaseStudies[question.linkquestion]) {
                if (question.linkquestion === '0') {
                    groupedCaseStudies[question.id].push(question);
                } else {
                    groupedCaseStudies[question.linkquestion].push(question);
                }
            } else {
                if (question.linkquestion === '0') {
                    groupedCaseStudies[question.id] = [question];
                } else {
                    groupedCaseStudies[question.linkquestion] = [question];
                }
            }
        });
        const values = Object.values(groupedCaseStudies);
        const randomCaseStudy = values[Math.floor(Math.random() * values.length)];
        questions = questions.concat(randomCaseStudy);
    } else {
        for (var i = 0; i < noOfQuestions; i++) {
            const q = res[Math.floor(Math.random() * res.length)];
            if (duplicateCheck.includes(q.id)) {
                i--;
            } else {
                questions.push(q);
            }
        }
    }

    return questions;
}

export async function fetchCategoriesWithoutRoadsigns() {
    var categories = await Joomla.fetchCategories();
    categories = categories.filter((category) => category.categorytype != '1');

    return categories;
}

export async function fetchCategoryType(categoryIds) {
    const res = await Joomla.fetchCategories();
    const categories = [];

    res.forEach((category) => {
        if (categoryIds.includes(parseInt(category.categorytype))) {
            categories.push(category);
        }
    });

    return categories;
}

export async function fetchRoadsignsForCategories(categoryIds) {
    const res = await Joomla.fetchRoadsigns();
    const roadsigns = [];

    res.forEach((roadsign) => {
        if (categoryIds.includes(parseInt(roadsign.category))) {
            roadsigns.push(roadsign);
        }
    });

    return roadsigns;
}

export async function fetchCategoriesWithoutRoadsignsOrEmpties() {
    const fetchedCategories = await Joomla.fetchCategories();
    const questions = await Joomla.fetchQuestions();
    var includedCategories = [];
    var categories = [];

    questions.forEach((question) => {
        if (!includedCategories.includes(question.category)) {
            includedCategories.push(question.category);
        }
    });

    fetchedCategories.forEach((category) => {
        if (includedCategories.includes(category.id) && categories.categorytype != '1') {
            categories.push(category);
        }
    });

    return categories;
}

export async function getUserTestType(userTests, testType) {
    if (userTests === null) {
        userTests = await Joomla.fetchUserTests();
    }

    var sortedUserTests = [];

    if (!userTests) {
        return;
    } else {
        userTests.forEach((test) => {
            if (test.testtype == testType) {
                sortedUserTests.push(test);
            }
        });
        return sortedUserTests;
    }
}

export async function fetchAveragePercentageOfTests(tests) {
    const average = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
    var percentageArray = [];

    if (!tests) {
        return 'NaN';
    } else {
        tests.map((test) => {
            var percentage = 0;
            if (test.correctanswers != 0 && test.totalquestions != 0) {
                percentage = (test.correctanswers / test.totalquestions).toFixed(2) * 100;
            }

            percentageArray.push(percentage);
        });

        return average(percentageArray).toFixed(0);
    }
}

export async function fetchGroupedHazardClips() {
    var hazards = await Joomla.fetchHazards();
    var hazardDict = {};

    hazards.forEach((hazard) => {
        if (hazard.category === '0') {
            if (hazardDict[hazard.category]) {
                hazardDict[hazard.category].push(hazard);
            } else {
                hazardDict[hazard.category] = [hazard];
            }
        }
    });

    return hazardDict;
}

export async function fetchCategoryNameMapping() {
    var categories = await Joomla.fetchCategories();
    var categoryDict = {};

    categories.forEach((category) => {
        categoryDict[category.id] = category.name;
    });

    return categoryDict;
}

export async function fetchHazardClips(numberOfClips) {
    var fetchedHazards = await Joomla.fetchHazards();

    var hazards = [];
    var hazardIDs = [];

    for (var i = 0; i < numberOfClips; i++) {
        const q = fetchedHazards[Math.floor(Math.random() * fetchedHazards.length)];
        if (q.category === '0' && !hazardIDs.includes(q.id)) {
            hazardIDs.push(q.id);
            hazards.push(q);
        } else {
            i--;
        }
    }

    return hazards;
}

export async function saveTest(
    userScore,
    totalQuestions,
    questionsTracker,
    questionFeedbackBreakdown,
    id
) {
    if (((userScore / totalQuestions) * 100).toFixed(0) < 50) {
        await Joomla.addTestResult(
            localStorage.getItem('userid'),
            localStorage.getItem('session'),
            totalQuestions,
            userScore,
            questionFeedbackBreakdown,
            '1',
            `${id}`
        );
    } else {
        await Joomla.addTestResult(
            localStorage.getItem('userid'),
            localStorage.getItem('session'),
            totalQuestions,
            userScore,
            questionFeedbackBreakdown,
            '0',
            `${id}`
        );
    }
    await Joomla.addAnswers(
        localStorage.getItem('userid'),
        localStorage.getItem('session'),
        questionsTracker
    );
}

export async function getCorrectlyAnsweredQuestions(userTests, testType) {
    const userQuestions = await Joomla.fetchUserQuestions();
    const totalQuestions = await Joomla.fetchQuestions();
    var correctUserQuestions = [];

    userQuestions.map((question) => {
        if (question.correct === '1') {
            correctUserQuestions.push(question);
        }
    });

    return totalQuestions.length - correctUserQuestions.length;
}

export async function fetchPreviouslyWrongQuestions(questionPool) {
    const userQuestions = await Joomla.fetchUserQuestions();
    var questions = [];

    questionPool.forEach((question, i) => {
        for (var x = 0; x < userQuestions.length; x++) {
            if (
                userQuestions[x].question === questionPool[i].id &&
                userQuestions[x].correct === '0'
            ) {
                questions.push(questionPool[i]);
                break;
            }
        }
    });

    questions = questions.sort(() => Math.random() - 0.5);

    return questions;
}

export async function fetchQuestionsForCategories(categoryIds) {
    const res = await Joomla.fetchQuestions();
    var questions = [];

    res.forEach((question) => {
        if (categoryIds.includes(question.category)) {
            questions.push(question);
        }
    });

    questions = questions.sort(() => Math.random() - 0.5);

    return questions;
}

export async function fetchUnseenQuestions(questionPool) {
    const userQuestions = await Joomla.fetchUserQuestions();
    var questions = [];

    questionPool.forEach((question, i) => {
        for (var x = 0; x < userQuestions.length; x++) {
            if (userQuestions[x].question === questionPool[i].id) {
                break;
            } else if (x + 1 === userQuestions.length) {
                questions.push(questionPool[i]);
            }
        }
    });

    questions = questions.sort(() => Math.random() - 0.5);

    return questions;
}

export async function fetchCategoriesForRelevantTestType() {
    var fetchedCategories;

    fetchedCategories = await Joomla.fetchCategories();

    const questions = await Joomla.fetchQuestions();
    const categoryDict = {};
    const categories = [];

    questions.forEach((question) => {
        if (categoryDict[question.category]) {
            categoryDict[question.category].push(question);
        } else {
            categoryDict[question.category] = [question];
        }
    });

    fetchedCategories.forEach((category) => {
        if (Object.keys(categoryDict).includes(category.id)) {
            categories.push([category, categoryDict[category.id].length]);
        }
    });

    return categories;
}

export function formatQuestionsPerDay(questionsPerDay) {
    const formattedQuestionsPerDay =
        questionsPerDay && questionsPerDay === 0
            ? `IT'S TEST DAY`
            : questionsPerDay && questionsPerDay < 0
            ? `HOW DID IT GO?`
            : questionsPerDay
            ? `${questionsPerDay} QUESTIONS PER DAY`
            : 'TEST DATE NOT SET';

    return formattedQuestionsPerDay;
}

export function formatDaysUntilTest(daysUntilTest) {
    const formattedDaysUntilTest =
        daysUntilTest && daysUntilTest === 0
            ? `IT'S TEST DAY`
            : daysUntilTest && daysUntilTest < 0
            ? `HOW DID IT GO?`
            : daysUntilTest
            ? `${daysUntilTest} DAYS UNTIL TEST`
            : 'TEST DATE NOT SET';

    return formattedDaysUntilTest;
}

export function notNumbersOrSpecialChars(str) {
    const pattern = /[0-9~`!@#$%^&*()\-_=+\[\]{}|;:'",<.>\/?]/;
    return !pattern.test(str);
}

export function calculatePPPercentage(status) {
    delete status.totalPercentage;
    const requirements = Object.values(status);
    const numOfCompleted = requirements.reduce(
        (total, singleValue) => total + singleValue.status,
        0
    );

    return { requirements, numOfCompleted };
}
