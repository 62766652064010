import React, { useEffect, useState, useRef } from 'react';
import { View, Text, Animated, Modal, Image, Dimensions } from 'react-native';
import { useHistory, Link } from 'react-router-dom';
import styles from './styles.web';

import PrimaryTextInput from '../../../Components/TextInput/Primary';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton';

import { reset } from '../../../API/Joomla.web';

const PasswordScreen = () => {
    const [email, setEmail] = useState('');
    const [errorVisible, setErrorVisible] = useState(false);
    const [successVisible, setSuccessVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const history = useHistory();

    function resetHandler(email) {
        reset(email)
            .then(() => {
                setSuccessVisible(true);
            })
            .catch((error) => {
                setErrorMessage(error), setErrorVisible(true);
            });
    }

    const ModalPoup = ({ visible, children }) => {
        const [showModal, setShowModal] = useState(visible);
        const scaleValue = useRef(new Animated.Value(0)).current;
        useEffect(() => {
            toggleModal();
        }, [visible]);
        const toggleModal = () => {
            if (visible) {
                setShowModal(true);
                Animated.spring(scaleValue, {
                    toValue: 1,
                    duration: 300,
                    useNativeDriver: true
                }).start();
            } else {
                setTimeout(() => setShowModal(false), 200);
                Animated.timing(scaleValue, {
                    toValue: 0,
                    duration: 300,
                    useNativeDriver: true
                }).start();
            }
        };

        return (
            <Modal transparent visible={showModal}>
                <View style={styles.modalBackGround}>
                    <Animated.View
                        style={[styles.modalContainer, { transform: [{ scale: scaleValue }] }]}>
                        {children}
                    </Animated.View>
                </View>
            </Modal>
        );
    };

    return (
        <View style={styles.container}>
            <ModalPoup visible={errorVisible}>
                <View style={{ alignItems: 'center' }}>
                    <Image
                        style={styles.trialFinished}
                        source={require('../../../../assets/images/Popup/PNG/trial-finished.png')}
                    />
                </View>

                <Text style={styles.oops}>Oops!</Text>

                <Text style={styles.errorMessage}>{errorMessage}</Text>

                <PrimaryButton text="Try again" onPress={() => setErrorVisible(false)} />
            </ModalPoup>

            <ModalPoup visible={successVisible}>
                <View style={{ alignItems: 'center' }}>
                    <Image
                        style={{ width: 150, height: 150, resizeMode: 'contain' }}
                        source={require('../../../../assets/images/Popup/PNG/getting-started.png')}
                    />
                </View>

                <Text style={styles.startingLineText}>We're off the starting line!</Text>

                <Text style={styles.resetEmailText}>
                    The reset email has been sent. Please follow the instructions, get back in
                    neutral and try to start the ignition again!
                </Text>

                <PrimaryButton
                    text="Log In"
                    onPress={() => {
                        setSuccessVisible(false), history.push('/login');
                    }}
                />
            </ModalPoup>

            <Image
                style={styles.logoImage}
                source={require('../../../../assets/images/Login-Signup-Dashboard/Welcome/ttp-logo.png')}
            />

            <Text style={styles.pageTitle}> Forgotten Password </Text>

            <Text style={styles.text}>
                {' '}
                It looks like you've forgotten your password.. No worries!{' '}
            </Text>

            <Text style={styles.text}>
                {' '}
                Enter your email below and we'll get you back on the road in no time!{' '}
            </Text>

            <PrimaryTextInput
                label={'Email Address'}
                value={email}
                onChangeText={(val) => setEmail(val)}
                customStyle={{
                    fontSize: Dimensions.get('window').height / 60,
                    width: '75%'
                }}
            />

            <PrimaryButton
                text="Send reset email"
                onPress={() => resetHandler(email)}
                disabled={![email].every(Boolean)}
                textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                style={{ width: '75%' }}
            />

            <Link to="/" style={{ marginTop: 20, padding: 10, borderRadius: 10 }}>
                <Text style={styles.secondaryButtonText}> Go Back </Text>
            </Link>
        </View>
    );
};

export default PasswordScreen;
