import React from 'react';
import { Text, TouchableHighlight } from 'react-native';
import { useHistory } from 'react-router-dom';
import styles from './styles';

const PrimaryLink = ({ text, link, onPress, customStyle, textStyle }) => {
    const history = useHistory();

    return (
        <TouchableHighlight
            style={[styles.primaryButton, customStyle]}
            onPress={() => history.push(link)}>
            <Text style={[styles.primaryButtonText, textStyle]}> {text} </Text>
        </TouchableHighlight>
    );
};

export default PrimaryLink;
