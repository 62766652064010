import React from 'react';
import { View, Text } from 'react-native';
import styles from './styles';

import RNSpeedometer from 'react-native-speedometer';

const Dial = ({ scorePercentage, size, prompt = true, noNote = false, noPercentage = false }) => {
    return (
        <View style={styles.mainContainer}>
            <RNSpeedometer
                value={scorePercentage === 'Infinity' ? 100 : scorePercentage}
                size={size || 300}
                labels={[
                    {
                        name: 'Unlucky',
                        labelColor: '#F0040F',
                        activeBarColor: '#F0040F'
                    },
                    {
                        name: 'Try again',
                        labelColor: '#ff5400',
                        activeBarColor: '#ff5400'
                    },
                    {
                        name: 'You are getting there',
                        labelColor: '#f4ab44',
                        activeBarColor: '#f4ab44'
                    },
                    {
                        name: 'Well done',
                        labelColor: '#f2cf1f',
                        activeBarColor: '#f2cf1f'
                    },
                    {
                        name: 'Brilliant',
                        labelColor: '#14eb6e',
                        activeBarColor: '#14eb6e'
                    },
                    {
                        name: 'Perfect',
                        labelColor: '#8CC63F',
                        activeBarColor: '#8CC63F'
                    }
                ]}
                labelStyle={[styles.dialTextTitle, noPercentage && { display: 'none' }]}
                labelNoteStyle={[styles.dialTextPassFail, noNote && { display: 'none' }]}
            />

            {prompt && (
                <Text style={styles.dialTextDetails}>
                    We've updated your dashboard with these results to help you get started!
                </Text>
            )}
        </View>
    );
};

export default Dial;
