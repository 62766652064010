import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    headingText: {
        paddingBottom: 15,
        fontWeight: 'bold',
        fontSize: 24,
        alignSelf: 'flex-start',
        color: '#0D0334',
        marginTop: 20
    },

    modalBackGround: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center'
    },

    modalContainer: {
        width: '80%',
        backgroundColor: 'white',
        paddingHorizontal: 20,
        paddingVertical: 30,
        borderRadius: 20,
        elevation: 20
    },

    selectionContainer: {
        flexDirection: 'row',
        width: '95%',
        height: '9%',
        marginTop: 5
    },

    selectionText: {
        fontSize: Dimensions.get('window').height / 60
    }
});

export default styles;
