import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1
    },

    pageContainer: {
        justifyContent: 'center'
    },

    logoImage: {
        width: 100,
        height: 100,
        marginBottom: 60,
        resizeMode: 'contain'
    },

    spinnerImage: {
        width: 75,
        height: 75,
        marginBottom: 10,
        resizeMode: 'contain',
        alignItems: 'center',
        justifyContent: 'center'
    },

    download: {
        alignItems: 'center',
        justifyContent: 'center',
        margin: 20
    },

    downloadList: {
        alignItems: 'center',
        justifyContent: 'center',
        margin: 2,
        fontSize: 11
    },

    webLoading: {
        height: '100vh',
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 36,
        flex: 1
    }
});

export default styles;
