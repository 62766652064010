import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    buttonView: {
        backgroundColor: '#fff',
        borderRadius: 10,
        marginBottom: 15,
        alignItems: 'center',
        justifyContent: 'center',
        width: '48%',
        overflow: 'hidden'
    },

    buttonWebView: {
        backgroundColor: '#fff',
        borderRadius: 10,
        marginBottom: 10,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'center',
        width: '33%',
        overflow: 'hidden'
    },

    buttonStyle: {
        borderRadius: 10,
        padding: 20,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },

    buttonInnerView: {
        alignItems: 'center'
    },

    buttonText: {
        paddingTop: 15,
        color: '#000',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center'
    },

    banner: {
        position: 'absolute',
        right: -65,
        top: -3,
        width: 160,
        transform: [{ rotate: '45deg' }],
        color: 'white',
        backgroundColor: '#F0040F',
        fontWeight: 'bold',
        padding: 12,
        paddingBottom: 6,
        textAlign: 'center'
    }
});

export default styles;
