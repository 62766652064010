import React, { useState } from 'react';

import { AuthContext, useAuth } from '../Context/AuthContext';

const AuthProvider = (props) => {
    const [user, setUser] = useState(null);

    const saveUser = (email, loginDetails, subscriptionDetails) => {
        if (![email, loginDetails, subscriptionDetails].every(Boolean)) {
            return;
        }

        const trial = subscriptionDetails.paymentplan === '1' ? subscriptionDetails.trial : 'No';

        updateLocalStorage('username', email);
        updateLocalStorage('trial', trial);

        const urlParams = `?userid=${loginDetails.userid}&testtype=${loginDetails.testtype}`;

        Object.entries(subscriptionDetails).forEach(([key, value]) => {
            updateLocalStorage(key, value);
        });

        Object.entries(loginDetails).forEach(([key, value]) => {
            updateLocalStorage(key, value);
        });

        const userDetails = { ...loginDetails, ...subscriptionDetails, email, trial, urlParams };

        setUser(userDetails);
    };

    const updateLocalStorage = (key, value) => {
        localStorage.setItem(key, String(value));
    };

    const clearUser = () => {
        localStorage.clear();
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, saveUser, clearUser }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export { AuthContext, useAuth };

export default AuthProvider;
