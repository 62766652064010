import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, FlatList } from 'react-native';
import styles from './styles.web';
import { useHistory } from 'react-router-dom';

import ProgressBar from '@ramonak/react-progress-bar';

import CategoryIcon from '../../../Components/CategoryIcons';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton';
import Dial from '../../../Components/Dial';
import LoadingState from '../../../Components/LoadingState';

import {
    fetchUserQuestionsCategoryList,
    fetchAveragePercentageOfTests,
    getUserTestType,
    fetchCategoriesWithoutRoadsignsOrEmpties
} from '../../../API/RealmComms.web';

import { useScreenSize } from '../../../Utilities/utils';

const CategoryBox = ({ category, userQuestions }) => {
    const screenSize = useScreenSize();

    var percentage = 0;

    if (!userQuestions) {
        userQuestions = [];
    }

    const totalTestQuestion =
        localStorage.getItem('testtype') === '0'
            ? category.totalcarquestions
            : localStorage.getItem('testtype') === '1'
            ? category.totalmotorcyclequestions
            : category.totalhgvquestions;

    if (totalTestQuestion === 0 || userQuestions.length === 0) {
        percentage = 0;
    } else {
        percentage = (userQuestions.length / totalTestQuestion).toFixed(2);
    }

    return (
        <View
            style={[
                styles.categoryBoxButtonWeb,
                {
                    width: screenSize.large ? '50%' : '31%',
                    height: screenSize.large ? 220 : 160
                }
            ]}
            disabled>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CategoryIcon categoryName={category.name} size={20} />
                <Text
                    numberOfLines={1}
                    style={[
                        styles.categoryNameWeb,
                        {
                            fontSize: screenSize.large ? 30 : 14,
                            marginLeft: 5
                        }
                    ]}>
                    {category.name}
                </Text>
            </View>

            <View style={styles.percentageBar}>
                <ProgressBar
                    animateOnRender={true}
                    completed={Number(percentage * 100).toFixed(0)}
                    customLabel={`${Number(percentage * 100).toFixed(0)}%`}
                    bgColor="#11AD04"
                    baseBgColor="#F3F2F5"
                    height="18"
                    width={'90%'}
                />
            </View>

            <Text
                style={[styles.categoryAnsweredQuestions, { fontSize: screenSize.large ? 24 : 14 }]}
                numberOfLines={1}>
                {userQuestions.length}/{totalTestQuestion} answered correctly
            </Text>
        </View>
    );
};

const ResultsBox = ({ testType, onPress, scorePercentage }) => {
    const screenSize = useScreenSize();

    return (
        <View style={styles.ResultsBoxView}>
            <Dial
                scorePercentage={scorePercentage}
                size={screenSize.large ? '260' : '140'}
                prompt={false}
                noNote={true}
                noPercentage={true}
            />

            {scorePercentage === 'NaN' ? (
                <Text style={styles.percentageTextWeb}>No Tests</Text>
            ) : (
                <Text style={styles.percentageTextWeb}>{scorePercentage}%</Text>
            )}

            <Text style={[styles.avgScoreText, { fontSize: screenSize.large ? 24 : 10 }]}>
                AVG. {testType} SCORE
            </Text>

            <PrimaryButton
                text={`View ${testType.toLowerCase()} results`}
                onPress={onPress}
                style={{ backgroundColor: '#0D0334', minWidth: '80%' }}
                textStyle={{ fontSize: screenSize.large ? 30 : 14 }}
                noOfLines={1}
            />
        </View>
    );
};

const ProgressPage = () => {
    const history = useHistory();
    const screenSize = useScreenSize();

    const [categories, setCategories] = useState();
    const [userQuestionList, setUserQuestionList] = useState();
    const [mockTestAverage, setMockTestAverage] = useState();
    const [hazardTestAverage, setHazardTestAverage] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const categories = await fetchCategoriesWithoutRoadsignsOrEmpties();
            var userCategoryQuestions = await fetchUserQuestionsCategoryList();
            const mockUserTests = await getUserTestType(null, '0');
            const hazardUserTests = await getUserTestType(null, '3');
            var averageMockTestPercentage = await fetchAveragePercentageOfTests(mockUserTests);
            var averageHazardTestPercentage = await fetchAveragePercentageOfTests(hazardUserTests);

            setMockTestAverage(averageMockTestPercentage);
            setHazardTestAverage(averageHazardTestPercentage);
            setUserQuestionList(userCategoryQuestions);
            setCategories(categories);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <LoadingState />;
    } else if (screenSize.large) {
        return (
            <View style={styles.container}>
                <ScrollView style={styles.scrollContainer}>
                    <Text style={[styles.pageTitle, { fontSize: screenSize.large ? 30 : 20 }]}>
                        By Category
                    </Text>

                    <Text style={{ color: '#868199', fontSize: 24 }}>
                        Track your progress and focus your revision. This is an overview of all our
                        categories.
                    </Text>

                    <View style={styles.pageContainerWebMobile}>
                        <View style={{ flexDirection: 'column', width: '100%' }}>
                            <FlatList
                                data={categories}
                                keyExtractor={(category) => category.id}
                                numColumns={2}
                                showsHorizontalScrollIndicator={true}
                                renderItem={({ item }) => (
                                    <CategoryBox
                                        category={item}
                                        userQuestions={userQuestionList[item.id]}
                                    />
                                )}
                            />
                        </View>

                        <View style={styles.dialView}>
                            <ResultsBox
                                testType={'Mock Test'}
                                onPress={() => history.push('/progress/mocktest')}
                                scorePercentage={mockTestAverage}
                            />

                            <ResultsBox
                                testType={'Hazard Perception'}
                                onPress={() => history.push('/progress/hazard')}
                                scorePercentage={hazardTestAverage}
                            />
                        </View>
                    </View>
                </ScrollView>
            </View>
        );
    }
    return (
        <View style={styles.container}>
            <ScrollView style={styles.scrollContainer}>
                <Text style={styles.pageTitle}>By Category</Text>

                <Text style={{ color: '#868199' }}>
                    Track your progress and focus your revision. This is an overview of all our
                    categories.
                </Text>

                <View style={styles.pageContainerWeb}>
                    <View style={{ flexDirection: 'column', width: '70%' }}>
                        <FlatList
                            data={categories}
                            keyExtractor={(category) => category.id}
                            numColumns={3}
                            showsHorizontalScrollIndicator={true}
                            renderItem={({ item }) => (
                                <CategoryBox
                                    category={item}
                                    userQuestions={userQuestionList[item.id]}
                                />
                            )}
                        />
                    </View>

                    <View style={{ flexDirection: 'column', width: '30%' }}>
                        <ResultsBox
                            testType={'Mock Test'}
                            onPress={() => history.push('/progress/mocktest')}
                            scorePercentage={mockTestAverage}
                        />

                        <ResultsBox
                            testType={'Hazard Perception'}
                            onPress={() => history.push('/progress/hazard')}
                            scorePercentage={hazardTestAverage}
                        />
                    </View>
                </View>
            </ScrollView>
        </View>
    );
};

export default ProgressPage;
