import React, { useEffect, useState } from 'react';
import { View, Image, Pressable, TouchableHighlight, Text, Dimensions } from 'react-native';
import { TextInput } from 'react-native-paper';
import styles from './styles.web';

import SelectionButton from '../../../Components/Buttons/SelectionButton';
import PrimaryTextInput from '../../../Components/TextInput/Primary';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton';
import LoadingState from '../../../Components/LoadingState';
import PopupModal from '../../../Components/Popup';

import { updateProfile, login, updateTestDate } from '../../../API/Joomla.web';
import moment from 'moment';
import { formatISO9075 } from 'date-fns';

const EditProfileScreen = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [feedbackMessageTitle, setFeedbackMessageTitle] = useState('');
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [errorVisible, setErrorVisible] = useState(false);
    const [successVisible, setSuccessVisible] = useState(false);
    const [dateBooked, setDateBooked] = useState(new Date());
    const [isDateBooked, setIsDateBooked] = useState(false);
    const [loading, setLoading] = useState(true);
    const [invalidDate, setInvalidDate] = useState(false);

    useEffect(() => {
        (async () => {
            setFirstName(localStorage.getItem('firstname'));
            setLastName(localStorage.getItem('lastname'));
            setLoading(false);
        })();
    }, []);

    function updateHandler(userid, username, firstname, lastname, oldPassword, password, token) {
        setLoading(true);
        login(username, oldPassword)
            .then(() => {
                updateProfile(username, userid, firstname, lastname, password, token)
                    .then(() => {
                        setSuccessVisible(true);
                        setLoading(false);
                    })
                    .catch((error) => {
                        setFeedbackMessageTitle('Error');
                        setFeedbackMessage(error);
                        setErrorVisible(true);
                        setLoading(false);
                    });
            })
            .catch((error) => {
                setFeedbackMessageTitle('Error');
                setFeedbackMessage(error);
                setErrorVisible(true);
                setLoading(false);
            });
    }

    if (loading) {
        return <LoadingState />;
    } else {
        return (
            <>
                <PopupModal
                    visible={errorVisible}
                    setVisible={setErrorVisible}
                    image={
                        <Image
                            style={{
                                width: 150,
                                height: 150,
                                marginVertical: 10,
                                resizeMode: 'contain'
                            }}
                            source={require('../../../../assets/images/Popup/SVG/trial-finished.svg')}
                        />
                    }
                    title={setFeedbackMessage}
                    secondaryText={feedbackMessage}
                    buttonText={'Got it!'}
                    onPress={() => setErrorVisible(false)}
                />

                <PopupModal
                    visible={successVisible}
                    setVisible={setSuccessVisible}
                    image={
                        <Image
                            style={{
                                width: 150,
                                height: 150,
                                marginVertical: 10,
                                resizeMode: 'contain'
                            }}
                            source={require('../../../../assets/images/Popup/SVG/trial-finished.svg')}
                        />
                    }
                    title={'Update Successful'}
                    secondaryText={
                        'Your profile updated successfully, your details have been saved to our system.'
                    }
                    buttonText={'Back to profile'}
                    buttonLink={'/profile'}
                />

                <PopupModal
                    visible={invalidDate}
                    setVisible={setInvalidDate}
                    title={'Invalid Date'}
                    secondaryText={'Date must formatted DD/MM/YYYY'}
                    buttonText={'Close popup'}
                    onPress={() => setInvalidDate(false)}
                />

                <View style={styles.containerWeb}>
                    <PrimaryTextInput
                        label={'First name'}
                        value={firstName}
                        onChangeText={(val) => setFirstName(val)}
                        customStyle={{
                            fontSize: Dimensions.get('window').height / 60,
                            width: '75%'
                        }}
                    />

                    <PrimaryTextInput
                        label={'Last name'}
                        value={lastName}
                        onChangeText={(val) => setLastName(val)}
                        customStyle={{
                            fontSize: Dimensions.get('window').height / 60,
                            width: '75%'
                        }}
                    />

                    <PrimaryTextInput
                        label={'Old Password*'}
                        value={oldPassword}
                        onChangeText={(val) => setOldPassword(val)}
                        secureTextEntry={true}
                        customStyle={{
                            fontSize: Dimensions.get('window').height / 60,
                            width: '75%'
                        }}
                    />

                    <PrimaryTextInput
                        label={'New Password*'}
                        value={password}
                        onChangeText={(val) => setPassword(val)}
                        secureTextEntry={true}
                        customStyle={{
                            fontSize: Dimensions.get('window').height / 60,
                            width: '75%'
                        }}
                    />

                    <PrimaryTextInput
                        label={'Confirm New Password*'}
                        value={confirmPassword}
                        onChangeText={(val) => setConfirmPassword(val)}
                        secureTextEntry={true}
                        customStyle={{
                            fontSize: Dimensions.get('window').height / 60,
                            width: '75%'
                        }}
                    />

                    <Text style={styles.testDateText}>
                        Would you like to change your test date?{' '}
                    </Text>

                    <SelectionButton
                        text="Yes"
                        onPress={() => setIsDateBooked(true)}
                        selected={isDateBooked}
                        textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                        style={{ width: '50%' }}
                    />

                    <SelectionButton
                        text="No"
                        onPress={() => setIsDateBooked(false)}
                        selected={!isDateBooked}
                        textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                        style={{ width: '50%' }}
                    />

                    {isDateBooked && (
                        <View style={{ width: '100%', alignItems: 'center' }}>
                            <Text style={styles.headingText}>
                                {' '}
                                Please enter your new test date.{' '}
                            </Text>

                            <Pressable
                                style={styles.dateFieldView}
                                onPress={() => {
                                    openDatePicker
                                        ? setOpenDatePicker(false)
                                        : setOpenDatePicker(true);
                                }}>
                                <TextInput
                                    style={styles.input}
                                    mode="outlined"
                                    theme={{ colors: { primary: 'grey' }, roundnessBottomRight: 0 }}
                                    placeholder={moment(dateBooked).format('DD/MM/YYYY')}
                                    onChangeText={(val) => setDateBooked(val)}
                                    outlineColor={'#0D03341A'}
                                    textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                                    style={{ width: '55%' }}
                                />

                                <TouchableHighlight
                                    style={styles.dateButton}
                                    onPress={() => {
                                        openDatePicker
                                            ? setOpenDatePicker(false)
                                            : setOpenDatePicker(true);
                                    }}>
                                    <Image
                                        style={{ resizeMode: 'contain', width: 15, height: 15 }}
                                        source={require('../../../../assets/images/TrainingPlan/PNG/icon-date-field.png')}
                                    />
                                </TouchableHighlight>
                            </Pressable>
                        </View>
                    )}

                    <PrimaryButton
                        text="Update profile"
                        onPress={async () => {
                            if (isDateBooked === true) {
                                try {
                                    const splitDate = dateBooked.split('/');
                                    const formattedDate =
                                        splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0];
                                    if (Date.parse(formattedDate) < new Date()) {
                                        setFeedbackMessageTitle('Oops');
                                        setFeedbackMessage('Your test date is before today');
                                        setErrorVisible(true);
                                    } else {
                                        const dateoftest = formatISO9075(Date.parse(formattedDate));
                                        await updateTestDate(
                                            localStorage.getItem('userid'),
                                            dateoftest,
                                            localStorage.getItem('session')
                                        );
                                        setFeedbackMessageTitle('Updated');
                                        setFeedbackMessage('Your test date has been updated.');
                                        setErrorVisible(true);
                                    }
                                } catch {
                                    setInvalidDate(true);
                                }
                            }
                            if (
                                (password !== '' && confirmPassword === '') ||
                                (password === '' && confirmPassword !== '') ||
                                (password !== '' && confirmPassword !== '')
                            ) {
                                if (password === confirmPassword) {
                                    await updateHandler(
                                        localStorage.getItem('userid'),
                                        localStorage.getItem('username'),
                                        firstName,
                                        lastName,
                                        oldPassword,
                                        password,
                                        localStorage.getItem('session')
                                    );
                                } else {
                                    setFeedbackMessageTitle('Oops');
                                    setFeedbackMessage(
                                        'Please confirm your new password with a matching password.'
                                    );
                                    setErrorVisible(true);
                                }
                            } else if (
                                password === '' &&
                                confirmPassword === '' &&
                                isDateBooked === false
                            ) {
                                setFeedbackMessageTitle('Oops');
                                setFeedbackMessage('Nothing has been changed');
                                setErrorVisible(true);
                            }
                        }}
                        textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                        style={{ width: '75%', marginTop: 20 }}
                    />
                </View>
            </>
        );
    }
};

export default EditProfileScreen;
