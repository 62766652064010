import React, { useState } from 'react';
import { View, Text, ScrollView, Image, TouchableHighlight, Dimensions } from 'react-native';
import { TextInput } from 'react-native-paper';
import styles from './styles';

import PageContainer from '../../PageContainer';
import PrimaryButton from '../../Buttons/PrimaryButton';
import SelectionButton from '../../Buttons/SelectionButton';
import { Link } from 'react-router-dom';
import { updateTestDate } from '../../../API/Joomla.web';
import { formatISO9075 } from 'date-fns';
import ModalPopup from '../../Popup/index.web';

const IntroductionScreen = ({ setTrainingPlanStage, setQuestionNumber, questionNumber }) => {
    const [introductionStep, setIntroductionStep] = useState(1);
    const [isDateBooked, setIsDateBooked] = useState(false);
    const [dateBooked, setDateBooked] = useState(new Date());
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [invalidDate, setInvalidDate] = useState(false);

    if (introductionStep == 1) {
        return (
            <View style={{ flex: 1, maxWidth: '75%', margin: 'auto' }}>
                <ModalPopup
                    visible={invalidDate}
                    setVisible={setInvalidDate}
                    title={'Invalid Date'}
                    secondaryText={'Date must formatted DD/MM/YYYY'}
                    buttonText={'Close popup'}
                    onPress={() => setInvalidDate(false)}
                />

                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <PageContainer>
                        <Text style={styles.headingText}>Have you booked a date for the test?</Text>

                        <View style={{ flexDirection: 'row', width: '100%', marginTop: 5 }}>
                            <SelectionButton
                                text="Yes"
                                onPress={() => setIsDateBooked(true)}
                                selected={isDateBooked}
                                style={{ width: '48%', marginRight: 10 }}
                                textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                            />

                            <SelectionButton
                                text="No"
                                onPress={() => setIsDateBooked(false)}
                                selected={!isDateBooked}
                                style={{ width: '48%' }}
                                textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                            />
                        </View>

                        {isDateBooked && (
                            <View style={{ width: '100%' }}>
                                <Text style={styles.headingText}>Date of your test</Text>

                                <View style={styles.dateFieldView}>
                                    <TextInput
                                        style={styles.input}
                                        mode="outlined"
                                        theme={{
                                            colors: { primary: 'grey' },
                                            roundnessBottomRight: 0
                                        }}
                                        placeholder={'DD/MM/YYYY'}
                                        onChangeText={(val) => setDateBooked(val)}
                                        outlineColor={'#0D03341A'}
                                    />

                                    <TouchableHighlight
                                        style={styles.dateButton}
                                        onPress={() => {
                                            openDatePicker
                                                ? setOpenDatePicker(false)
                                                : setOpenDatePicker(true);
                                        }}>
                                        <Image
                                            style={{ resizeMode: 'contain', width: 15, height: 15 }}
                                            source={require('../../../../assets/images/TrainingPlan/PNG/icon-date-field.png')}
                                        />
                                    </TouchableHighlight>
                                </View>

                                <View style={{ paddingBottom: 30 }}></View>
                            </View>
                        )}

                        <PrimaryButton
                            text="Continue"
                            onPress={async () => {
                                if (isDateBooked) {
                                    try {
                                        const splitDate = dateBooked.split('/');
                                        const formattedDate =
                                            splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0];
                                        const dateoftest = formatISO9075(Date.parse(formattedDate));
                                        await updateTestDate(
                                            localStorage.getItem('userid'),
                                            dateoftest,
                                            localStorage.getItem('session')
                                        );
                                        setIntroductionStep(2);
                                    } catch {
                                        setInvalidDate(true);
                                    }
                                } else {
                                    setIntroductionStep(2);
                                }
                            }}
                            textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                        />

                        <Link to="/dashboard" style={{ marginTop: 32 }}>
                            <Text style={styles.secondaryButtonText}> Cancel </Text>
                        </Link>
                    </PageContainer>
                </ScrollView>
            </View>
        );
    }

    return (
        <View style={{ flex: 1, maxWidth: '75%', margin: 'auto' }}>
            <PageContainer>
                <Text style={styles.headingText}> How many questions? </Text>

                <View style={{ flexDirection: 'row', width: '100%', marginTop: 5 }}>
                    <SelectionButton
                        text="20"
                        onPress={() => setQuestionNumber(20)}
                        selected={questionNumber === 20}
                        style={{ width: '48%', marginRight: 10 }}
                        textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                    />

                    <SelectionButton
                        text="30"
                        onPress={() => setQuestionNumber(30)}
                        selected={questionNumber === 30}
                        style={{ width: '48%' }}
                        textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                    />
                </View>

                <View style={{ flexDirection: 'row', width: '100%', marginTop: 5 }}>
                    <SelectionButton
                        text="40"
                        onPress={() => setQuestionNumber(40)}
                        selected={questionNumber === 40}
                        style={{ width: '48%', marginRight: 10 }}
                        textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                    />

                    <SelectionButton
                        text="56"
                        onPress={() => setQuestionNumber(56)}
                        selected={questionNumber === 56}
                        textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                        hintStyle={{ fontSize: Dimensions.get('window').height / 90 }}
                        style={{ width: '48%' }}
                        hint={'RECOMMENDED'}
                    />
                </View>

                <PrimaryButton
                    text="Start test"
                    onPress={async () => {
                        setTrainingPlanStage(2);
                    }}
                    textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                />

                <Link to="/dashboard" style={{ marginTop: 32 }}>
                    <Text style={styles.secondaryButtonText}> Cancel </Text>
                </Link>
            </PageContainer>
        </View>
    );
};

export default IntroductionScreen;
