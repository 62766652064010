import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        backgroundColor: '#F3F2F5',
        alignItems: 'center',
        padding: 36,
        paddingTop: 15,
        flex: 1
    },

    containerScrollview: {
        height: '100%',
        width: '100%',
        backgroundColor: '#F3F2F5',
        padding: 36,
        paddingTop: 15,
        flex: 1
    },

    containerWeb: {
        height: '100%',
        width: '100%',
        backgroundColor: '#F3F2F5',
        alignItems: 'center',
        padding: 36,
        paddingTop: 36,
        flex: 1
    }
});

export default styles;
