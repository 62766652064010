import React from 'react';

import CriteriaBox from '../CriteriaBox/index.web';

const PassProtectionCriteria = ({ status, style, textStyle }) => {
    return status.map((criteria, i) => (
        <CriteriaBox
            checked={criteria.status}
            text={criteria.text}
            key={i}
            style={style}
            textStyle={textStyle}
        />
    ));
};

export default PassProtectionCriteria;
