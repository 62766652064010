import React, { useEffect, useState } from 'react';
import { View, Text, TouchableHighlight, Image } from 'react-native';
import styles from './styles.web';

import { useHistory } from 'react-router-dom';

import ModalPopup from '../../../Components/Popup/index.web';
import LoadingState from '../../../Components/LoadingState';

import { useAuth } from '../../../Context/AuthContext';
import { fetchHazards, arrayRows } from '../../../API/Joomla.web';
import { GET_ONLINE_IMAGE_PATH } from '../../../API/Environment.web';

const DashboardButton = ({ text, onPress, icon }) => {
    return (
        <TouchableHighlight
            onPress={onPress}
            style={styles.dashboardButtonStyleWeb}
            underlayColor="#cac7c6">
            <View style={styles.dashboardButtonView}>
                {icon}
                <Text style={styles.dashboardButtonText}>{text}</Text>
            </View>
        </TouchableHighlight>
    );
};

const HazardPerceptionDashboard = () => {
    const [hazards, setHazards] = useState(null);
    const [loading, setLoading] = useState(true);
    const [trialExceeded, setTrialExceeded] = useState(false);

    const history = useHistory();
    const auth = useAuth();

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const hazards = await fetchHazards();
            const filteredHazards = hazards.filter((hazard) => hazard.category === '33');
            setHazards(filteredHazards);
            setLoading(false);
        };
        getData();
    }, []);

    if (loading) {
        return <LoadingState />;
    }
    return (
        <View style={styles.container}>
            <ModalPopup
                visible={trialExceeded}
                setVisible={setTrialExceeded}
                title={'Trial Exceeded'}
                secondaryText={'Please upgrade your plan to gain access to this section.'}
                buttonText={'Choose a plan'}
                buttonLink={'/choose-a-plan'}
                skip
            />

            <DashboardButton
                text={'Mock Test'}
                icon={
                    <Image
                        style={{ width: 75, height: 75, resizeMode: 'contain' }}
                        source={require('../../../../assets/images/HazardPerception/SVG/mock-test.svg')}
                    />
                }
                onPress={() => {
                    if (auth.user.trial === 'Yes') {
                        setTrialExceeded(true);
                    } else {
                        history.push('/hazard-perception-mocktest');
                    }
                }}
            />

            <DashboardButton
                text={'Practice videos'}
                icon={
                    <Image
                        style={{ width: 75, height: 75, resizeMode: 'contain' }}
                        source={require('../../../../assets/images/HazardPerception/SVG/practice-videos.svg')}
                    />
                }
                onPress={() => history.push('/hazard-perception-practice')}
            />

            <Text style={styles.hintText}>Hints and tips</Text>

            {arrayRows(hazards, 2).map((slice) => {
                return (
                    <View style={styles.hazardRow}>
                        {slice.map((hazard, key) => {
                            return (
                                <TouchableHighlight
                                    onPress={() =>
                                        history.push({
                                            pathname: '/hazard-perception-tutorial',
                                            hazard: hazard
                                        })
                                    }
                                    style={styles.videoButtonStyle}
                                    underlayColor="#cac7c6"
                                    id={key}>
                                    <>
                                        <Image
                                            style={styles.thumbnail}
                                            source={{
                                                uri: `${GET_ONLINE_IMAGE_PATH}${hazard.thumbnail}`
                                            }}
                                        />
                                        <Image
                                            style={{
                                                width: 100,
                                                height: 100,
                                                resizeMode: 'contain',
                                                position: 'absolute',
                                                alignSelf: 'center'
                                            }}
                                            source={require('../../../../assets/images/HazardPerception/SVG/icon-play-blue.svg')}
                                        />
                                    </>
                                </TouchableHighlight>
                            );
                        })}
                    </View>
                );
            })}
        </View>
    );
};

export default HazardPerceptionDashboard;
