import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, TouchableHighlight, Image, Dimensions } from 'react-native';
import { Link } from 'react-router-dom';
import styles from './styles.web';

import PrimaryLink from '../../Buttons/PrimaryLink';
import PrimaryButton from '../../Buttons/PrimaryButton';
import SectionFeedback from '../../ResultTemplates/SectionFeedback/index.web';
import LoadingState from '../../LoadingState/index';

import { saveTest } from '../../../API/RealmComms.web';
import { fetchCategories } from '../../../API/Joomla.web';
import { MOCK_PASS_SCORE, HGV_MOCK_PASS_SCORE } from '../../../API/Environment.web';

const ToggleButton = ({ text, onPress, selected }) => {
    return (
        <TouchableHighlight
            onPress={onPress}
            underlayColor="#cac7c6"
            style={[styles.toggleButton, selected && styles.selectedButton]}>
            <Text style={[styles.toggleText, selected && styles.selectedText]}> {text} </Text>
        </TouchableHighlight>
    );
};

const ResultsScreen = ({
    questionFeedbackBreakdown,
    userScore,
    totalQuestions,
    questionsTracker,
    currentUser,
    isHgv
}) => {
    const [seeResults, setSeeResults] = useState(false);
    const [sort, setSort] = useState('All');
    const [categories, setCategories] = useState();
    const [loading, setLoading] = useState(false);

    const userPass = userScore >= (isHgv ? HGV_MOCK_PASS_SCORE : MOCK_PASS_SCORE);

    useEffect(() => {
        (async () => {
            await saveTest(
                userScore,
                totalQuestions,
                questionsTracker,
                questionFeedbackBreakdown,
                0
            );
        })();
    }, []);

    const CategoryName = ({ categoryID }) => {
        const categoryObject = categories ? categories.find((o) => o.id === categoryID) : null;
        if (categoryObject) {
            return categoryObject.name;
        }
        return 'No name found';
    };

    const viewResults = async () => {
        setLoading(true);
        setCategories(await fetchCategories());
        setSeeResults(true);
        setLoading(false);
    };

    if (loading) {
        return <LoadingState />;
    }

    return (
        <View style={{ flex: 1 }}>
            {!seeResults ? (
                <>
                    <ScrollView contentContainerStyle={{ flexGrow: 1, backgroundColor: 'white' }}>
                        <View style={styles.feedbackView}>
                            <View style={{ width: '100%' }}>
                                <Image
                                    style={{
                                        resizeMode: 'contain',
                                        width: '30vw',
                                        height: '30vh',
                                        alignSelf: 'center'
                                    }}
                                    source={require('../../../../assets/images/MockTest/PNG/test-complete.png')}
                                />

                                <Text style={styles.testComplete}>Test Complete</Text>

                                <Text
                                    style={{
                                        textAlign: 'center',
                                        marginBottom: 10,
                                        fontSize: Dimensions.get('window').height / 60
                                    }}>
                                    The test has now finished. Submit now to see your results.
                                </Text>
                            </View>
                        </View>
                    </ScrollView>

                    <View style={{ bottom: 10, paddingHorizontal: 32 }}>
                        <PrimaryButton
                            text="Finish test"
                            onPress={() => viewResults(questionFeedbackBreakdown)}
                            textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                        />

                        <Link
                            to="/dashboard"
                            style={{
                                marginTop: 20,
                                width: '100%',
                                padding: 10,
                                borderRadius: 20,
                                textAlign: 'center',
                                fontSize: Dimensions.get('window').height / 60
                            }}>
                            <Text style={styles.secondaryButtonText}> Cancel </Text>
                        </Link>
                    </View>
                </>
            ) : (
                <>
                    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                        <View style={styles.feedbackView}>
                            <Image
                                style={{ resizeMode: 'contain', width: 250, height: 250 }}
                                source={require('../../../../assets/images/MockTest/PNG/first-place.png')}
                            />

                            <Text style={styles.scoreStyle}>
                                {userScore}/{totalQuestions}
                            </Text>

                            {userPass ? (
                                <Text style={styles.scoreFeedbackStylePass}>Pass</Text>
                            ) : (
                                <Text style={styles.scoreFeedbackStyleFail}>Fail</Text>
                            )}

                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingBottom: 20
                                }}>
                                <ToggleButton
                                    text={'All'}
                                    onPress={() => {
                                        setSort('All');
                                    }}
                                    selected={sort === 'All'}
                                />
                                <ToggleButton
                                    text={'✓ Correct'}
                                    onPress={() => {
                                        setSort('Correct');
                                    }}
                                    selected={sort === 'Correct'}
                                />
                                <ToggleButton
                                    text={'X Incorrect'}
                                    onPress={() => {
                                        setSort('Incorrect');
                                    }}
                                    selected={sort === 'Incorrect'}
                                />
                            </View>
                            <SectionFeedback
                                questionFeedbackBreakdown={questionFeedbackBreakdown}
                                CategoryName={CategoryName}
                                sort={sort}
                            />

                            <PrimaryLink
                                text={'Back to dashboard'}
                                link={'/dashboard'}
                                customStyle={{ margin: 20 }}
                                textStyle={{ fontSize: Dimensions.get('window').height / 60 }}
                            />
                        </View>
                    </ScrollView>
                </>
            )}
        </View>
    );
};

export default ResultsScreen;
