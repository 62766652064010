import { StyleSheet, Platform, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F3F2F5'
    },

    mobileViewContainer: {
        flex: 1,
        backgroundColor: '#F3F2F5',
        alignItems: 'center',
        width: '100%',
        minHeight: '90vh'
    },

    headerContainer: {
        paddingVertical: Platform.OS === 'ios' ? 50 : 20,
        paddingHorizontal: 36
    },

    dashboardTitle: {
        fontSize: 36,
        marginBottom: 24,
        color: '#fff',
        fontWeight: 'bold'
    },

    restartButton: {
        backgroundColor: '#fff',
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: 25
    },

    restartButtonText: {
        fontSize: 18,
        color: '#0D0334',
        margin: 15
    },

    buttonRowViews: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    buttonRowViewsWeb: {
        flexDirection: 'row',
        width: '100%'
    },

    buttonRowViewsAdditional: {
        flexDirection: 'row',
        width: '100%'
    },

    headerBackgroundImage: {
        height: 325,
        backgroundColor: '#F3F2F5'
    },

    headerBackgroundImageWeb: {
        height: 350,
        backgroundColor: '#F3F2F5'
    },

    headerBackgroundFooterWeb: {
        height: 75,
        backgroundColor: '#F3F2F5'
    },

    dashboardHeaderButtonView: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: 10,
        width: '48%'
    },

    dashboardHeaderButtonViewWeb: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: 10,
        marginHorizontal: 20
    },

    dashboardHeaderButtonStyle: {
        borderRadius: 10,
        padding: 5
    },

    dashboardHeaderButtonChildren: {
        alignItems: 'center'
    },

    dashboardHeaderButtonText: {
        paddingTop: 10,
        color: '#fff',
        fontSize: 13,
        textAlign: 'center'
    },

    dashboardHeaderProfileButton: {
        borderRadius: 10,
        width: 'auto',
        height: 'auto'
    },

    pageContainer: {
        paddingHorizontal: 36,
        paddingVertical: 20,
        backgroundColor: '#F3F2F5'
    },

    pageContainerWeb: {
        paddingHorizontal: 36,
        paddingVertical: 65,
        backgroundColor: '#F3F2F5',
        flexDirection: 'row'
    },

    pageTitle: {
        fontSize: Dimensions.get('window').height / 60,
        fontWeight: 'bold',
        paddingBottom: 15,
        color: '#0D0334'
    },

    mobileDashboardIcon: {
        width: 50,
        height: 50,
        resizeMode: 'contain'
    },

    popupImage: {
        width: 150,
        height: 150,
        resizeMode: 'contain'
    },

    largePopupImage: {
        width: 250,
        height: 250,
        resizeMode: 'contain'
    }
});

export default styles;
