import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
    containerView: {
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 36,
        paddingTop: 36,
        flex: 1,
        margin: 'auto',
        height: '100%',
        width: '100vw'
    },
    trailFinished: {
        width: 150,
        height: 150,
        resizeMode: 'contain'
    },
    pageTitle: {
        fontSize: Dimensions.get('window').height / 30,
        fontWeight: 'bold',
        color: 'black',
        padding: 40
    },

    logoImage: {
        width: '25vw',
        height: '25vh',
        marginBottom: 10,
        resizeMode: 'contain'
    },

    forgottenPasswordText: {
        fontSize: Dimensions.get('window').height / 70
    },

    modalBackGround: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center'
    },

    modalContainer: {
        width: '80%',
        backgroundColor: 'white',
        paddingHorizontal: 20,
        paddingVertical: 30,
        borderRadius: 20,
        elevation: 20
    },

    secondaryButton: {
        marginTop: 20,
        padding: 10,
        borderRadius: 10
    },

    secondaryButtonText: {
        fontSize: Dimensions.get('window').height / 70,
        color: '#0D0334'
    },

    goBack: {
        marginTop: 20,
        padding: 10,
        borderRadius: 10
    },

    trailFinished: {
        width: '50vw',
        height: '15vh',
        resizeMode: 'contain'
    }
});

export default styles;
